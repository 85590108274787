import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BackstrapService } from '../backstrap.service';

@Injectable({
  providedIn: 'root'
})
export class TrifoldViewResolverService {

  constructor(private router: Router,
              private api: BackstrapService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<boolean> {
    let crashId: string = null;
    const currentUrl: string = state.url;
    // If we are trying to retrieve the report from the full report page, get the route param
    // from the parent component.
    if (currentUrl.endsWith('/fullReport/initialIncidentReport')) {
      crashId = route.parent.paramMap.get('crashId');
    } else if (currentUrl.endsWith('/trifold') || currentUrl.endsWith('/insDriver')) {
      // If we are trying to retrieve the report from the /trifold or /insDrive page, get the route param
      // from the current component.
      crashId = route.paramMap.get('crashId');
    } else {
      // Else, return the user to the carrier dashboard page.
      return this.router.navigate(['/carrierDashboard']);
    }
    // As long as the crash id exists on the route, get the report. Otherwise navigate to the carrier dashboard.
    return crashId ? this.api.getAbridgedReport(crashId) : this.router.navigate(['/carrierDashboard']);
  }
}
