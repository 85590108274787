// ==================================================================
// CurrentUserComponent
//
// This component shows the name of the logged in user and provides
// a dropdown which lets him/her log out.
//
// Further account settings may be added here in the future
// ==================================================================

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EventDispatchService } from '../event-dispatch.service';
import { BackstrapService } from '../backstrap.service';

@Component({
  selector: 'app-current-user',
  templateUrl: './current-user.component.html',
  styleUrls: ['./current-user.component.css']
})
export class CurrentUserComponent implements OnInit {
	eventSubscription: any;
	currentUser = {
		username: '',
		email: '',
		first_name: '',
    last_name: '',
    is_admin: false,
    is_carrier: false
	}
	showDropdown = false;

  constructor(private route: ActivatedRoute, private router: Router, private api: BackstrapService, private eventDispatch: EventDispatchService) { 
  	
  }

  ngOnInit() {
  	this.currentUser = this.api.getUser();
  }

  ngOnDestroy() {
  	
  }

  toggleDropdown() {
  	this.showDropdown = !this.showDropdown;
  }

  signout() {
	  if(this.api.getToken() !== null) {
	  	this.api.signout()
	  	.subscribe((data) => {
	  		this.eventDispatch.dispatch({type: 'auth', payload: 'signout'}, null);
	  		this.router.navigate(['/']);
	  	},
	  	(err) => {
	  		console.log(err);
	  	})
	  }
	  else {
		  this.router.navigate(['/']);
	  }
  }

  goToAdminSection() {
    var url = '/admin';
  	this.router.navigate([url]);
  }

  goToCarrierSection() {
    var url = '/carrierDashboard';
  	this.router.navigate([url]);
  }
}
