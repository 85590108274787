// ==============================================================
// BackstrapService
//
// This handles all communication with the Backstrap back-end.
// The BASE_URL is taken from globals.ts (Remeber to change for prod).
// This also stores some of the information that persists through
// a session such as api token, current user, etc.
// 
// Sign in and sign out both use EventDispatchService to emit an
// event to the rest of the app that the user has signed in or out.
//
// Automatically navigates to '/' on 401 and 403 responses.
//
// All functions return a self-terminating observable, so no need
// to manually shut down the subscription call.
// ==============================================================

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IUser } from './contracts/user/iuser';
import { EventDispatchService } from './event-dispatch.service';
import * as GLOBAL from './globals';


@Injectable({
  providedIn: 'root'
})
export class BackstrapService {
	baseUrl: string;
	tokenKey = 'oss-api-token';
	token = null;
	user = {};
	postData = {};
	currentUser: IUser = {
		username: '',
		email: '',
		first_name: '',
    last_name: '',
    is_admin: false,
    is_carrier: false,
    carrier: null
	};
  router:Router;
  unauthedUrl: string = null;

  constructor(private http: HttpClient, private r:Router, private eventDispatch: EventDispatchService) { 
  	var storedToken = localStorage.getItem('oss-api-token');
  	if(storedToken !== undefined && storedToken !== null) this.token = storedToken;

  	var storedUser = null;
  	try { 
  		storedUser = JSON.parse(localStorage.getItem('oss_user'));
  		if(storedUser !== undefined && storedUser !== null) this.currentUser = storedUser;
  	}
  	catch(e) { console.log(e) }
  	this.router = r;
  	this.baseUrl = GLOBAL.BASE_URL;
  }


  // =========================================================================
  // MAKE REQUEST -- INTERCEPT 401 & 403 FOR ROUTING
  // =========================================================================
  makeRequest(method, urlFragment, reqData, headerData) {
  	if(reqData === undefined || reqData === null) {
  		reqData = {};
  	}
  	if(headerData === undefined || headerData === null) {
  		headerData = {};
  	}
  	return new Observable((observer) => {
  		switch(method.toLowerCase()) {
  			case 'get':
  				this.http.get(this.baseUrl + urlFragment, {headers:headerData})
  				.subscribe((data) => {
  					observer.next(data);
	  				observer.complete();
	  				return;
  				},
  				(err) => {
  					if(err.status == 401 || err.status == 403) {
  						this.clearUser();
  						this.router.navigate(['/']);
  						observer.error(err);
  						observer.complete();
  						return;
  					}
  					else {
  						observer.error(err);
			  			observer.complete();
			  			return;
  					}
  				});
  				break;
  			case 'post':
  				this.http.post(this.baseUrl + urlFragment, reqData, {headers:headerData})
  				.subscribe((data) => {
  					observer.next(data);
	  				observer.complete();
	  				return;
  				},
  				(err) => {
  					if(err.status == 401 || err.status == 403) {
  						this.clearUser();
  						this.router.navigate(['/']);
  						observer.error(err);
  						observer.complete();
  						return;
  					}
  					else {
  						observer.error(err);
			  			observer.complete();
			  			return;
  					}
  				});
  				break;
  			case 'put':
  				this.http.put(this.baseUrl + urlFragment, reqData, {headers:headerData})
  				.subscribe((data) => {
  					observer.next(data);
	  				observer.complete();
	  				return;
  				},
  				(err) => {
  					if(err.status == 401 || err.status == 403) {
  						this.clearUser();
  						this.router.navigate(['/']);
  						observer.error(err);
  						observer.complete();
  						return;
  					}
  					else {
  						observer.error(err);
			  			observer.complete();
			  			return;
  					}
  				});
  				break;
  			case 'patch':
  				this.http.patch(this.baseUrl + urlFragment, reqData, {headers:headerData})
  				.subscribe((data) => {
  					observer.next(data);
	  				observer.complete();
	  				return;
  				},
  				(err) => {
  					if(err.status == 401 || err.status == 403) {
  						this.clearUser();
  						this.router.navigate(['/']);
  						observer.error(err);
  						observer.complete();
  						return;
  					}
  					else {
  						observer.error(err);
			  			observer.complete();
			  			return;
  					}
  				});
  				break;
  			case 'delete':
  				this.http.delete(this.baseUrl + urlFragment, {headers:headerData})
  				.subscribe((data) => {
  					observer.next(data);
	  				observer.complete();
	  				return;
  				},
  				(err) => {
  					if(err.status == 401 || err.status == 403) {
  						this.clearUser();
  						this.router.navigate(['/']);
  						observer.error(err);
  						observer.complete();
  						return;
  					}
  					else {
  						observer.error(err);
			  			observer.complete();
			  			return;
  					}
  				});
  				break;
  			default:
  				observer.error('Unknown http method');
	  			observer.complete();
	  			return;
  		}
  	});
  }


  // =========================================================================
  // ACCESSORS & INTERNALS
  // =========================================================================
  getUser() {
  	return this.currentUser;
  }

  clearUser() {
  	localStorage.removeItem('oss-api-token');
  	this.token = null;

  	localStorage.removeItem('oss_user');
		this.currentUser = {
			username: '',
			email: '',
			first_name: '',
      last_name: '',
      is_admin: false,
      is_carrier: false,
      carrier: null
		}

		this.eventDispatch.dispatch({type: 'auth', payload: 'signout'}, null);
  }

  getToken() {
  	return this.token;
  }

  checkToken() {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
    }
    var headers = new HttpHeaders(headerObj);

    var urlFrag = '/common/accounts/checkToken/1.0.0';

    return this.makeRequest('get', urlFrag, null, headers);
  }

  // =========================================================================
  // AUTH CALLS
  // =========================================================================
  signin(username, password) {
    let that = this;
    localStorage.setItem("oss_user", null);
    
  	return new Observable((observer) => {
  		this.postData = {username: username, password: password};
  		this.http.post(this.baseUrl + '/oss/accounts/signin', this.postData)
	  	.subscribe((data) => {
	  		that.token = data[that.tokenKey];
        
        localStorage.setItem("oss-api-token", this.token);
	  		that.currentUser.username = data['username'];
	  		that.currentUser.email = data['email'];
	  		that.currentUser.first_name = data['first'];
        that.currentUser.last_name = data['last'];
        that.currentUser.is_admin = data['is_admin'] || false;
        that.currentUser.is_carrier = data['is_carrier'] || false;
        that.currentUser.carrier = data['carrier'] || null;
        
	  		localStorage.setItem('oss_user', JSON.stringify(that.currentUser));

	  		that.eventDispatch.dispatch({type: 'auth', payload: 'signin'}, null);
	  		
	  		observer.next(data);
	  		observer.complete();
	  		return;
	  	},
	  	(err) => {
	  		observer.error(err);
	  		observer.complete();
	  		return;
	  	})
  	});
  }

  getServiceLocations() {
    var headerObj = {};
		if(this.tokenKey && this.token) {
  		headerObj[this.tokenKey] = this.token;
  	}
  	var headers = new HttpHeaders(headerObj);

		return this.makeRequest('get', '/oss/accounts/serviceLocations/1.0.0', null, headers)
  }

  signup(userName, userEmail, firstName, lastName, userPassword) {
    this.postData = {
      username: userName, 
      email: userEmail, 
      first_name: firstName, 
      last_name: lastName, 
      password: userPassword
    };

    return this.http.post(this.baseUrl+'/common/accounts/signup', this.postData);
  }

  signout() {
  	return new Observable((observer) =>	 {
  		var headerObj = {};
  		headerObj[this.tokenKey] = this.token;
  		var headers = new HttpHeaders(headerObj);

  		this.postData = {};

  		this.http.post(this.baseUrl+'/common/accounts/signout', this.postData, {headers: headers})
	  	.subscribe((data) => {
	  		this.clearUser();
	  		observer.next(data);
	  		observer.complete();
	  		return;
	  	},
	  	(err) => {
	  		observer.error(err);
	  		observer.complete();
	  		return;
	  	})
  	});
  }

  forgotPassword(usernameOrEmail) {
  	this.postData = {};
  	if(usernameOrEmail.indexOf('@') == -1 || usernameOrEmail.indexOf('@') == 0) {
  		this.postData['username'] = usernameOrEmail;
  	}
  	else {
  		this.postData['email'] = usernameOrEmail;
  	}
  	return this.http.post(this.baseUrl+'/oss/accounts/forgotPassword', this.postData);
  }

  resetPassword(resetToken, newPassword) {
  	this.postData = {token: resetToken, password: newPassword};
  	return this.http.post(this.baseUrl+'/common/accounts/resetPassword', this.postData);
  }

	// =========================================================================
	// OSS CALLS
	// =========================================================================
	updateFormField(crashId, fullFieldDescriptor, groupRepeatIndex, repeatIndex) {
		this.postData = {
			crash_num: crashId,
			field_descriptor: {
				fieldName: fullFieldDescriptor.fieldName,
				tableName: fullFieldDescriptor.tableName,
				key: fullFieldDescriptor.key,
        value: fullFieldDescriptor.value
			}
    };

    if(repeatIndex !== undefined && repeatIndex !== null) {
      this.postData['field_descriptor'].repeatIndex = repeatIndex;
    }

    if(groupRepeatIndex !== undefined && groupRepeatIndex !== null) {
      this.postData['field_descriptor'].groupRepeatIndex = groupRepeatIndex;
    }

		var headerObj = {};
		if(this.tokenKey && this.token) {
  		headerObj[this.tokenKey] = this.token;
  	}
  	var headers = new HttpHeaders(headerObj);

		return this.makeRequest('post', '/oss/crashReport/formField/1.0.0', this.postData, headers)
	}

  getReportsInProgress() {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
    }
    var headers = new HttpHeaders(headerObj);

    return this.makeRequest('get', '/oss/crashReport/inProgress/1.0.0', null, headers)
  }

  getSchema(versionNumber, serviceLocation) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
    }
    var headers = new HttpHeaders(headerObj);

    var urlFrag = '/oss/crashReport/schema/1.0.0';
    if(versionNumber != null) {
      urlFrag += '?version='+versionNumber;
    }
    else if(serviceLocation != null) {
      urlFrag += `?service_location_id=${serviceLocation}`;
    }

    return this.makeRequest('get', urlFrag, null, headers);
  }

  getReport(crashId) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
    }
    var headers = new HttpHeaders(headerObj);
    var urlFrag = '/oss/crashReport/report/1.0.0?crash_num='+crashId;
    return this.makeRequest('get', urlFrag, null, headers)
  }

  getAbridgedReport(crashId) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
    }
    var headers = new HttpHeaders(headerObj);
    var urlFrag = '/oss/crashReport/abridgedReport/1.0.0?crash_num='+crashId;
    return this.makeRequest('get', urlFrag, null, headers)
  }

  newReport(schemaNum, crashNum, stateNum, stateInnerNum, localNum, serviceLocationId) {
    this.postData = {};

    if(crashNum !== undefined && crashNum !== null) {
      this.postData['crash_num'] = crashNum;
    }
    if(schemaNum !== undefined && schemaNum !== null) {
      this.postData['schema_version'] = schemaNum;
    }
    if(stateNum !== undefined && stateNum !== null) {
      this.postData['state_rpt_num'] = stateNum;
    }
    if(stateInnerNum !== undefined && stateInnerNum !== null) {
      this.postData['state_inner_rpt_num'] = stateInnerNum;
    }
    if(localNum !== undefined && localNum !== null) {
      this.postData['local_rpt_num'] = localNum;
    }
    if (!!serviceLocationId) {
      this.postData['service_location_id'] = serviceLocationId;
    }

    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
    }
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/crashReport/newReport/1.0.0';
    return this.makeRequest('post', urlFrag, this.postData, headers);
  }

  addToList(crashNum, listType, groupIndex) {
    this.postData = {
      crash_num: crashNum,
      list_type: listType
    };

    if(groupIndex != null) {
      this.postData['group_index'] = groupIndex;
    }

    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
    }
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/crashReport/addToList/1.0.0';
    return this.makeRequest('post', urlFrag, this.postData, headers);
  }

  removeFromList(crashNum, listType, groupIndex) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
    }
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/crashReport/removeFromList/1.0.0?crash_num='+crashNum+'&list_type='+listType;
    if(groupIndex != null && groupIndex >= 0) {
    urlFrag += '&group_index='+groupIndex;
    }
    return this.makeRequest('delete', urlFrag, null, headers);
  }

  checkForData(crashNum, lastUpdate) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
		headerObj['ETag'] = '';
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/crashReport/checkForData/1.0.0?crash_num='+crashNum+'&last_update='+lastUpdate;
    return this.makeRequest('get', urlFrag, null, headers);
  }

  reportsByStatus(data: {serviceLocationId: string, statuses: string[], limit: number, offset: number}) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
		headerObj['ETag'] = '';
    var headers = new HttpHeaders(headerObj);
    
    var params = new URLSearchParams()
    params.append('serviceLocationId', data.serviceLocationId);
    if(data.limit != null && data.limit > 0)
      params.append('limit', data.limit.toString());
    if(data.offset != null && data.offset > 0)
      params.append('offset', data.offset.toString());
    data.statuses.forEach(status => params.append('status', status));

    return this.makeRequest('get', `/oss/dashboard/reportsByStatus/1.0.0?${params}`, null, headers);
  }

  setReportStatus(crashNum, status) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
		headerObj['ETag'] = '';
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/dashboard/reportStatus/1.0.0';
    var reqBody = {
      crash_num: crashNum,
      status: status
    };

    return this.makeRequest('post', urlFrag, reqBody, headers);
  }

  setLACrashId(crashNum, lacId) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);
    var body = {
      crash_num: crashNum,
      lacrash_id: lacId
    }
    var urlFrag = '/oss/crashReport/laCrashId/1.0.0';
    return this.makeRequest('post', urlFrag, body, headers);
  }

  getInsuranceInfoBetweenDates(startDate, endDate) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/admin/insuranceInfoBetweenDates/1.0.0';
    if(startDate != null && startDate !== '' &&
      endDate != null && endDate !=='') {
        urlFrag += '?start_date='+startDate+'&end_date='+endDate;
    }
    else if(startDate != null && startDate !== '') {
      urlFrag += '?start_date='+startDate;
    }
    else if(endDate != null && endDate !== '') {
      urlFrag += '?end_date='+endDate;
    }

    return this.makeRequest('get', urlFrag, null, headers);
  }

  getReportsForCarrier(carrier, currentPage, pageSize) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/carrier/reportsByInsuranceCarrier/1.0.0';
    if(carrier != null) {
      urlFrag += '?carrier_name='+carrier;
    }
    if(currentPage == null) currentPage = 0;
    if(pageSize == null) pageSize = 25;
    urlFrag += `&offset=${currentPage * pageSize}&limit=${pageSize}`;

    return this.makeRequest('get', urlFrag, null, headers);
  }

  getInsuranceCarriers() {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/carrier';

    return this.makeRequest('get', urlFrag, null, headers);
  }

  boxDocsForReport(reportName) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/carrier/boxDocsForReport/1.0.0?report_name='+reportName;

    return this.makeRequest('get', urlFrag, null, headers).pipe(
      catchError((error) => of(error))
    );
  }

  sendNotificationToAdmins(crashId) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/crashreport/notifyAdminOfCrash/1.0.0';

    return this.makeRequest('post', urlFrag, {crash_num: crashId}, headers);
  }

  getPublicReport(crashUid) {
    var urlFrag = '/oss/crashReport/publicReport/1.0.0?crash_uid='+crashUid;
    return this.makeRequest('get', urlFrag, null, null);
  }

  postMeteredEvent(eventName, crashNum) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);
    
    var urlFrag = '/oss/carrier/meteredEvent/1.0.0';

    return this.makeRequest('post', urlFrag, {event_name: eventName, crash_num: crashNum}, headers);
  }

  schemaVersions(data: {serviceLocationId: number}) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);

    var path = `/oss/dashboard/schemas/1.0.0?${new URLSearchParams(data as any)}`;

    console.log('path', path);

    return this.makeRequest('get', path, null, headers);
  }

  getImagesForReport(itemNum: string) {
    var headerObj = {};
    if(this.tokenKey && this.token) {
      headerObj[this.tokenKey] = this.token;
		}
    var headers = new HttpHeaders(headerObj);
    var urlFrag = '/oss/media/imagesForReport/1.0.0?item_num='+itemNum;
    return this.makeRequest('get', urlFrag, null, headers);
  }
}