import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { BackstrapService } from '../../backstrap.service';

@Component({
  selector: 'app-agent-report',
  templateUrl: './agent-report.component.html',
  styleUrls: ['./agent-report.component.css']
})
export class AgentReportComponent implements OnInit, OnDestroy {
  // Subject to help unsubscribe from any active subscriptions on component destroy.
  private unsubscribe: Subject<any> = new Subject<any>();
  // The report.
  reportData: any;
  // Crash id.
  crashId: string;
  // Last update date.
  lastUpdate: Date;
  // Form groups.
  formGroups: any[];

  constructor(private route: ActivatedRoute,
              private api: BackstrapService) { }

  ngOnInit() {
    // Scroll to the top of the window when the page loads.
    const top: Element = document.getElementById('topOfInitialReportContent');
    if (top) {
      top.scrollIntoView({
        behavior: 'smooth'
      });
    }
    // Subscribe to the data on the route from the trifold-view resolver.
    this.route.data.pipe(
      takeUntil(this.unsubscribe),
      mergeMap((response: { report: any }) => {
        const report = response.report;
        if(report !== undefined && report !== null) {
          this.reportData = report;
          this.crashId = report.crash_num;

          // SET THE LAST UPDATE TO NOW -- USED TO CHECK FOR DIRTY DATA LATER
          this.lastUpdate = new Date();
          var sv = null;
          if(this.reportData.rev_num !== undefined && this.reportData.rev_num !== null) {
            sv = this.reportData.rev_num;
          }

          // GET THE SCHEMA VERSION FROM THE DB THAT MATCHES THIS REPORT
          return this.api.getSchema(sv, null);
          
        }
        return of(null);
      })
    ).subscribe((data:any) => {
      if (data) {
        this.formGroups = data.schema;
        
        // RUN THROUGH THE REPORT DATA AND FILL IN THE APPROPRIATE
        // FORM FIELDS
        this.populateForm();
      }
    }, (error) => {
      console.log('Error loading report: ', error);
    });
  }

  populateForm(){
  	var reportKeys = Object.keys(this.reportData);

    // FOR EACH GROUP IN THE FORM SCHEMA
    for(var gIdx = 0; gIdx < this.formGroups.length; gIdx++) {
      var formGroup = this.formGroups[gIdx];
      
      // GET ALL THE SUB GROUPS OF THAT GROUP
      for(var sgIdx = 0; sgIdx < formGroup.subGroups.length; sgIdx++) {
        var subGroup = formGroup.subGroups[sgIdx];
        // GET ALL FORM ITEMS FOR EACH SUBGROUP
        for(var fIdx = 0; fIdx < subGroup.formItems.length; fIdx++) {
          var formItem = subGroup.formItems[fIdx];

          // GET THE FIELD NAME FOR THIS FORM ITEM
          // IT WILL BE THE KEY IN THE REPORT DATA
          var thisKey = formItem.fieldName.toLowerCase();

          // IF WE DON'T HAVE THE TABLE NAME SPECIFIED,
          // DERIVE IT FROM THE FORM ITEM KEY
          formItem.tableName = formItem.table;
          if(formItem.table == null || formItem.table === ''){
            formItem.tableName = formItem.key.split('-')[0];
          }
          var thisTable = formItem.tableName.toLowerCase();


          // FOR EACH KEY IN THE REPORT DATA
          for(var rIdx = 0; rIdx < reportKeys.length; rIdx++) {
            var reportKey = reportKeys[rIdx];

            // IF THIS REPORT DATA IS AN OBJECT, THERE ARE MULTIPLE
            // FORM ITEMS FOR THIS TABLE.  RUN THROUGH EACH REPORT KEY
            // TO FIND THE CORRECT TABLE, THEN RUN THROUGH THE INNER KEYS
            // TO FIND THE KEY IN QUESTION
            if(this.reportData[reportKey] != null && 
                typeof(this.reportData[reportKey]) === 'object') {
              // FOUND THE TABLE
              if(reportKey.toLowerCase() === thisTable) {
                // THIS ELEMENT IS AN ARRAY OF OBJECTS.  CYCLE THROUGH EACH ONE
                for(var irIdx = 0; irIdx < this.reportData[reportKey].length; irIdx++) {
                  // THE NARR OBJECT (FROM NARR_TB) IS A SPECIAL CASE.  IT KEEPS SINGLETON DATA
                  // FOR THE TEXT NARRATIVE AND MANNER OF COLLISION CODE
                  // BUT ALSO REPEATING DATA FOR DIAGRAMS.  THE ENTITY FROM THE DB WITH
                  // diagram_num === 0 HOLDS THE SINGLETON DATA AND THOSE WITH diagram_num > 0
                  // ARE USED FOR THE DIAGRAMS
                  //
                  // SO IF THIS IS A FORMITEM FOR THE SINGLETON DATA, IGNORE ANYTHING IN THE OTHER
                  // NARR ENTRIES.  IF IT IS REPEATING DATA, IGNORE ANYTHING IN THE FIRST ENTRY
                  if((['NARR-0001', 'NARR-0003', 'NARR-0631'].includes(formItem.key) && this.reportData['narr'][irIdx].diagram_num !== 0)
                      ||
                      (formItem.key === 'NARR-0002' && this.reportData['narr'][irIdx].diagram_num === 0)
                    ) {
                    continue;
                  }

                  var tableKeys = Object.keys(this.reportData[reportKey][irIdx]);

                  for(var ikIdx = 0; ikIdx < tableKeys.length; ikIdx++) {
                    var tableKey = tableKeys[ikIdx];
                    // FOUND THE SPECIFIC KEY FOR THIS FORM ITEM
                    if(tableKey.toLowerCase() === thisKey) {
                      // FIGURE OUT WHICH GROUP ITERATION AND SUBGROUP ITERATION THIS APPLIES TO
                      var groupIndex = 0;
                      var subGroupIndex = 0;


                      if(formGroup.repeats === true && thisTable === formGroup.key) {
                        groupIndex = this.reportData[reportKey][irIdx][subGroup.parentGroupKey] - 1;
                      }
                      else if(formGroup.repeats === true) {
                        groupIndex = this.reportData[reportKey][irIdx][subGroup.parentGroupKey] - 1;
                        subGroupIndex = this.reportData[reportKey][irIdx][subGroup.groupKey] - 1;
                      }
                      else if(subGroup.repeats === true) {
                        subGroupIndex = this.reportData[reportKey][irIdx][subGroup.groupKey] - 1;
                      }


                      if(formItem.value == null) formItem.value = [];
                      for(var gi = 0; gi <= groupIndex; gi++) {
                        if(formItem.value[gi] === undefined || formItem.value[gi] === null) {
                          formItem.value[gi] = [['']];
                        }
                        for(var ri = 0; ri <= subGroupIndex; ri++) {
                          if(formItem.value[gi][ri] === undefined || formItem.value[gi][ri] === null) {
                            formItem.value[gi][ri] = '';
                          }
                        }
                      }
                      
                      // THE BACK END FOR YES/NO FORM ITEMS STORES t/f IN THE DB.
                      // CHECK FOR YES/NO FORM ITEMS AND CONVERT true/false -> Y/N
                      if(formItem.items !== undefined && formItem.items !== null && formItem.items.length === 2 && 
                        (formItem.items[0].value.toLowerCase() === 'y' || formItem.items[0].value.toLowerCase() === 'n') &&
                        (formItem.items[1].value.toLowerCase() === 'y' || formItem.items[1].value.toLowerCase() === 'n')) {
                        if(this.reportData[reportKey][irIdx][tableKey] === true) {
                          formItem.value[groupIndex][subGroupIndex] = 'Y';
                        }
                        else {
                          formItem.value[groupIndex][subGroupIndex] = 'N';
                        }
                      }
                      else if(formItem.type === 'date') {
                        if(this.reportData[reportKey][irIdx][tableKey] != null && this.reportData[reportKey][irIdx][tableKey] !== '') {
                          var d = new Date(this.reportData[reportKey][irIdx][tableKey]);
                          var monthNum = d.getMonth()+1;
                          var monthString = monthNum < 10 ? '0'+monthNum.toString() : monthNum.toString();
                          var dayString = d.getDate() < 10 ? '0'+d.getDate().toString() : d.getDate().toString();
                          var yearString = d.getFullYear().toString();
                          var dateVal = monthString+dayString+yearString;

                          formItem.value[groupIndex][subGroupIndex] = dateVal;
                        }
                        else {
                          formItem.value[groupIndex][subGroupIndex] = '';
                        }
                      }
                      else if(formItem.type === 'time') {
                        if(this.reportData[reportKey][irIdx][tableKey] != null && this.reportData[reportKey][irIdx][tableKey] !== '') {
                          formItem.value[groupIndex][subGroupIndex] = this.reportData[reportKey][irIdx][tableKey].replace(new RegExp('\\:', 'g'), '');
                        }
                        else {
                          formItem.value[groupIndex][subGroupIndex] = '';
                        }
                      }
                      // OTHERWISE THIS IS A NORMAL FIELD WHOSE VALUE IS STORED
                      // ON THE BACK END THE SAME AS ON THE FRONT
                      else {
                        formItem.value[groupIndex][subGroupIndex] = this.reportData[reportKey][irIdx][tableKey];
                      }

                      break;
                    }
                  }
                }
              }
            }
            else {
              if(reportKeys[rIdx].toLowerCase() === thisKey) {
                if(formItem.value == null) formItem.value = [];
                if(formItem.value[0] == null || formItem.value[0].length === 0) {
                  formItem.value[0] = [''];
                }
                // THE BACK END FOR YES/NO FORM ITEMS STORES t/f IN THE DB.
                // CHECK FOR YES/NO FORM ITEMS AND CONVERT true/false -> Y/N
                if(formItem.items !== undefined && formItem.items !== null && formItem.items.length === 2 && 
                  (formItem.items[0].value.toLowerCase() === 'y' || formItem.items[0].value.toLowerCase() === 'n') &&
                  (formItem.items[1].value.toLowerCase() === 'y' || formItem.items[1].value.toLowerCase() === 'n')) {
                  if(this.reportData[reportKey] === true) {
                    formItem.value[0] = ['Y'];
                  }
                  else {
                    formItem.value[0] = ['N'];
                  }
                }
                else if(formItem.type === 'date') {
                  if(this.reportData[reportKey] != null && this.reportData[reportKey] !== '') {
                    var d = new Date(this.reportData[reportKey]);
                    var monthNum = d.getMonth()+1;
                    var monthString = monthNum < 10 ? '0'+monthNum.toString() : monthNum.toString();
                    var dayString = d.getDate() < 10 ? '0'+d.getDate().toString() : d.getDate().toString();
                    var yearString = d.getFullYear().toString();
                    var dateVal = monthString+dayString+yearString;

                    formItem.value[0] = [dateVal];
                  }
                  else {
                    formItem.value[0] = [''];
                  }
                }
                else if(formItem.type === 'time') {
                  if(this.reportData[reportKey] != null && this.reportData[reportKey] !== '') {
                    formItem.value[0][0] = this.reportData[reportKey].replace(new RegExp('\\:', 'g'), '');
                  }
                  else {
                    formItem.value[0] = [''];
                  }
                }
                // OTHERWISE THIS IS A NORMAL FIELD WHOSE VALUE IS STORED
                // ON THE BACK END THE SAME AS ON THE FRONT
                else {
                  formItem.value[0] = [this.reportData[reportKey]];
                }
                break;
              }
            }
          }
        }
      }
    }
  }

  /**
   * Determines if we show the group or not based on the table row values.
   * @param {object[]} subgroups 
   * @param {any[]} rfg 
   * @param {object} fg 
   * @returns {boolean}
   */
  showGroup(subgroups: object[], rfg: any[], fg: object) {
    let showGroup: boolean = false;
    mainloop:
    for (let subgroupIndex = 0; subgroupIndex < subgroups.length; subgroupIndex++) {
      const subGroup: object = subgroups[subgroupIndex];
      const repGroups: any[] = subGroup['key'] ? this.reportData[subGroup['key']] : [1];
      for (let repGroupIndex = 0; repGroupIndex < repGroups.length; repGroupIndex++) {
        const repGroup: any = repGroups[repGroupIndex];
        const parentGroupKey: any = subGroup['parentGroupKey'];
        if (parentGroupKey == null || parentGroupKey == '' || repGroup === 1 || (parentGroupKey != null && repGroup[parentGroupKey] === rfg[fg['groupKey']])) {
          for (let optionsIndex = 0; optionsIndex < subGroup['formItems'].length; optionsIndex++) {
            if (this.hasValue(subGroup['formItems'][optionsIndex], rfg[parentGroupKey], repGroup[subGroup['groupKey']])) {
              showGroup = true;
              break mainloop;
            }
          }
        }
      }
    }
    return showGroup;
  }

  /**
   * Helps determine if we should show the damage to vehicle image. This tests if the First Vehicle Damage
   * row has a value or not.
   * @param {object} option 
   * @param {number} groupRepeatIndex 
   * @param {number} repeatIndex
   * @returns {any | null}
   */
  hasValue(option: object, groupRepeatIndex: number, repeatIndex: number, isDamageImg: boolean = false) {
    const value: any[] = option['value'];
    if (option['value']) {
      const optionType: string = option['type'];
      if(groupRepeatIndex) groupRepeatIndex--;
      const valueFirstIndex: any[] = groupRepeatIndex ? value[groupRepeatIndex] : value[0];
      var valueSecondIndex: any[] = null;
      if(valueFirstIndex) {
        if(repeatIndex) repeatIndex--;
        valueSecondIndex = repeatIndex ? valueFirstIndex[repeatIndex] : valueFirstIndex[0];
      }
      if (isDamageImg) {
        if (valueFirstIndex && valueSecondIndex) {
          return true;
        }
      } else {
        if (optionType === 'text' || optionType === 'password' || optionType === 'number' || optionType === 'date' || optionType === 'time') {
          if (valueFirstIndex || valueSecondIndex) {
            return true;
          }
        } else {
          if (valueSecondIndex) {
            return true;
          }
        }
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
