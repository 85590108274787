import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminControlsComponent } from './admin-controls/admin-controls.component';
import { AuthComponent } from './auth/auth.component';
import { CarrierDashboardComponent } from './carrier-dashboard/carrier-dashboard.component';
// IMPORT COMPONENTS
import { DashboardComponent } from './dashboard/dashboard.component';
import { DformComponent } from './dform/dform.component';
import { AgentReportComponent } from './full-report/agent-report/agent-report.component';
import { BoxWindowResolverService } from './full-report/box-window-resolver.service';
import { FullReportComponent } from './full-report/full-report.component';
import { GalleryComponent } from './full-report/gallery/gallery.component';
import { PublicReportComponent } from './public-report/public-report.component';
import { AuthGuard } from './shared/auth.guard';
// IMPORT RESOLVERS
import { TrifoldViewResolverService } from './trifold-view/trifold-view-resolver.service';
import { TrifoldViewComponent } from './trifold-view/trifold-view.component';
import { ReportResolverService } from './full-report/report-resolver.service';

// CREATE ROUTES
const routes: Routes = [
	{
    path: '',
    component: AuthComponent
  },
	{
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
	{
    path: 'crashReport/:crashId',
    component: DformComponent,
    canActivate: [AuthGuard]
  },
	{
    path: 'crashReport/:crashId/print',
    component: AgentReportComponent,
    resolve: {
      report: ReportResolverService
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'crashReport/:crashId/trifold',
    component: TrifoldViewComponent,
    canActivate: [AuthGuard],
    resolve: {
      report: TrifoldViewResolverService
    },
  },
  {
    path: 'crashReport/:crashId/insDriver',
    component: TrifoldViewComponent,
    canActivate: [AuthGuard],
    resolve: {
      report: TrifoldViewResolverService
    }
  },
  {
    path: 'admin',
    component: AdminControlsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'carrierDashboard',
    component: CarrierDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'crashReport/:crashId/fullReport',
    redirectTo: 'crashReport/:crashId/fullReport/initialIncidentReport',
    canActivate: [AuthGuard]
  },
  {
    path: 'crashReport/:crashId/fullReport', 
    component: FullReportComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'initialIncidentReport',
        component: TrifoldViewComponent,
        resolve: {
          report: TrifoldViewResolverService
        }
      },
      {
        path: 'agentReport',
        component: AgentReportComponent,
        resolve: {
          report: ReportResolverService
        }
      },
      {
        path: 'photosAndVideos/:reportId',
        component: GalleryComponent,
        // resolve: {
        //   boxResponse: BoxWindowResolverService
        // }
      },
      {
        path: 'documentsAndStatements/:reportId',
        component: GalleryComponent,
        // resolve: {
        //   boxResponse: BoxWindowResolverService
        // }
      }
    ]
  },
  {path: 'report/:reportUid', component: PublicReportComponent},
  {
    path: ':mode',
    component: AuthComponent
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: [
    AuthGuard,
    BoxWindowResolverService,
    ReportResolverService,
    TrifoldViewResolverService
  ]
})

export class AppRoutingModule { }