// ==================================================================
// FormItemComponent
//
// This represents a single form element in the DFormComponent.
// 
// It takes an "options" object as input and uses that to format
// itself properly.  Depending on the arguments in the options obj,
// it will render a label as well as an input element (text, number,
// date, textarea, dropdown, or button-group).  Values and labels
// for the options in dropdown or button-group must be included.
//
// The options object also must include a 'value' which binds to
// the input elements.  The value property is an array, and so for
// fields which only appear once in the form, they will bind to
// options.value[0].
//
// Sometimes the DFormComponent will include a repeating set
// of FormItemComponents.  In that case, the iteration of the repeating
// set is indicated with the repeatIndex input.
//
// The options object includes a fieldname which is used to identify a
// database field on the backend.  And via integration with the
// BackstrapService, on edit-end (+1.5 seconds), the form sends its value to the
// API which is responsible for saving that value to the correct field
// in the correct table of the db.  The green sync icon turns red, and
// then back to green once the api call is complete and successful.
//
//
// options for button-group form item 
// eg. from OSS
// {
//  "fieldName":"LIGHTING_CD",
//  "tableName":"CRASH"
//  "max_length":2,
//  "value":[],
//  "type":"button-group",
//  "key":"CRASH-0033",
//  "label":"Lighting Code",
//  "placeholder":"",
//  "items":[
//    {"label":"Daylight","value":"A"},
//    {"label":"Dark-No Street Lights","value":"B"},
//    {"label":"Dark-Continuous Street Light","value":"C"},
//    {"label":"Dark-Street Light At Intersection Only","value":"D"},
//    {"label":"Dusk","value":"E"},
//    {"label":"Dawn","value":"F"},
//    {"label":"Unknown","value":"Y"},
//    {"label":"Other","value":"Z"}
//    ]
// }
// ==================================================================


import { Component, Inject, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BackstrapService } from '../backstrap.service';
import { EventDispatchService } from '../event-dispatch.service';
import * as GLOBAL from '../globals';
import {FormControl} from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

declare let L;

export interface DialogData {
  formElement: any;
}

@Component({
  selector: 'app-form-item',
  templateUrl: './form-item.component.html',
  styleUrls: ['./form-item.component.css']
})
export class FormItemComponent implements OnInit {
  @Input() options: any;
  @Input() crashNum: string;
  @Input() groupRepeatIndex: number;
  @Input() repeatIndex: number;
  @Input() itemIndex: number;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @ViewChild('filter') filterElement: ElementRef;

  isSynced: boolean;
  sigPadOptions: any;
  eventSubscription: any;
  subParams: any;
  hideParams: any;
  isDisabled: boolean;
  isActive: boolean;
  apiError: boolean;
  filteredOptions: any[];
  myControl = new FormControl('');
  errorText = ""

  constructor(
    private api: BackstrapService, private eventDispatch: EventDispatchService, public dialog: MatDialog
  ) { 
    this.isSynced = true;
    this.sigPadOptions = {
      canvasWidth: 600,
      canvasHeight: 100,
      backgroundColor: '#EEEEEE'
    };
    this.subParams = {
      value: '',
      field: '',
      condition: ''
    };
    this.hideParams = {
      objects: [],
      value: undefined
    };
    this.isActive = false;
    this.apiError = false;
  }

  onFilter(name: string) {
    const filterValue = name.toLowerCase();
    this.filteredOptions = this.options.items.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  ngOnChanges() {
    if (
      ['ecrash-ref', 'ecrash-ref-multi'].includes(this.options.type) && !!this.options.items
    ) {
      this.onFilter("");
    }
  }

  onECrashValueSelected(formElement, code, isMultiSelect) {
    if (code) {
      this.buttonSelected(formElement, { value: code }, isMultiSelect);
    }
    else {
      this.buttonSelected(formElement, { value: null }, isMultiSelect);
    }
  }

  // ecrashValueChange(formElement, fieldInput) {
  //   if (fieldInput === null || fieldInput === "") {
  //     this.buttonSelected(formElement, { value: null }, false);
  //   }
  //   else {
  //     var selItem = formElement.items.find(i => i.label.toLowerCase() == fieldInput.toLowerCase())
  //     if (selItem) {
  //       this.buttonSelected(formElement, { value: selItem.value }, false);
  //     }
  //   }
  // }

  getECrashCode(groupRepeatIndex, repeatIndex) {
    if (this.options.value && this.options.items.length > 0) {
      var code = this.options.value[groupRepeatIndex][repeatIndex] || "";
      return code;
    } 
    return "";
  }

  ngOnInit() {
    
    if(this.groupRepeatIndex == null) {
      this.groupRepeatIndex = 0;
    }
    else {
      this.groupRepeatIndex--;
    }

    if(this.repeatIndex == null) {
      this.repeatIndex = 0;
    }
    else {
      this.repeatIndex--;
    }
    
    this.isDisabled = false;
    this.options.hideByDefault = this.options.hide;
    
    this.hideParams.objects = this.options.conditionallyHideObjects;
    this.hideParams.value = this.options.conditionallyHideValue;
    
    var that = this;
    this.eventSubscription = this.eventDispatch.emitter.subscribe((data) => {
      switch(data.type) {
        case 'report_load': 
          if(that.options.type === 'signature') {
            that.loadSignature(this.options.value[this.groupRepeatIndex][this.repeatIndex])
          }
          break;
        case 'inputChange':
          //handle setting defaults
          if(data.payload.key === that.subParams.field && data.payload.value[data.payload.repeatIndex] == that.subParams.condition){
            if(that.subParams.value.indexOf('()') > -1){
              that.subParams.value = that.getPseudoFunctionResult(that.subParams.value);
            }

            that.options.value[that.repeatIndex] = that.subParams.value;
            that.inputChange(that.options, null);
          }

          //handle hiding related fields
          if(data.payload.conditionallyHideObjects.length > 0){
            if( (data.payload.conditionallyHideObjects.indexOf(that.options.key.replace('-','').toLowerCase()) > -1 || 
                  data.payload.conditionallyHideObjects.indexOf(that.options.domID.toLowerCase()) > -1) && 
                  data.payload.conditionallyHideValue.indexOf(data.payload.value[data.payload.repeatIndex]) > -1){
              
                    that.options.hide = true;
            }
          }
          break;
        case 'show_fields':
          if(data.payload === true){
            that.options.hide = false;
          }else{
            that.options.hide = that.options.hideByDefault;
          }
          break;
        case 'set_status':
          if(that.options.fieldName.toUpperCase() == 'RESPONSE_STATUS'){
            that.options.value[that.groupRepeatIndex][that.repeatIndex] = data.payload;
            that.inputChange(that.options, null);
          }
          break;
        case 'carrier_selected':
          if(that.options.fieldName.toUpperCase() === 'INSCO' && that.groupRepeatIndex === data.payload.group_repeat_index) {
            var newVal = null;
            if(data.payload.carrier_num > 1) {
              newVal = GLOBAL.CARRIERS[data.payload.carrier_num - 2];
            } 
            that.inputChange(that.options, newVal);
            
          }
          break;
        default:
      }
    },
    (err) => {
      //console.log(err);
    });

    if(this.options.type === 'map'){
      if(this.options.value && this.options.value[this.groupRepeatIndex][this.repeatIndex] != undefined && this.options.value[this.groupRepeatIndex][this.repeatIndex] != ''){
        setTimeout(() => {this.setupMap(this.options.value[this.groupRepeatIndex][this.repeatIndex])}, 50);
      }else{
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.options.value[this.groupRepeatIndex][this.repeatIndex] = position.coords.latitude + ',' + position.coords.longitude;
            this.inputChange(this.options, null);
            this.setupMap(this.options.value[this.groupRepeatIndex][this.repeatIndex]);
          })
        }
      }
    }

    if(this.options.fieldName === 'AGENT_COORDINATES'){
      if(this.options.value && this.options.value[this.groupRepeatIndex][this.repeatIndex] != undefined && this.options.value[this.groupRepeatIndex][this.repeatIndex] != ''){
        //just here in case we need it in the future. right now, we're just
        //capturing on initial arrival, i.e., report creation
      }else{
        if(navigator.geolocation){
          navigator.geolocation.getCurrentPosition((position) => {
            this.options.value[this.groupRepeatIndex][this.repeatIndex] = position.coords.latitude + ',' + position.coords.longitude;
            this.inputChange(this.options, null);
          })
        }
      }
    }

    if(this.options.fieldName === 'DATE_ENTERED'){
      // //console.log('field', this.options);
    }

    if(this.options.fieldName.toUpperCase() == 'RESPONSE_STATUS'){
      var that = this;
      setTimeout(() => {
        that.eventDispatch.dispatch({type: 'set_status_from_child', payload: that.options.value[that.groupRepeatIndex][that.repeatIndex]}, null);
      });
    }

    if(this.options.type === 'image'){
      // this.blobToDataURL(this.options.value[this.repeatIndex], (dataurl) => {
      setTimeout(() => {
        document.getElementById('image_thumb_' + this.options.fieldName + '_' + this.options.tableName + '_' + this.repeatIndex).setAttribute('src', this.options.value[this.groupRepeatIndex][this.repeatIndex]);
      }, 50);
      // })
    }

    // DISABLE VEHICLE NUMBER INPUT
    if(this.options.key === 'VEHIC-0001') {
      this.isDisabled = true;
    }

    if(this.options.type === 'ecrash-ref-multi') {
      let v = this.options.value[this.groupRepeatIndex][this.repeatIndex] || '';
      if(v !== '' && typeof(v) === 'string') {
        this.options.value[this.groupRepeatIndex][this.repeatIndex] = this.options.value[this.groupRepeatIndex][this.repeatIndex].split(',');
      }
    }
  }

  ngAfterViewInit() {
    //handle default values
    if(this.options.value == null || this.options.value[this.groupRepeatIndex] == null || this.options.value[this.groupRepeatIndex][this.repeatIndex] == null || this.options.value[this.groupRepeatIndex][this.repeatIndex] == ''){
      
      if(this.options.value == null) this.options.value = [];
      for(var fgIdx = 0; fgIdx <= this.groupRepeatIndex; fgIdx++) {
        if(this.options.value[fgIdx] == null)
          this.options.value[fgIdx] = [];
        
        for(var sgIdx = 0; sgIdx < this.repeatIndex; sgIdx++) {
          if(this.options.value[fgIdx][sgIdx] === undefined || this.options.value[fgIdx][sgIdx] === null)
            this.options.value[fgIdx][sgIdx] = '';
        }
      }


      if(this.options.type === 'date'){
        this.options.value[this.groupRepeatIndex][this.repeatIndex] = ''; //prevent 1969 being set
      }

      if(this.options.default && this.options.default.length > 0){
        if(this.options.default.indexOf('()') > -1 && this.options.default.indexOf('calc(') == -1){ //is a predefined pseudo-function
          this.options.value[this.groupRepeatIndex][this.repeatIndex] = this.getPseudoFunctionResult(this.options.default);
        }else if(this.options.default == '(autoincrement)'){
          this.options.value[this.groupRepeatIndex][this.repeatIndex] = this.repeatIndex + 1; //repeatIndex is zero-based
        }else if(this.options.default == '(derived)'){
          //this is handled on the API side of things
        }else if(this.options.default.indexOf('calc(') > -1){ // doing a calculated default value
          var params = /calc\((.*);(.*);(.*)\)/gi.exec(this.options.default);
          this.subParams.value = params[1];
          this.subParams.field = params[2];
          this.subParams.condition = params[3];
        }else{
          this.options.value[this.groupRepeatIndex][this.repeatIndex] = this.options.default;
        }

        //this.inputChange(this.options);
      }
    }

    if(this.options.type === 'signature') {
      this.loadSignature(this.options.value[this.groupRepeatIndex][this.repeatIndex])
    }

    if(this.options.type !== 'number' && this.options.conditionallyHideObjects.length > 0){
      setTimeout(() => {
          for(var e = 0; e < this.options.conditionallyHideObjects.length; e++){
            if(!/\d/.test(this.options.conditionallyHideObjects[e])){
              if(this.options.conditionallyHideValue.indexOf(this.options.value[this.groupRepeatIndex][this.repeatIndex]) > -1) {
                if(document.getElementById(this.options.conditionallyHideObjects[e]) !== null) document.getElementById(this.options.conditionallyHideObjects[e]).style.display = 'none';
              }else{
                if(document.getElementById(this.options.conditionallyHideObjects[e]) !== null) document.getElementById(this.options.conditionallyHideObjects[e]).style.display = 'block';
              }
            }
          }
      }, 2000);
    }      
  }

  ngOnDestroy() {
    if(this.eventSubscription !== undefined) this.eventSubscription.unsubscribe();
  }

  onFocus(fieldKey, grIdx, rIdx) {
    this.isActive = true;
    this.eventDispatch.dispatch({type:'set_active_field', payload:{field:fieldKey, groupRepeatIndex:grIdx, repeatIndex:rIdx}}, null);
  }

  onSelectionChange(formElement, value) {
    this.buttonSelected(formElement, { value: value }, false );
  }

  onBlur(formElement) {
    this.isActive = false;
    this.eventDispatch.dispatch({type:'clear_active_field', payload:{}}, null);

    var dataToSend = JSON.parse(JSON.stringify(formElement));
      
    if(dataToSend.type === 'text' && dataToSend.value[this.groupRepeatIndex][this.repeatIndex] != null) dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = dataToSend.value[this.groupRepeatIndex][this.repeatIndex].toUpperCase();
    if(dataToSend.value[this.groupRepeatIndex][this.repeatIndex] == null) return; //only allow for button-group elements
    if(dataToSend.value[this.groupRepeatIndex][this.repeatIndex] == 'null') dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = null;
    if(dataToSend.type === 'date') {
      var dVal = dataToSend.value[this.groupRepeatIndex][this.repeatIndex];
      var formattedVal = '';
      if(dVal != null && dVal !== '') {
        if(dVal.length === 8) {
          formattedVal += dVal.substring(4)+'-'+dVal.substring(0, 2)+'-'+dVal.substring(2, 4);
          //let tzOffset = new Date().getTimezoneOffset();
          let tzOffset = 360;
          let tzHours = Math.floor(tzOffset/60);
          let tzMins = tzOffset - (tzHours*60);
          let hrsString = tzHours < 10 ? '0'+tzHours.toString() : tzHours.toString();
          let minsString = tzMins < 10 ? '0'+tzMins.toString() : tzMins.toString(); 
          
          formattedVal += 'T'+hrsString+':'+minsString;
          
          if(dataToSend.key === 'VEHIC-0017') {
            this.eventDispatch.dispatch({type:'set_dob', payload:{date:formattedVal, groupIndex: this.groupRepeatIndex, subGroupIndex: this.repeatIndex}}, null);
          }
        }
        else {
          return;
        }
        
        dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = formattedVal;
      }
      else {
        dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = '';
      }  
    }
    else if(dataToSend.type === 'time') {
      var dVal = dataToSend.value[this.groupRepeatIndex][this.repeatIndex];
      var formattedVal = '';
      if(dVal != null && dVal !== '') {
        if(dVal.length === 5) {
          formattedVal += '0'+dVal.substring(0, 1)+':'+dVal.substring(1, 3)+':'+dVal.substring(3);
        }
        else if(dVal.length === 3) {
          formattedVal += '0'+dVal.substring(0, 1)+':'+dVal.substring(1);
        }
        else if(dVal.length === 4) {
          formattedVal += dVal.substring(0, 2)+':'+dVal.substring(2);
        }
        else if(dVal.length === 6) {
          formattedVal += dVal.substring(0, 2)+':'+dVal.substring(2, 4)+':'+dVal.substring(4);
        }
        else {
          return;
        }
        dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = formattedVal;
      }
      else {
        dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = '';
      }
      this.options.value = dataToSend.value;
    }
    else if(dataToSend.type === 'phone') {
      var dVal = dataToSend.value[this.groupRepeatIndex][this.repeatIndex];
      var formattedVal = '';
      if(dVal != null && dVal !== '') {
        if(dVal.length === 10) {
          formattedVal = dVal.substring(0,3)+'-'+dVal.substring(3,6)+'-'+dVal.substring(6);
        }
        else {
          return;
        }
        dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = formattedVal;
      }
      else {
        dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = '';
      }
      this.options.value = dataToSend.value;
    }
    else {
      if(dataToSend.type === 'number' && (dataToSend.value[this.groupRepeatIndex][this.repeatIndex] == null || dataToSend.value[this.groupRepeatIndex][this.repeatIndex] === '')) {
        dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = null;
      }
      this.options.value = dataToSend.value;
    }

    
    var that = this;
    //this.isDisabled = true;
    
    // validation required here
    console.log(`Type: ${dataToSend.type}`)
    console.log(`Value: ${dataToSend.value[this.groupRepeatIndex][this.repeatIndex]}`)
    console.log(`Column Name: ${dataToSend.fieldName}`)

    var valid = this.validateFormInput(dataToSend.value[this.groupRepeatIndex][this.repeatIndex], dataToSend.fieldName, dataToSend.type)
    
    console.log(valid)
    if (valid[0]) {
      this.api.updateFormField(this.crashNum, dataToSend, this.groupRepeatIndex, this.repeatIndex)
      .subscribe((data) => {
        //that.isDisabled = false;
        that.isSynced = true;
        that.apiError = false;
        this.eventDispatch.dispatch({type:'clear_sync_ignore', payload:{field:this.options.key, groupRepeatIndex:this.groupRepeatIndex, repeatIndex:this.repeatIndex}}, null);
      },
      (err) => {
        //that.isDisabled = false;
        console.log(err);
        that.apiError = true;
      });
    }
    else { //input is invalid, so display error text
      this.errorText = valid[1]
    }
    
  }

  inputChange(formElement, fieldInput) {
    this.eventDispatch.dispatch({type:'set_sync_ignore', payload:{field:this.options.key, groupRepeatIndex:this.groupRepeatIndex, repeatIndex:this.repeatIndex}}, null);

    if(['text', 'number', 'password', 'date', 'time', 'textarea', 'phone'].includes(formElement.type)) {
      formElement.value[this.groupRepeatIndex][this.repeatIndex] = fieldInput;
      this.options.value[this.groupRepeatIndex][this.repeatIndex] = formElement.value[this.groupRepeatIndex][this.repeatIndex];
      
      // IF THIS FIELD IS BEING CHANGED, BUT DOESN'T HAVE FOCUS, IT IS EITHER ANOTHER
      // USER MAKING THE CHANGE OR THE INPUT MASK.  EITHER WAY, DON'T MARK AS NOT SYNCED
      if(this.isActive == true) {
        this.isSynced = false;
      }

      //handle hiding fields based on this one's value
      if(formElement.type === 'number' && formElement.conditionallyHideObjects.length > 0){
        for(var s = 0; s < formElement.conditionallyHideObjects.length; s++){
          //find how many sections we already have for the section type
          //the dform component sticks a class name on the subsections using the subsection's DOMID 
          //attribute. a quick way to see how many sections we already have displayed is to just 
          //count how many sections with that class exist. this isn't Angular-y, but it saves us
          //from dealing with inter-component communication and is incredibly fast
          var num_visible = document.querySelectorAll('.' + formElement.conditionallyHideObjects[s]).length;

          //find out the difference in what's visible and what the user wants
          var num_needed = parseInt(dataToSend.value[this.repeatIndex]) - num_visible;
          if(num_needed > 0){ //need moar sections
            for(var x = 0; x < num_needed; x++){
              this.eventDispatch.dispatch({type: 'add_section', payload: {'section': formElement.conditionallyHideObjects[s], total: parseInt(dataToSend.value[this.groupRepeatIndex][this.repeatIndex])}}, null);
            }
          }else if(num_needed < 0){ //fewer needed
            num_needed = num_needed * -1; //make it positive
            for(var x = 0; x < num_needed; x++){
              this.eventDispatch.dispatch({type: 'remove_section', payload: {section: formElement.conditionallyHideObjects[s], total: parseInt(dataToSend.value[this.groupRepeatIndex][this.repeatIndex])}}, null);
            }
          }
        }
      }

      if(formElement.type !== 'number' && formElement.conditionallyHideObjects.length > 0){
        for(var e = 0; e < formElement.conditionallyHideObjects.length; e++){
          if(!/\d/.test(formElement.conditionallyHideObjects[e]) && formElement.conditionallyHideValue.indexOf(dataToSend.value[this.groupRepeatIndex][this.repeatIndex]) > -1){
            document.getElementById(formElement.conditionallyHideObjects[e]).style.display = 'none';
          }else{
            document.getElementById(formElement.conditionallyHideObjects[e]).style.display = 'block';
          }
        }
      }
    }
    else {
      this.options.value[this.groupRepeatIndex][this.repeatIndex] = formElement.value[this.groupRepeatIndex][this.repeatIndex];
      this.isSynced = false;

      var dataToSend = JSON.parse(JSON.stringify(formElement));
      if(dataToSend.type !== 'image' && dataToSend.value[this.groupRepeatIndex][this.repeatIndex] == null) return;
      if(dataToSend.type === 'signature') {
        dataToSend.value[this.groupRepeatIndex] = [];
        dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = this.signaturePad.toDataURL();
      }
      if(dataToSend.value[this.groupRepeatIndex][this.repeatIndex] == 'null') dataToSend.value[this.groupRepeatIndex][this.repeatIndex] = null;

      if(formElement.conditionallyHideObjects.length > 0){
        for(var e = 0; e < formElement.conditionallyHideObjects.length; e++){
          if(!/\d/.test(formElement.conditionallyHideObjects[e]) && formElement.conditionallyHideValue.indexOf(dataToSend.value[this.groupRepeatIndex][this.repeatIndex]) > -1){
            document.getElementById(formElement.conditionallyHideObjects[e]).style.display = 'none';
          }else{
            document.getElementById(formElement.conditionallyHideObjects[e]).style.display = 'block';
          }
        }
      }
      
      if(formElement.fieldName.toUpperCase() == 'RESPONSE_STATUS') {
        var that = this;
        setTimeout(() => {
          that.eventDispatch.dispatch({type: 'set_status_from_child', payload: that.options.value[that.groupRepeatIndex][that.repeatIndex]}, null);
        });
      }


      if(dataToSend.type === 'image' && dataToSend.image !=''){
        var reader = new FileReader();
        const that = this;

        reader.onload = (e) => {
          // get loaded data and render thumbnail.
          document.getElementById('image_thumb_' + that.options.fieldName + '_' + that.options.tableName + '_' + that.repeatIndex).setAttribute('src', e.target['result']);
          that.options.value[that.groupRepeatIndex][that.repeatIndex] = e.target['result'];
          dataToSend.value = that.options.value;
            //that.isDisabled = true;
            that.api.updateFormField(that.crashNum, dataToSend, that.groupRepeatIndex, that.repeatIndex)
            .subscribe((data) => {
              //that.isDisabled = false;
              that.isSynced = true;
              this.eventDispatch.dispatch({type:'clear_sync_ignore', payload:{field:this.options.key, groupRepeatIndex:this.groupRepeatIndex, repeatIndex:this.repeatIndex}}, null);
              that.eventDispatch.dispatch({type: 'inputChange', payload: {...formElement}}, null);
            },
            (err) => {
              //console.log(err);
              //that.isDisabled = false;
            });
        };

        // read the image file as a data URL.
        var dom_el = 'image_' + this.options.fieldName + '_' + this.options.tableName + '_' + this.repeatIndex;
        //console.log('dom',dom_el);
        reader.readAsDataURL(document.getElementById(dom_el)['files'][0]);
      }
      else {
        var that = this;
        //this.isDisabled = true;
        this.api.updateFormField(this.crashNum, dataToSend, this.groupRepeatIndex, this.repeatIndex)
        .subscribe((data) => {
          //that.isDisabled = false;
          that.isSynced = true;
          that.apiError = false;
          this.eventDispatch.dispatch({type:'clear_sync_ignore', payload:{field:this.options.key, groupRepeatIndex:this.groupRepeatIndex, repeatIndex:this.repeatIndex}}, null);
        },
        (err) => {
          //that.isDisabled = false;
          console.log(err);
          that.apiError = true;
        });
      }
    }
  }

  // returns array with boolean value and error message
  validateFormInput(input, fieldName, fieldType):[boolean, string] {
    if (input == "") {
      return [true, ""];
    }
    if (fieldName == 'REPORT_NUM_2') { 
      var regex = new RegExp('^[A-L]-\\d{5}-\\d{2}$')
      var match = regex.test(input)
      if (!match) {
        return [false, 'Please match the format A-12345-23']
      }
    }
    //if ()
    return [true, ""];
  }

  setupMap(position){
    var lat = position.split(',')[0];
    var lng = position.split(',')[1];
    const map = L.map('map').setView([lat, lng], 18);
    const that = this;
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);
      var marker = L.marker([lat, lng], {draggable: true})
        .on('dragend', function(event){
          var marker = event.target;
          var position = marker.getLatLng();
          that.options.value[that.repeatIndex] = position.lat + ',' + position.lng;
          that.inputChange(that.options, null);
          marker.setLatLng(new L.LatLng(position.lat, position.lng),{draggable:'true'});
          map.panTo(new L.LatLng(position.lat, position.lng))
        }).addTo(map)
  }


  buttonSelected(formElement, b, isMultiSelect) {
    this.isSynced = false;
    if(isMultiSelect !== true) {
      // TURN OFF THE BUTTON IF IT IS PRESSED WHILE SELECTED
      if(this.options.value[this.groupRepeatIndex][this.repeatIndex] === b.value) {
        this.options.value[this.groupRepeatIndex][this.repeatIndex] = null;
      }
      else {
        this.options.value[this.groupRepeatIndex][this.repeatIndex] = b.value;
      }
    }
    else {
      // MULTI-SELECT BUTTON GROUPS
      if(this.options.value[this.groupRepeatIndex][this.repeatIndex] == null) this.options.value[this.groupRepeatIndex][this.repeatIndex] = [];
      let arrVal = this.options.value[this.groupRepeatIndex][this.repeatIndex];
      if(typeof(arrVal) === 'string') {
        if(arrVal === '') {
          arrVal = [];
        }
        else {
          arrVal = arrVal.split(',');
        }
      }
      // IF USER PRESSES A BUTTON THAT IS ALREADY ACTIVE, DE-SELECT IT
      if(arrVal.includes(b.value)) {
        arrVal.splice(this.options.value[this.groupRepeatIndex][this.repeatIndex].indexOf(b.value), 1);
      }
      else {
        // CHECK IF THERE IS A LIMIT TO THE NUMBER OF SELECTED VALUES THE USER CAN CHOOSE
        if(this.options.allowedSelections == null || this.options.allowedSelections === '' || 
          (!isNaN(parseInt(this.options.allowedSelections))&& arrVal.length < parseInt(this.options.allowedSelections)))
          arrVal.push(b.value);
      }
      this.options.value[this.groupRepeatIndex][this.repeatIndex] = arrVal;
    }

    if(formElement.type !== 'number' && formElement.conditionallyHideObjects.length > 0){
      for(var e = 0; e < formElement.conditionallyHideObjects.length; e++){
        if(!/\d/.test(formElement.conditionallyHideObjects[e]) && formElement.conditionallyHideValue.indexOf(formElement.value[this.groupRepeatIndex][this.repeatIndex]) > -1){
          var el = document.getElementById(formElement.conditionallyHideObjects[e]);
          if(el != null)
            el.style.display = 'none';
        }else{
          var el = document.getElementById(formElement.conditionallyHideObjects[e]);
          if(el != null)
            el.style.display = 'block';
        }
      }
    }

    if(formElement.key === 'VEHIC-0250') {
      var that = this;
      setTimeout(() => {
        that.eventDispatch.dispatch({type: 'carrier_selected', payload: {carrier_num: that.options.value[that.groupRepeatIndex][that.repeatIndex], group_repeat_index: that.groupRepeatIndex}}, null);
      });
    }

    if(!Array.isArray(formElement.value)){
      formElement.value = [formElement.value];
    }
    //this.isDisabled = true;
    this.api.updateFormField(this.crashNum, formElement, this.groupRepeatIndex, this.repeatIndex)
    .subscribe((data) => {
      //this.isDisabled = false;
      this.isSynced = true;
      this.eventDispatch.dispatch({type: 'inputChange', payload: {repeatIndex: this.repeatIndex, ...formElement}}, null);
      if(formElement.fieldName.toUpperCase() == 'RESPONSE_STATUS') {
        var that = this;
        setTimeout(() => {
          that.eventDispatch.dispatch({type: 'set_status_from_child', payload: that.options.value[that.groupRepeatIndex][that.repeatIndex]}, null);
        });
      }
      this.apiError = false;
      this.eventDispatch.dispatch({type:'clear_input_error', payload:{field:this.options.key, groupRepeatIndex:this.groupRepeatIndex, repeatIndex:this.repeatIndex}}, null);
    },
    (err) => {
      //this.isDisabled = false;
      //console.log(err);
      this.apiError = true;
      this.eventDispatch.dispatch({type:'set_input_error', payload:{field:this.options.key, groupRepeatIndex:this.groupRepeatIndex, repeatIndex:this.repeatIndex}}, null);
    }); 
  }


  buttonStyle(b) {
    if(b.value === this.options.value) {
      return "{'background-color': 'red'}";
    }
    else {
      return "'background-color': 'black'";
    }
  }

  clearSignature() {
    this.signaturePad.clear();
    this.inputChange(this.options, null);
  }

  clearImage(field){
    document.getElementById('image_thumb_' + field.fieldName + '_' + field.tableName + '_' + this.groupRepeatIndex + '_' + this.repeatIndex)['src'] = '';
    document.getElementById('image_' + field.fieldName + '_' + field.tableName + '_' + this.groupRepeatIndex + '_' + this.repeatIndex)['value'] = '';
    this.options.value[this.groupRepeatIndex][this.repeatIndex] = '';
    this.inputChange(this.options, null);
  }

  embiggenImage(field){
    const dialogRef = this.dialog.open(ImageDialog, {
      width: '600px',
      data: {formElement: field}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  loadSignature(dataUrl) {
    this.signaturePad.fromDataURL(dataUrl, 
      {
        width: this.sigPadOptions.canvasWidth, 
        height: this.sigPadOptions.canvasHeight
      }
    );
  }

  showField(options) {
    //console.log('show', options);
    this.options.hide = false;
  }

  showHiddenButtons(val){
    this.options.showHiddenItems = val;
  }

  getPseudoFunctionResult(func) {
    switch(func){
      case 'date()':
        var d = new Date();
        d.setHours(0,0,0,0);
        return d.toISOString();
      case 'month()':
        var months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        return months[(new Date).getMonth()];
      case 'day()':
        return (new Date).getDate();
      case 'year()':
        return (new Date).getFullYear() + 1;
      case 'dow()':
        var dows = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
        return dows[(new Date).getDay()];
      case 'time()':
        var now = new Date;
        var mins = (now.getMinutes() < 10)? '0' + now.getMinutes(): now.getMinutes();
        return now.getHours() + ':' + mins;
      case 'hour()':
        return (new Date).getHours();
      case 'minutes()':
        var now = new Date;
        var mins = (now.getMinutes() < 10)? '0' + now.getMinutes(): now.getMinutes();
        return mins;
      default:
        return '';
    }
  }

  checkButtonVal(buttonVal, reportDataVal, isMultiSelect) {
    if(buttonVal == null || reportDataVal == null) {
      return false;
    }
    else {
      if(typeof(reportDataVal) === 'number') {
        reportDataVal = reportDataVal.toString();
      }

      if(isMultiSelect !== true) {
        return buttonVal === reportDataVal;
      }
      else {
        return reportDataVal.includes(buttonVal);
      }
    }
  }

  startScan(formItemDescriptor) {
    console.log('START SCAN');
  }
}



@Component({
  selector: 'image-dialog',
  templateUrl: 'image-dialog.html',
})
export class ImageDialog {

  constructor(
    public dialogRef: MatDialogRef<ImageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
