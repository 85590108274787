import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subject } from 'rxjs';
import { mergeMap, takeUntil, take } from 'rxjs/operators';
import { BackstrapService } from '../backstrap.service';
import { ContentService } from '../shared/content.service';
import { IUrlInfo } from '../contracts/content/iurl-info';

@Component({
  selector: 'app-trifold-view',
  templateUrl: './trifold-view.component.html',
  styleUrls: ['./trifold-view.component.css']
})

export class TrifoldViewComponent implements OnInit, OnDestroy {
  // Subject to help unsubscribe from any active subscriptions on component destroy.
  private unsubscribe: Subject<any> = new Subject<any>();
  isLoading: boolean = true;
  printData: any = {};;
  repeatIndex: number = 0;;
  reportData: any = {};;
  lastUpdate: any;
  formGroups: any[];
  crashId: string;

  constructor(private route: ActivatedRoute,
              private api: BackstrapService,
              private contentService: ContentService) {}

  ngOnInit() {
    let that = this;
    // Subscribe to the data on the route from the trifold-view resolver.
    this.route.data.pipe(
      takeUntil(this.unsubscribe),
      mergeMap((response: { report: any }) => {
        // Set the report response.
        const report = response.report;
        // If the report exists...
        if (report) {
          this.reportData = JSON.parse(JSON.stringify(report));
          this.crashId = this.reportData.crash_num;
          // SET THE LAST UPDATE TO NOW -- USED TO CHECK FOR DIRTY DATA LATER
          this.lastUpdate = new Date();
          var sv = null;
          if (this.reportData.rev_num) {
            sv = this.reportData.rev_num;
          }
          // GET THE SCHEMA VERSION FROM THE DB THAT MATCHES THIS REPORT
          return this.api.getSchema(sv, null);
        }
        console.log('no report found with that id');
        return of(null);
      })
    ).subscribe((data: any) => {
      // If the report exists.
      if (data) {
        that.formGroups = data.schema;
  
        // run through report data, match fields up with schema, build print data
        that.printData = {};
        var ignoredFields = ['city', 'crash_num', 'state_rpt_num', 'report_num'];
        for(var key in that.reportData){
          if(!Array.isArray(that.reportData[key])){
            for(var a = 0; a < that.formGroups.length; a++){ 
              for(var b = 0; b < that.formGroups[a].subGroups.length; b++){ 
                for(var c = 0; c < that.formGroups[a].subGroups[b].formItems.length; c++){
                  var item = that.formGroups[a].subGroups[b].formItems[c];
  
                  if(item.fieldName.toLowerCase() === key && ignoredFields.indexOf(key) === -1){ //found the matching schema item
                    if(that.reportData[key] != null) that.reportData[key] = that.reportData[key].toString();
                    this.printData[key] = {
                      fieldName: item.fieldName,
                      value: [[that.reportData[key]]] || [['']],
                      label: item.label,
                      type: item.type
                    };  
                  }
                }
              }
            }
          }else{
            for(var d = 0; d < that.reportData[key].length; d++){
              for(var bkey in that.reportData[key][d]){
                for(var a = 0; a < that.formGroups.length; a++){
                  for(var b = 0; b < that.formGroups[a].subGroups.length; b++){
                    for(var c = 0; c < that.formGroups[a].subGroups[b].formItems.length; c++){
                      var item = that.formGroups[a].subGroups[b].formItems[c];
                      if(item.fieldName.toLowerCase() === bkey){ //found the matching schema item
                        if(!that.printData.hasOwnProperty(key)) that.printData[key] = []; //{vehic: []}
                        if(!that.printData[key][d]) that.printData[key][d] = [];
                        
                        var ignoredFields = ['VEH_NUM', 'DR_LIC_STATE', 'PLATE_NUM', 'PLATE_TYPE', 'DR_CITY', 'DR_STATE', 'DR_ZIP', 'OWNER_CITY', 'OWNER_STATE', 'OWNER_ZIP'];
                        if(window.location.href.includes('trifold')) {
                          ignoredFields.push('DR_HOME_PHONE');
                        }
                        var skip = false;
                        switch(item.fieldName){
                          case 'DR_LIC_NUM':
                            var licState = that.reportData[key][d]['dr_lic_state'] || '';
                            var licNum = that.reportData[key][d][bkey] || '';
                            that.reportData[key][d][bkey] = licState + ' ' + licNum;
                            item.label = 'License';
                            break;
                          case 'DR_ST':
                            var drAddress = JSON.parse(JSON.stringify(that.reportData[key][d][bkey])) || '';
                            var drState = that.reportData[key][d]['dr_state'] || '';
                            var drZip = that.reportData[key][d]['dr_zip'] || '';
                            var drCity = that.reportData[key][d]['dr_city'] || '';
                            var comma = ', ';
                            if(drState === '' && drZip === '') comma = '';
                            item.label = 'Driver\'s Address';
                            this.reportData[key][d][bkey] = drAddress+' '+drCity + comma + drState + ' ' + drZip;
                            break;
                          case 'OWNER_NAME':
                            item.label = 'Owner\'s Name';
                            break;
                          case 'OWNER_ST':
                            var ownAddress = JSON.parse(JSON.stringify(that.reportData[key][d][bkey])) || '';
                            var ownState = that.reportData[key][d]['owner_state'] || '';
                            var ownZip = that.reportData[key][d]['owner_zip'] || '';
                            var ownCity = that.reportData[key][d]['owner_city'] || '';
                            var comma = ', ';
                            if(ownState === '' && ownZip === '') comma = '';
                            item.label = "Owner's Address";
                            this.reportData[key][d][bkey] = ownAddress+' '+ownCity + comma + ownState + ' ' + ownZip;
                            break;
                          case 'INS_AGENT':
                            item.label = 'Agent Name';
                            break;
                          case 'INS_PHONE':
                            item.label = 'Agent Phone';
                            break;
                          case 'INSCO':
                            item.label = 'Agency';
                            break;
                          case 'INS_POL_NUM':
                            item.label = 'Policy Number';
                            break;
                          case 'DR_HOME_PHONE':
                            item.label = 'Driver\'s Phone';
                            break;
                        }
  
                        if(ignoredFields.indexOf(item.fieldName) === -1 && skip == false){
                          that.printData[key][d].push({
                            fieldName: item.fieldName,
                            value: [that.reportData[key][d][bkey]] || '',
                            label: item.label,
                            type: item.type
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
  
        //clean up fields:
        console.log(this.printData)
        if(this.printData.hasOwnProperty('inter_road') && this.printData.inter_road.value[0] !== null){
          var priRoad = this.printData.pri_road.value[0] || '';
          var interRoad = this.printData.inter_road.value[0] || '';
          this.printData.location = {
              fieldName: 'location',
              value: [[priRoad + ' at ' + interRoad]],
              label: 'Reported Location',
              type: 'text'
            };
        }
        else{
          var pri_road_val = ''
          console.log(this.printData.pri_road)
          if (this.printData.pri_road == undefined) {
            //console.log("yes")
            pri_road_val = ''
          }
          else {
            pri_road_val = this.printData.pri_road.value[0]
          }
          this.printData.location = {
              fieldName: 'location',
              value: pri_road_val,
              label: 'Reported Location',
              type: 'text'
            };              
        }
        this.printData.crash_date.label = 'Date of Accident';
        this.printData.crash_time.label = 'Time of Call';
        if (this.printData.who_entered != undefined) {
          this.printData.who_entered.label = 'OSS Agent Name';
        }
  
        delete this.printData.inter_road;
        delete this.printData.pri_road;
  
        //reformat vehicles to make the template not a nightmare
        var vehicles = [];
        var subarrays = -1;
        for(var v = 0; v < this.printData.vehic.length; v++){
          if((v + 1) % 2 === 1){
            subarrays++;
            if(vehicles[subarrays] === undefined) vehicles[subarrays] = [];
          }
          vehicles[subarrays].push(this.printData.vehic[v]);
        }
  
        this.printData.vehicles = vehicles;
  
        // RUN THROUGH THE REPORT DATA AND FILL IN THE APPROPRIATE
        // FORM FIELDS
        console.log();
        this.populateForm();
      }
    }, (error) => {
      console.log(error);
    });
    // Only send the admin an email or show print if the page is trifold or insDriver.
    this.contentService.urlInfo$.pipe(take(1))
    .subscribe((urlInfo: IUrlInfo) => {
      if (urlInfo.currentUrl.endsWith('/trifold') || urlInfo.currentUrl.endsWith('/insDriver')) {
        // SEND AN EMAIL TO ADMINS WITH DRIVER/INSURANCE INFO
        this.api.sendNotificationToAdmins(this.crashId).subscribe();
        setTimeout(() => {window.print()}, 500);
      } else {
        // Scroll to the top of the window when the page loads on the initial incident report page.
        const top: Element = document.getElementById('topOfInitialReportContent');
        if (top) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      }
    });
  }

  populateForm(){
  	this.isLoading = false;
  }

  formatOptions(o) {
    o.value = [[o.value]];
    return o;
  }

  ngOnDestroy() {
    // Unsubscribe from any subscriptions.
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}