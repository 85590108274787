import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BackstrapService } from '../backstrap.service';

@Injectable({
  providedIn: 'root'
})
export class ReportResolverService {

  constructor(private router: Router,
              private api: BackstrapService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<boolean> {
    let crashId: string = ''
    if (state.url.endsWith('/print')) {
      crashId = route.paramMap.get('crashId');
    } else {
      crashId = route.parent.paramMap.get('crashId');
    }
    // Get the report only if it exists on the route. Otherwise return the user to the dashboard page.
    return crashId ? this.api.getReport(crashId) : this.router.navigate(['/dashbaord']);
  }
}
