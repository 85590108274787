// ==================================================================
// DashboardComponent
//
// The dashboard component includes a tabbed-view component displaying
// tables for reports of various statuses.  To begin with, it shows
// in-progress reports in one tab, and submitted reports in the other.
//
// This component also contains the form to create a brand new report.
// ==================================================================

import { Component, OnInit, ViewChild } from '@angular/core';
import { BackstrapService } from '../backstrap.service';
import { TabbedViewComponent } from '../tabbed-view/tabbed-view.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  showNewReportForm: boolean;
  schemaVersions: number[] = []; // NOT USED
  serviceLocations: any[] = [];
  serviceLocation: any;
  schemaNum: number; // NOT USED
  crashNum: string;
  stateNum: number;
  innerStateNum: string;
  localNum: string;
  reloadTable: number;
  doesNotMatch: boolean;

  @ViewChild(TabbedViewComponent) tabView:TabbedViewComponent;


  constructor(private api: BackstrapService, private router: Router, private route: ActivatedRoute) { 
    this.showNewReportForm = false;
    this.doesNotMatch = false;
  }

  ngOnInit() {
    const serviceLocationId = this.route.snapshot.queryParamMap.get('serviceLocationId');
    this.api.getServiceLocations().subscribe((response: any[]) => {
      this.serviceLocations = response;
      if (response.length > 0) {
        var serviceLocationIndex = response.findIndex(s => s.id == serviceLocationId);
        if (serviceLocationIndex >= 0) {
          this.serviceLocation = this.serviceLocations[serviceLocationIndex];
        }
        else {
          this.serviceLocation = this.serviceLocations[0];
        }
      }
      else {
        this.serviceLocation = null;
      }
    }, (error) => {
      console.log('Error getting service locations: ', error);
    });
  }

  setServiceLocation(event) {
    this.serviceLocation = event.value;
    this.router.navigate(['.'], { 
      relativeTo: this.route, 
      queryParams: { 
        serviceLocationId: event.value.id
      },
      queryParamsHandling: 'merge'
    });
  }

  // THIS DOUBLES AS BOTH A TRIGGER TO SHOW THE NEW REPORT FORM
  // IF IT'S HIDDEN, OR CREATE THE NEW REPORT IF THE FORM IS SHOWN
  createReportPressed() {
    if(this.showNewReportForm === false) {
      this.showNewReportForm = true;
    }
    else {
      var rid = this.crashNum;
      if(this.crashNum === undefined || this.crashNum === null || this.crashNum === '') {
        rid = null;
      }
      // NOT USED
      // var sv = this.schemaNum;
      // if(this.schemaNum === undefined || this.schemaNum === null) {
      //   sv = null;
      // }
      var sid = this.stateNum;
      if(this.stateNum === undefined || this.stateNum === null) {
        sid = null;
      }
      var siid = this.innerStateNum;
      if(this.innerStateNum === undefined || this.innerStateNum === null || this.innerStateNum === '') {
        siid = '';
      }
      var lid = this.localNum;
      if(this.localNum === undefined || this.localNum === null || this.localNum === '') {
        lid = '';
      }

      var regex = new RegExp('^[A-L]-\\d{5}-\\d{2}$')

      this.doesNotMatch = !regex.test(this.localNum)

      console.log(this.doesNotMatch)

      if (this.doesNotMatch) { // if the localNum does not properly match the regex requirements
        console.log("Error: number did not follow regex")


      }
      else {
        // CREATE A NEW REPORT VIA API
        this.api.newReport(null, rid, sid, siid, lid, this.serviceLocation.id)
        .subscribe((data) => {
          this.showNewReportForm = false;
          this.tabView.tabChange({index:0});
          this.reloadTable = Date.now();
        },
        
        (err) => {
          console.log(err);
        });
      }
    }
  }

  // HIDE THE NEW REPORT FORM
  cancelNewReport() {
    this.showNewReportForm = false;
    this.crashNum = '';
    this.schemaNum = null;
    this.stateNum = null;
    this.innerStateNum = '';
    this.localNum = '';
  }
}
