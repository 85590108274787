import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { BackstrapService } from '../backstrap.service';
import { IUrlInfo } from '../contracts/content/iurl-info';
import { ContentService } from '../shared/content.service';

@Component({
  selector: 'app-full-report',
  templateUrl: './full-report.component.html',
  styleUrls: ['./full-report.component.css']
})
export class FullReportComponent implements OnInit, OnDestroy {
  // Subject to help unsubscribe from any active subscriptions on component destroy.
  private unsubscribe: Subject<any> = new Subject<any>();
  meterEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  crashId: string;
	reportData: any;
  currentUser: any;
  // Current URL info for the application. Helps handle which sidenav button is active.
  currentUrl: string = '';
  disablePhotosDocuments: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private api: BackstrapService, 
              private contentService: ContentService) { 
  }

  ngOnInit(){
    // Subscribe to navigation events.
    this.contentService.urlInfo$.pipe(takeUntil(this.unsubscribe))
    .subscribe((urlInfo: IUrlInfo) => {
      this.currentUrl = urlInfo.currentUrl;
      if (this.crashId && this.meterEvent.getValue()) {
        this.meterEvent.next(true);
      }
    });
    this.route.firstChild.data.pipe(take(1))
    .subscribe((response: { boxResponse?: object, report?: object }) => {
      let report: object = {};
      
      report = response.report;
      this.reportData = report;
      
      if (report !== undefined && report !== null) {
        this.reportData = report;
        this.crashId = report['crash_num']; 
      }
      this.meterEvent.next(true);

      console.log(this.reportData)
    }, (error) => {
      console.log('Error loading report: ', error);
    });
    this.meterEvent.pipe(
      takeUntil(this.unsubscribe),
      mergeMap(() => {
        if (event) {
          let eventName = '';
          if (this.currentUrl.endsWith('/initialIncidentReport')) {
            eventName = 'initialIncidentReport';
          } else if (this.currentUrl.includes('/photosAndVideos')) {
            eventName = 'photosAndVideos';
          } else if (this.currentUrl.includes('/documentsAndStatements')) {
            eventName = 'documentsAndStatements';
          } else {
            eventName = 'agentReport';
          }
          return this.api.postMeteredEvent(eventName, this.crashId)
        }
        return of(null);
      })
    ).subscribe(() => {}, (error) => {
      console.log('Error metering event: ', error);
    });

    this.disablePhotosDocuments = false;
  }

  /**
   * Navigate back to the carrier table.
   */
  backToCarrierDashboard() {
    // Get the carrier from the last carrier query. This allows the user to navigate
    // back to the carrier dashboard and load the table with the carrier if it exists
    // in session storage.
    const carrier: string = sessionStorage.getItem('lastCarrierQuery');
    carrier ? this.router.navigate(['/carrierDashboard'], {
      queryParams: {
        carrier
      }
    }) : this.router.navigate(['/carrierDashboard']);
  }

  /**
   * Print the current page.
   */
  printPage() {
    window.print();
  }

  ngOnDestroy() {
    // Unsubscribe from any subscriptions.
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
