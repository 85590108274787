// ==================================================================
// EventDispatchService
//
// The EventDispatchService performs two functions.  First, it is a
// global event emitter, so any component (with this service imported)
// can dispatch an event {type: '', payload:''} to all components
// which have subscribed.
//
// After importing and adding this service to the constructor args,
// you can emit an event using:
// eventDispatch.dispatch(myEvent, null);
//
// The second argument (with a null value in this case) relates to the
// second function of this class which is to operate as a global
// store.  When dispatching a message, you can also set data in the
// store.
// Otherwise, you can set data using setState(newState) or
// updateState(updateObj).  Update state only overwrites the variables
// found in the updateObj.
//
// Any comonent with EventDispatchService can pull down the global
// state using getState().
//
// The data in the global store will persist until a browser refresh.
// ==================================================================

import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventDispatchService {
	emitter = new EventEmitter();
	state: any;
  
  constructor() { 
  	this.state = {};
  }

  getState() {
  	return this.state;
  }

  setState(newState) {
  	this.state = newState;
  	return this.state;
  }

  updateState(updateObj) {
  	var keys = Object.keys(updateObj);
  	for(var kIdx = 0; kIdx < keys.length; kIdx++) {
  		this.state[keys[kIdx]] = updateObj[keys[kIdx]];
  	}
  	return this.state;
  }

  dispatch(data:any, stateObj:any) {
  	if(stateObj !== undefined && stateObj !== null) {
  		this.updateState(stateObj);
  	}

    // console.log(stateObj, data);
  	
  	this.emitter.emit(data);
  }
}