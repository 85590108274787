// ==================================================================
// MessagingComponent
//
// This is a basic Toast component.  It displays a message on a
// colored background based on message type (success, warning, error),
// and automatically removes it after 5 seconds.
//
// For OSS, this is only being used by the AuthComponent
//
// Adjust the CSS if absolute positioning is needed.
// ==================================================================

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.css']
})
export class MessagingComponent implements OnInit {
	show = false;
	message = '';
	bgColor = 'rgb(0, 190, 128)';

  constructor() { }

  ngOnInit() {
  }

  displayMessage(msg, msgType) {
  	switch(msgType) {
  		case 'success':
  			this.bgColor = 'rgb(0, 190, 128)';
  			break;
  		case 'warning':
  			this.bgColor = 'rgb(225, 225, 65)';
  			break;
  		case 'error':
  			this.bgColor = 'rgb(200, 50, 0)';
  			break;
  		default:
  			this.bgColor = 'rgb(0, 190, 128)';
  	}

  	this.message = msg;
  	this.show = true;

  	setTimeout(this.hideMessage.bind(this), 5000);
  }

  hideMessage() {
	  this.show = false;
	  this.message = '';
  }
}
