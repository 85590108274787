
// LOCAL
// -------------
// export const BASE_URL = 'http://localhost:3000';
// export const BASE_URL = 'http://localhost:5015';

// DEVELOPMENT
// ------------
export const BASE_URL = 'https://dev-api.onsceneservices.com';


// PROD
// -------------
// export const BASE_URL = 'https://api.onsceneservices.com';



// DO NOT CHANGE THE ORDER OR CARRIERS.  IT MATCHES THE ORDER OF IDS IN THE DB.
// INSTEAD OF THIS, WE SHOULD MAKE A CALL TO THE BACK END ON INITIALIZATION TO
// GET A LIST OF LEGAL NAMES FOR THE CARRIERS IN ORDER OF DB ID.
export const CARRIERS = [
  'State Farm Insurance',
  'Progressive Insurance Company',
  'Geico Casualty Insurance',
  'Allstate Property & Casualty',
  'Self Insured',
  'USAA',
  'GoAuto Insurance Company',
  'Houston Specialty Insurance',
  'Root Insurance'
];
