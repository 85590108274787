import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SignaturePad } from 'angular2-signaturepad';
import { BackstrapService } from '../../../backstrap.service';
import { EventDispatchService } from '../../../event-dispatch.service';
import { ImageDialog } from '../../../form-item/form-item.component';

@Component({
  selector: '[app-print-agent-report]',
  templateUrl: './print-agent-report.component.html',
  styleUrls: ['./print-agent-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrintAgentReportComponent implements OnInit {
	@Input() options: any;
  @Input() crashNum: string;
  @Input() groupRepeatIndex: number;
  @Input() repeatIndex: number;
  @Input() itemIndex: number;
  @Input() vehicle: any;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

	inputTimer: any;
  isSynced: boolean;
  sigPadOptions: any;
  eventSubscription: any;

  brokenImage: boolean = false;


  constructor(private api: BackstrapService, private eventDispatch: EventDispatchService, public dialog: MatDialog) { 
  	this.inputTimer = null;
    this.isSynced = true;
    this.sigPadOptions = {
      canvasWidth: 600,
      canvasHeight: 100,
      backgroundColor: '#EEEEEE'
    };
  }


  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  ngOnInit() {

    if(this.options.value == null) { 
      this.options.value = [];
    }
    if(this.options.value[this.groupRepeatIndex] == null) {
      this.options.value[this.groupRepeatIndex] = [];
    }

    if(this.groupRepeatIndex == null) {
      this.groupRepeatIndex = 0;
    }
    else {
      this.groupRepeatIndex--;
    }

    if(this.repeatIndex == null) {
      this.repeatIndex = 0;
    }
    else {
      this.repeatIndex--;
    }

    if(this.options.type === 'map' && this.options.value[this.groupRepeatIndex] && this.options.value[this.groupRepeatIndex][this.repeatIndex] != undefined){
      this.options.lat = this.options.value[this.groupRepeatIndex][this.repeatIndex].split(',')[0];
      this.options.lng = this.options.value[this.groupRepeatIndex][this.repeatIndex].split(',')[1];
      this.options.value[this.groupRepeatIndex][this.repeatIndex] = 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/pin-s(' + this.options.lng + ',' + this.options.lat + ')/' + this.options.lng + ',' + this.options.lat + ',16,0,0/600x300?access_token=pk.eyJ1IjoiZ2dhdWNoZXQiLCJhIjoiY2pucWY5OGF3MDFtZTN0bGpvZDhwMzExbSJ9.m1MFQimz7bcxNru8iyDGYQ';
    }

    if(this.options.type === 'date'){
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      if(this.options.value[this.groupRepeatIndex][0] != null && this.options.value[this.groupRepeatIndex][0] !== '') {
        var formattedVal = null;
        var d = null;

        if(this.options.value[this.groupRepeatIndex][0].indexOf('-') !== -1) {
          var formattedVal = this.options.value[this.groupRepeatIndex][0];
          d = new Date(formattedVal);
          this.options.value[this.groupRepeatIndex][0] = days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
        }
        else if(this.options.value[this.groupRepeatIndex][0].indexOf(',') === -1) {
          formattedVal = this.options.value[this.groupRepeatIndex][0].substring(4)+'-'+this.options.value[this.groupRepeatIndex][0].substring(0, 2)+'-'+this.options.value[this.groupRepeatIndex][0].substring(2, 4);
          let tzOffset = new Date().getTimezoneOffset();
          let tzHours = Math.floor(tzOffset/60);
          let tzMins = tzOffset - (tzHours*60);
          let hrsString = tzHours < 10 ? '0'+tzHours.toString() : tzHours.toString();
          let minsString = tzMins < 10 ? '0'+tzMins.toString() : tzMins.toString(); 
          
          formattedVal += 'T'+hrsString+':'+minsString;
          
          d = new Date(formattedVal);
          this.options.value[this.groupRepeatIndex][0] = days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
        }  
      }
      else {
        this.options.value[this.groupRepeatIndex][0] = '';
      }
    }

    this.eventSubscription = this.eventDispatch.emitter.subscribe((data) => {
      switch(data.type) {
        case 'report_load': 
          console.log('caught report load event');
          if(this.options.type === 'signature') {}
          break;
        default:
      }
    },
    (err) => {
      console.log(err);
    });
  }

  ngAfterViewInit() {
    if(this.options.type === 'signature') {
      // this.loadSignature(this.options.value[this.repeatIndex])
    }
    if(this.options.type === 'image'){
      let fieldName = this.options.fieldName;
      let imgData = this.options.value[this.repeatIndex];
        let image = document.getElementById('image_thumb_' + fieldName);
        if (image) {
          image.setAttribute('src', imgData);
        }
    }
  }

  ngOnDestroy() {
    if(this.eventSubscription != null) {
      this.eventSubscription.unsubscribe();
    }
  }


  buttonStyle(b) {
  	if(b.value === this.options.value) {
  		return "{'background-color': 'red'}";
  	}
  	else {
  		return "'background-color': 'black'";
  	}
  }

  embiggenImage(field){
    const dialogRef = this.dialog.open(ImageDialog, {
      width: '600px',
      data: {formElement: field}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  
  brokenLink() {
    this.brokenImage = true;
  }
}
