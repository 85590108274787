import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { BackstrapService } from '../backstrap.service';


@Component({
  selector: 'app-admin-controls',
  templateUrl: './admin-controls.component.html',
  styleUrls: ['./admin-controls.component.css']
})
export class AdminControlsComponent implements OnInit {
  insCsvStartDate: any;
  insCsvEndDate: any;

  constructor(private api: BackstrapService) { }

  ngOnInit() {
  }

  exportInsuranceCsv() {
    var startDate = null;
    if(this.insCsvStartDate != null && this.insCsvStartDate !== '') {
      var sd = new Date();
      sd.setFullYear(this.insCsvStartDate.substring(0,4));
      sd.setMonth(parseInt(this.insCsvStartDate.substring(5,7))-1);
      sd.setDate(parseInt(this.insCsvStartDate.substring(8,10)));
      sd.setHours(0,0,0,0);
      startDate = sd.toISOString();
    }

    var endDate = null;
    if(this.insCsvEndDate != null && this.insCsvEndDate !== '') {
      var ed = new Date();
      ed.setFullYear(this.insCsvEndDate.substring(0,4));
      ed.setMonth(parseInt(this.insCsvEndDate.substring(5,7))-1);
      ed.setDate(parseInt(this.insCsvEndDate.substring(8,10)));
      ed.setHours(0,0,0,0);
      endDate = ed.toISOString();
    }

    this.api.getInsuranceInfoBetweenDates(startDate, endDate)
    .subscribe((api_res) => {
      var fileBody = '';
      var csvHeaders = 'NOPD item num, status, insurance company, agent, phone, policy no, vehicle owner, owner phone, driver, driver phone';
      var numVehicles = 1;

      var nopdItemNums = Object.getOwnPropertyNames(api_res);
      nopdItemNums.forEach((rn) => {
        var rep = api_res[rn];
        var maxIdx = rep.length - 1;

        // SEE IF WE NEED TO ADD MORE HEADERS
        if(maxIdx+1 > numVehicles) {
          let dif = maxIdx + 1 - numVehicles;
          for(var vIdx = 0; vIdx < dif; vIdx++) {
            csvHeaders += ', insurance company, agent, phone, policy no, vehicle owner, owner phone, driver, driver phone';
          }
          numVehicles = maxIdx+1;
        }

        fileBody += rn+', RTF, ';
        rep.forEach((veh, idx) => {
          console.log(veh);
          fileBody += removeCommas(veh.insco)+', '+removeCommas(veh.ins_agent)+', '+removeCommas(veh.ins_phone)+', '+
                      removeCommas(veh.ins_pol_num)+', '+removeCommas(veh.owner_name)+', '+removeCommas(veh.owner_home_phone)+', '+
                      removeCommas(veh.dr_name)+', '+removeCommas(veh.dr_home_phone);
          if(idx !== maxIdx) {
            fileBody += ', ';
          }
        });
        fileBody += '\n';
      });

      csvHeaders += '\r\n';
      fileBody = csvHeaders+fileBody;

      let b = new Blob([fileBody], {type: 'text/plain'});
      var shortStartDate = startDate ? startDate.substring(0, 10) : 'XXXX-XX-XX';
      var shortEndDate = endDate ? endDate.substring(0, 10) : 'XXXX-XX-XX';
      var exportFileName = 'InsuranceExport_'+shortStartDate+'_'+shortEndDate+'.csv';
      saveAs(b, exportFileName);
    },
    (api_err) => {
      if(api_err) {
        alert('There was a problem getting the reports from the server.');
      }
    });
  }

}

function removeCommas(inString) {
  if(inString != null) {
    return inString.replace(/,/g, '');
  }
  else {
    return '';
  }
}
