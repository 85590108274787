import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BackstrapService } from '../backstrap.service';

@Component({
  selector: 'app-print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.css']
})

export class PrintViewComponent implements OnInit {
  crashId: string;
	isLoading: boolean;
	reportData: any;
	lastUpdate: any;
	formGroups: any[];


  constructor(private route: ActivatedRoute, private api: BackstrapService, 
              private router: Router) { 
    this.crashId = null;
    this.isLoading = true;
  }

  ngOnInit(){
  	this.crashId = this.route.snapshot.paramMap.get('crashId');
  	if(this.crashId !== null && typeof(this.crashId) !== 'undefined') {
  		// GET THE REPORT FROM THE DB
  		this.api.getReport(this.crashId)
  		.subscribe((data) => {
  		  if(data !== undefined && data !== null) {
  		    this.reportData = data;

  		    // SET THE LAST UPDATE TO NOW -- USED TO CHECK FOR DIRTY DATA LATER
  		    this.lastUpdate = new Date();
  		    var sv = null;
  		    if(this.reportData.rev_num !== undefined && this.reportData.rev_num !== null) {
  		      sv = this.reportData.rev_num;
  		    }

  		    // GET THE SCHEMA VERSION FROM THE DB THAT MATCHES THIS REPORT
  		    this.api.getSchema(sv, null)
  		    .subscribe((data:any) => {
  		      this.formGroups = data.schema;

  		      // RUN THROUGH THE REPORT DATA AND FILL IN THE APPROPRIATE
  		      // FORM FIELDS
            this.populateForm();
            setTimeout(() => {window.print()}, 500);
  		    },
  		    (err) => {
  		      console.log(err);
  		    });
  		  }
  		  else {
  		    console.log('no report found with that id');
  		  }
  		},
  		(err) => {
  		  console.log(err);
  		});
	  }
  }

  trackByHandler(index: any, item: any) {
    return index;
  }

  populateForm(){
  	var reportKeys = Object.keys(this.reportData);

    // FOR EACH GROUP IN THE FORM SCHEMA
    for(var gIdx = 0; gIdx < this.formGroups.length; gIdx++) {
      var formGroup = this.formGroups[gIdx];
      // GET ALL THE SUB GROUPS OF THAT GROUP
      for(var sgIdx = 0; sgIdx < formGroup.subGroups.length; sgIdx++) {
        var subGroup = formGroup.subGroups[sgIdx];
        // GET ALL FORM ITEMS FOR EACH SUBGROUP
        for(var fIdx = 0; fIdx < subGroup.formItems.length; fIdx++) {
          var formItem = subGroup.formItems[fIdx];

          // GET THE FIELD NAME FOR THIS FORM ITEM
          // IT WILL BE THE KEY IN THE REPORT DATA
          var thisKey = formItem.fieldName.toLowerCase();

          // IF WE DON'T HAVE THE TABLE NAME SPECIFIED,
          // DERIVE IT FROM THE FORM ITEM KEY
          formItem.tableName = formItem.table;
          if(formItem.table == null || formItem.table === ''){
            formItem.tableName = formItem.key.split('-')[0];
          }
          var thisTable = formItem.tableName.toLowerCase();


          // FOR EACH KEY IN THE REPORT DATA
          for(var rIdx = 0; rIdx < reportKeys.length; rIdx++) {
            var reportKey = reportKeys[rIdx];

            // IF THIS REPORT DATA IS AN OBJECT, THERE ARE MULTIPLE
            // FORM ITEMS FOR THIS TABLE.  RUN THROUGH EACH REPORT KEY
            // TO FIND THE CORRECT TABLE, THEN RUN THROUGH THE INNER KEYS
            // TO FIND THE KEY IN QUESTION
            if(this.reportData[reportKey] !== undefined &&
                this.reportData[reportKey] !== null && 
                typeof(this.reportData[reportKey]) === 'object') {
              // FOUND THE TABLE
              if(reportKey.toLowerCase() === thisTable) {
                for(var irIdx = 0; irIdx < this.reportData[reportKey].length; irIdx++) {
                  var tableKeys = Object.keys(this.reportData[reportKey][irIdx]);

                  for(var ikIdx = 0; ikIdx < tableKeys.length; ikIdx++) {
                    var tableKey = tableKeys[ikIdx];
                    // FOUND THE SPECIFIC KEY FOR THIS FORM ITEM
                    if(tableKey.toLowerCase() === thisKey) {
                      
                      // FIGURE OUT WHICH GROUP ITERATION AND SUBGROUP ITERATION THIS APPLIES TO
                      var groupIndex = 0;
                      var subGroupIndex = 0;
                      if(formGroup.repeats === true && thisTable === formGroup.key) {
                        groupIndex = this.reportData[reportKey][irIdx][subGroup.parentGroupKey] - 1;
                      }
                      else if(formGroup.repeats === true) {
                        groupIndex = this.reportData[reportKey][irIdx][subGroup.parentGroupKey] - 1;
                        subGroupIndex = this.reportData[reportKey][irIdx][subGroup.groupKey] - 1;
                      }
                      else if(subGroup.repeats === true) {
                        subGroupIndex = this.reportData[reportKey][irIdx][subGroup.groupKey] - 1;
                      }

                      if(formItem.value == null) formItem.value = [];
                      for(var gi = 0; gi <= groupIndex; gi++) {
                        if(formItem.value[gi] === undefined || formItem.value[gi] === null) {
                          formItem.value[gi] = [['']];
                        }
                        for(var ri = 0; ri <= subGroupIndex; ri++) {
                          if(formItem.value[gi][ri] === undefined || formItem.value[gi][ri] === null) {
                            formItem.value[gi][ri] = '';
                          }
                        }
                      }
                      
                      // THE BACK END FOR YES/NO FORM ITEMS STORES t/f IN THE DB.
                      // CHECK FOR YES/NO FORM ITEMS AND CONVERT true/false -> Y/N
                      if(formItem.items !== undefined && formItem.items !== null && formItem.items.length === 2 && 
                        (formItem.items[0].value.toLowerCase() === 'y' || formItem.items[0].value.toLowerCase() === 'n') &&
                        (formItem.items[1].value.toLowerCase() === 'y' || formItem.items[1].value.toLowerCase() === 'n')) {
                        if(this.reportData[reportKey][irIdx][tableKey] === true) {
                          formItem.value[groupIndex][subGroupIndex] = 'Y';
                        }
                        else {
                          formItem.value[groupIndex][subGroupIndex] = 'N';
                        }
                      }
                      else if(formItem.type === 'date') {
                        if(this.reportData[reportKey][irIdx][tableKey] != null && this.reportData[reportKey][irIdx][tableKey] !== '') {
                          var d = new Date(this.reportData[reportKey][irIdx][tableKey]);
                          var monthNum = d.getMonth()+1;
                          var monthString = monthNum < 10 ? '0'+monthNum.toString() : monthNum.toString();
                          var dayString = d.getDate() < 10 ? '0'+d.getDate().toString() : d.getDate().toString();
                          var yearString = d.getFullYear().toString();
                          var dateVal = monthString+dayString+yearString;

                          formItem.value[groupIndex][subGroupIndex] = dateVal;
                        }
                        else {
                          formItem.value[groupIndex][subGroupIndex] = '';
                        }
                      }
                      else if(formItem.type === 'time') {
                        if(this.reportData[reportKey][irIdx][tableKey] != null && this.reportData[reportKey][irIdx][tableKey] !== '') {
                          formItem.value[groupIndex][subGroupIndex] = this.reportData[reportKey][irIdx][tableKey].replace(new RegExp('\\:', 'g'), '');
                        }
                        else {
                          formItem.value[groupIndex][subGroupIndex] = '';
                        }
                      }
                      // OTHERWISE THIS IS A NORMAL FIELD WHOSE VALUE IS STORED
                      // ON THE BACK END THE SAME AS ON THE FRONT
                      else {
                        formItem.value[groupIndex][subGroupIndex] = this.reportData[reportKey][irIdx][tableKey];
                      }

                      break;
                    }
                  }
                }
              }
            }
            else {
              if(reportKeys[rIdx].toLowerCase() === thisKey) {
                if(formItem.value == null) formItem.value = [];
                if(formItem.value[0] == null || formItem.value[0].length === 0) {
                  formItem.value[0] = [''];
                }
                // THE BACK END FOR YES/NO FORM ITEMS STORES t/f IN THE DB.
                // CHECK FOR YES/NO FORM ITEMS AND CONVERT true/false -> Y/N
                if(formItem.items !== undefined && formItem.items !== null && formItem.items.length === 2 && 
                  (formItem.items[0].value.toLowerCase() === 'y' || formItem.items[0].value.toLowerCase() === 'n') &&
                  (formItem.items[1].value.toLowerCase() === 'y' || formItem.items[1].value.toLowerCase() === 'n')) {
                  if(this.reportData[reportKey] === true) {
                    formItem.value[0] = ['Y'];
                  }
                  else {
                    formItem.value[0] = ['N'];
                  }
                }
                else if(formItem.type === 'date') {
                  if(this.reportData[reportKey] != null && this.reportData[reportKey] !== '') {
                    var d = new Date(this.reportData[reportKey]);
                    var monthNum = d.getMonth()+1;
                    var monthString = monthNum < 10 ? '0'+monthNum.toString() : monthNum.toString();
                    var dayString = d.getDate() < 10 ? '0'+d.getDate().toString() : d.getDate().toString();
                    var yearString = d.getFullYear().toString();
                    var dateVal = monthString+dayString+yearString;

                    formItem.value[0] = [dateVal];
                  }
                  else {
                    formItem.value[0] = [''];
                  }
                }
                else if(formItem.type === 'time') {
                  if(this.reportData[reportKey] != null && this.reportData[reportKey] !== '') {
                    formItem.value[0][0] = this.reportData[reportKey].replace(new RegExp('\\:', 'g'), '');
                  }
                  else {
                    formItem.value[0] = [''];
                  }
                }
                // OTHERWISE THIS IS A NORMAL FIELD WHOSE VALUE IS STORED
                // ON THE BACK END THE SAME AS ON THE FRONT
                else {
                  formItem.value[0] = [this.reportData[reportKey]];
                }
                break;
              }
            }
          }
        }
      }
    }
    this.isLoading = false;
  }
}