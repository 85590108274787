import { NgModule } from '@angular/core';

import { ContentService } from './content.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  entryComponents: [ConfirmationDialogComponent],
  imports: [MaterialModule],
  exports: [ConfirmationDialogComponent],
  providers: [
    ContentService
  ]
})
export class SharedModule { }
