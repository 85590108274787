import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
// import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment} from 'moment';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxMaskModule } from 'ngx-mask';
import { AdminControlsComponent } from './admin-controls/admin-controls.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { CarrierDashboardComponent } from './carrier-dashboard/carrier-dashboard.component';
import { CurrentUserComponent } from './current-user/current-user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DformComponent } from './dform/dform.component';
import { FormItemComponent, ImageDialog } from './form-item/form-item.component';
import { AgentReportComponent } from './full-report/agent-report/agent-report.component';
import { FullReportComponent } from './full-report/full-report.component';
import { LocalePipe } from './locale.pipe';
import { MessagingComponent } from './messaging/messaging.component';
import { PrintItemComponent } from './print-item/print-item.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { PublicReportComponent } from './public-report/public-report.component';
import { ReportListComponent } from './report-list/report-list.component';
import { SharedModule } from './shared/shared.module';
import { TabbedViewComponent } from './tabbed-view/tabbed-view.component';
import { TrifoldViewComponent } from './trifold-view/trifold-view.component';
import { MaterialModule } from './shared/material.module';
import { PrintAgentReportComponent } from './full-report/agent-report/print-agent-report/print-agent-report.component';
import { GalleryComponent } from './full-report/gallery/gallery.component';

// const moment = _rollupMoment || _moment;
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    AppComponent,
    FormItemComponent,
    DformComponent,
    DashboardComponent,
    TabbedViewComponent,
    ReportListComponent,
    AuthComponent,
    MessagingComponent,
    CurrentUserComponent,
    PrintViewComponent,
    PrintItemComponent,
    TrifoldViewComponent,
    ImageDialog,
    AdminControlsComponent,
    CarrierDashboardComponent,
    LocalePipe,
    FullReportComponent,
    PublicReportComponent,
    AgentReportComponent,
    PrintAgentReportComponent,
    GalleryComponent
  ],
  entryComponents: [
    ImageDialog
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //AmazingTimePickerModule,
    // MatMomentDateModule,
    // DateAdapter,
    // MAT_DATE_LOCALE,
    // MAT_DATE_FORMATS
    SignaturePadModule,
    SharedModule,
    MaterialModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    // {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
