// ================================================================
// AppComponent
//
// This is the parent component for the whole app.
//  
// When it is initialized, it performs a "check token" call to the API 
// to see if the user's current token is still valid.
//
// This component includes the title bar with all associated
// functionality as well as the <router-outlet> which is populated
// by the other components based on routing and can be considered
// the main content view.
// ================================================================

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BackstrapService } from './backstrap.service';
import { EventDispatchService } from './event-dispatch.service';
import { ContentService } from './shared/content.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  // Subject to help unsubscribe from any active subscriptions on component destroy.
  private unsubscribe: Subject<any> = new Subject<any>();
  title = 'app';
  eventSubscription: any;
  showTitle: boolean;
  reportIsOpen: boolean;
  reportNum2: string; // NOPD ITEM NUM
  reportNum: string;  // OSS ITEM NUM
  homeLink: string;
  isPrintAgentReport: boolean;

  constructor(private router: Router, 
              private api: BackstrapService,
              private eventDispatch: EventDispatchService,
              private contentService: ContentService,
              private location: Location) {
    setTimeout(() => {this.showTitle = true;});
    this.reportIsOpen = false;
  }

  ngOnInit() {
    this.updateHomeLink();

  	// ============================================
    // EVENT DISPATCH HANDLERS --
    // data INCLUDES A type AND payload PROPERTY
    // ============================================
    this.eventSubscription = this.eventDispatch.emitter.subscribe((data) => {
      switch(data.type) {
        case 'authComponent':
          if(data.payload === 'open') {
            setTimeout(() => {
              this.showTitle = false;
            });
          }
          else if(data.payload === 'closed') {
            setTimeout(() => {
              this.showTitle = true;
              this.updateHomeLink();
            });
          }
          break;
        case 'reportComponent':
          setTimeout(() => {
            this.reportIsOpen = data.payload.isOpen;
          })
          break;
        case 'reportIds':
          setTimeout(() => {
            this.reportNum2 = data.payload.report_num_2;
            this.reportNum = data.payload.report_num;
          })
          break;
        default:
      }
    },
    (err) => {
      console.log(err);
    });

    this.router.events.pipe(takeUntil(this.unsubscribe))
    .subscribe((event) => this._navigationInterceptor(event));
  }

  /**
   * Intercept navigation events.
   * @param {any} event
   */
  private _navigationInterceptor(event) {
    // Set the previous and current url when navigation has finished.
    if (event instanceof NavigationEnd) {
      this.contentService.setUrlInfo(event.url !== event.urlAfterRedirects ? event.urlAfterRedirects : event.url);
      this.isPrintAgentReport = event.url.startsWith('/crashReport') && event.url.endsWith('/print');
    }
  }

  goToDashboard() {
    this.location.back();
  }

  toggleMenu() {
    console.log('toggle emit');
    this.eventDispatch.dispatch({type: 'toggle-hamburger'}, null);
  }

  updateHomeLink() {
    if(localStorage.getItem('oss_user') != null) {
      try {
        var currentUser = JSON.parse(localStorage.getItem('oss_user'));
        if(currentUser.is_carrier && !currentUser.is_admin) {
          this.homeLink = '/carrierDashboard';
        }
        else {
          this.homeLink = '/dashboard';
        }
      }
      catch(e) {
        console.log(e);
        this.homeLink = '/dashboard';
      }
    }
    else {
      this.homeLink = '/';
    }
  }

  ngOnDestroy() {
    // KILL THE SUBSCRIPTION TO EVENT DISPATCH
    this.eventSubscription.unsubscribe();
    // Unsubscribe from any subscriptions.
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
