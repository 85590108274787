import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUrlInfo } from '../contracts/content/iurl-info';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private urlInfo: BehaviorSubject<IUrlInfo> = new BehaviorSubject<IUrlInfo>({
    previousUrl: null,
    currentUrl: null
  });
  public urlInfo$: Observable<IUrlInfo> = this.urlInfo;

  constructor() { }

  /**
   * Set the url info for the application.
   * @param {string} currentUrl
   */
  public setUrlInfo(currentUrl: string) {
    const previousUrl: string = this.urlInfo.getValue().currentUrl
    this.urlInfo.next({
      previousUrl: previousUrl,
      currentUrl: currentUrl
    });
  }

}
