import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSelectChange } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { BackstrapService } from '../backstrap.service';

interface ICarrier {
  id: number;
  name: string;
  legal_name: string;
  emails: any;
  customer_type: 'individual' | 'bulk';
}

@Component({
  selector: 'app-carrier-dashboard',
  templateUrl: './carrier-dashboard.component.html',
  styleUrls: ['./carrier-dashboard.component.css']
})
export class CarrierDashboardComponent implements OnInit {
  // Mapped to the carrier selector.
  carriers: ICarrier[];
  carrierMapping: object = {
    'state farm': 'State Farm Insurance',
    'progressive': 'Progressive Insurance Company',
    'geico': 'Geico Casualty Insurance',
    'allstate': 'Allstate Property & Casualty',
    'self insured': 'Self Insured',
    'usaa': 'USAA',
    'goauto': 'GoAuto Insurance Company',
    'houston specialty': 'Houston Specialty Insurance',
    'root': 'Root Insurance'
  };
  carrierSelector: string = null;
  selectedCarrier: string = null;
  crashReports: any;
  displayedColumns = ['nopd_item_num', 'date_of_crash', 'driver_number', 'policy_number', 'insured', 'last_update', 'investigating_agency'];
  currentUser: any;
  showAdminControls: boolean;
  currentPage: number;
  pageSize: number;

  constructor(private api: BackstrapService,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog) { }

  ngOnInit() {
    let that = this;
    this.currentPage = 0;
    this.pageSize = 25;
    this.api.getInsuranceCarriers()
    .subscribe((carriers: ICarrier[]) => {
      // RESET THE CARRIER MAP WITH DATA FROM THE BACK END
      that.carrierMapping = {};
      
      carriers.forEach((cr) => {
        if(cr.id !== 1) {
          that.carrierMapping[cr.name] = cr.legal_name;
        }
      });

      that.carriers = carriers;

      try {
        // IF THIS USER IS A SUPER-USER OR ADMIN-USER
        that.currentUser = JSON.parse(localStorage.getItem('oss_user'));
        // Get the carrier from the route.
        const carrier: string = that.route.snapshot.queryParamMap.get('carrier');
        that.selectedCarrier = carrier;
        if(that.currentUser.is_admin) {
          that.showAdminControls = true;
          if (carrier) {
            that.getCarrier();
          }
        }
        else {
          that.showAdminControls = false;
          if(that.currentUser.is_carrier) {
            that.selectedCarrier = Object.keys(that.carrierMapping).find(cmk => that.carrierMapping[cmk] === that.currentUser.carrier);
            
            that.getCarrier();
          }
          // THIS USER SHOULDN'T BE HERE.
          else {
            that.router.navigate(['/']);
          }
        }
      }
      catch(e) {
        console.log(e);
      }
    }, (error) => {
      console.log('error getting carriers: ', error);
    });
  }

  /**
   * Event fired when the carrier selector value has changed.
   * @param event MatSelectChange 
   */
  selectCarrier(event: MatSelectChange) {
    const carrier: string = event.value;
    // If the carrier value doesn't equal 'none', get the data by carrier.
    // Otherwise  set the data to an empty array.
    if (carrier !== 'none') {
      this.currentPage = 0;
      this.selectedCarrier = carrier;
      this.getCarrier();
    } else {
      this.crashReports = [];
    } 
  }

  /**
   * Get the carrier data.
   * @param carrier string
   */
  getCarrier() {
    // Set the carrier the user is querying by in session storage for later use.
    sessionStorage.setItem('lastCarrierQuery', this.selectedCarrier);
    this.carrierSelector = this.selectedCarrier;
    if(this.selectedCarrier != null) {
      this.api.getReportsForCarrier(this.selectedCarrier, this.currentPage, this.pageSize)
      .subscribe((apiRes) => {
        this.crashReports = apiRes;
        //console.log(this.crashReports)
      },
      (apiErr) => {
        if(apiErr) {
          alert(JSON.stringify(apiErr));
        }
      });
    }
    else {

    }
  }

  /**
   * If the user is not and admin and their customer type is individual, ask them
   * to confirm their purchase of the report.
   * @param {string} url 
   */
  navigateToReport(crashId: string, investigating_agency: string) {

    const carrier: ICarrier = this.carriers.find(function(carrier: ICarrier)  {
      // THIS IS THE ONLY WAY TO MAKE TYPESCRIPT COMPILE W/ thisarg
      let unknownThis = <any> this;
      let carDashThis = <CarrierDashboardComponent> unknownThis;
      return carrier.legal_name === carDashThis.carrierMapping[carDashThis.carrierSelector];
    }, this);
    if (!this.currentUser.is_admin && carrier.customer_type === 'individual') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Confirm Purchase',
          message: 'By choosing this incident, you are aware you are purchasing this report.',
          confirmText: 'Yes',
          cancelText: 'Cancel'
        }
      });
      dialogRef.afterClosed().subscribe((purchase: boolean) => {
        if (purchase) {
        this.router.navigate(['/crashReport', crashId, 'fullReport', 'initialIncidentReport']);
        }
      })
    } else {
      this.router.navigate(['/crashReport', crashId, 'fullReport', 'initialIncidentReport']);
    }
  }

  pageChange(newPage) {
    let np = parseInt(newPage);
    if(isNaN(np) || np < 0) {
      np = 0;
    }

    this.currentPage = np;
    this.getCarrier();
  }
}
