import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BackstrapService } from '../backstrap.service';
import { IUser } from '../contracts/user/iuser';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
              private api: BackstrapService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Check the user's token to determine if they are logged in or not.
    return this.api.checkToken().pipe(
      map(() => {
        // Reset the unauthed url in case it was previously set.
        this.api.unauthedUrl = null;
        // Get the current user.
        const currentUser: IUser = this.api.getUser();
        // Do some checks on the user's roles to see if they can continue to the admin page.
        if (state.url === '/admin') {
          if(currentUser.is_admin !== true) {
            if(currentUser.is_carrier) {
              this.router.navigate(['/carrierDashboard']);
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
        }
        // Do some checks on the user's roles to see if they can continue to the carrier dashboard page.
        // if (state.url === '/carrierDashboard') {
        //   if(!currentUser.is_admin) {
        //     this.router.navigate(['/dashboard']);
        //   }
        // }
        
        // If they are logged in, return true and let them continue.
        return true;
      }),
      catchError((error) => {
        // If the user is not logged in and trying to access a protected route, save that url
        // for when they attempt to log in.
        this.api.unauthedUrl = state.url;
        // If they are not logged in, send them to the login screen and return false.
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

}
