// ==================================================================
// AuthComponent
//
// This component has the standard fields for sign in, sign up, and
// forgot password.  It uses the BackstrapService to communicate
// with the server.
//
// Sign up is hidden for the OSS project since the general public
// can't sign up.
// ==================================================================

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'

import { BackstrapService } from '../backstrap.service';
import { MessagingComponent } from '../messaging/messaging.component'
import { EventDispatchService } from '../event-dispatch.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
	@ViewChild(MessagingComponent) messaging:MessagingComponent;

	username: string;
	password: string;
	passwordConfirm: string;
	emailOrUsername: string;
	mode: string;
  imgSource = './assets/oss_logo_black.png';

  email: string;
  first_name: string;
  last_name: string;
  
  constructor(private route: ActivatedRoute, private api: BackstrapService, 
              private router: Router, private eventDispatch: EventDispatchService) { 
  	this.username = '';
  	this.password = '';
  	this.passwordConfirm = '';
  	this.emailOrUsername = '';

  	this.email = '';
  	this.first_name = '';
  	this.last_name = '';
  	this.mode = null;
  }

  ngOnInit() {
    this.eventDispatch.dispatch({type: "authComponent", payload: "open"}, null);

    // IF THIS ROUTE WAS FIRED OFF PROGRAMATICALLY W/ DATA
  	if(this.route.snapshot.data.mode != null && 
  	this.route.snapshot.data.mode !== '') {
  		this.mode = this.route.snapshot.data.mode;
  	}

    if(this.mode == null) {
      if(this.route.snapshot.params.mode != null && 
        this.route.snapshot.params.mode !== '') {
          let lcMode = this.route.snapshot.params.mode.toLowerCase();
          switch(lcMode) {
            case 'forgotpassword':
              this.mode = 'forgotPassword';
              break;
            case 'resetpassword':
              this.mode = 'resetPassword';
              break;
            default:
              this.mode = 'signin';
          }
      }
      // IF THERE IS NO DATA & NO PARAMS IN THE ROUTE, GO TO SIGNIN
      else {
        this.mode = 'signin';
      }
    }

    // this.mode = this.route.params._value['mode'] || 'signin';
    console.log(this.mode);
  }

  ngOnDestroy() {
    this.eventDispatch.dispatch({type: "authComponent", payload: "closed"}, null);
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

  signinClick() {
		this.api.signin(this.username, this.password)
		.subscribe((data) => {
			this.password = '';
			const unauthedUrl: string = this.api.unauthedUrl;
			if (unauthedUrl) {
				// Route the user to the url they were trying to access before being logged in.
				this.router.navigateByUrl(unauthedUrl);
			} else if(data['roles'].includes('insurance-carrier') && !data['roles'].includes('admin-user') &&
      !data['roles'].includes('super-user')) {
        this.router.navigate(['/carrierDashboard']);
      }
      else {
        this.router.navigate(['/dashboard']);
      }
		},
		(err) => {
			this.messaging.displayMessage(err.error.display_message, 'error');
		});
  }

  signupClick() {
  	if(this.password === this.passwordConfirm) {
	  	this.api.signup(this.username, this.email, this.first_name, this.last_name, this.password)
	  	.subscribe((data) => {
	  		this.messaging.displayMessage('Your account has been created', 'success');
	  	},
	  	(err) => {
				this.messaging.displayMessage(err.error.display_message, 'error');
	  	})
  	}
  	else {
  		this.messaging.displayMessage('Your passwords did not match', 'error');
  	}
  }

  forgotPasswordClick() {
  	this.api.forgotPassword(this.emailOrUsername)
  	.subscribe((data) => {
  		this.messaging.displayMessage('A password reset link has been emailed to you.', 'success');
  	},
  	(err) => {
  		this.messaging.displayMessage(err.error.display_message, 'error');
  	});
  }

  signoutClick() {
  	this.api.signout()
  	.subscribe((data) => {
  		this.messaging.displayMessage('Successfully signed out', 'success');
  	},
  	(err) => {
  		this.messaging.displayMessage(err.error.display_message, 'error');
  	});
  }

  resetPasswordClick() {
  	if(this.password === this.passwordConfirm) {
      let tkn = this.route.snapshot.queryParams.token;
      let pw = this.password;
      this.api.resetPassword(tkn, pw)
      .subscribe((data) => {
        this.password = '';
        this.passwordConfirm = '';
        this.messaging.displayMessage('Password Reset', 'success');
        //this.router.navigate(['/signin'])
        this.redirectTo('/signin');
      },
      (err) => {
        this.messaging.displayMessage(err.error.display_message, 'error');
      });
  		
      // this.route.params.subscribe(params => {
	  	// 	this.api.resetPassword(params.token, this.password)
		  // 	.subscribe((data) => {
      //     this.password = '';
      //     this.passwordConfirm = '';
		  // 		this.messaging.displayMessage('Password Reset', 'success');
      //     this.mode = 'signin';
      //     this.router.navigate(['/signin']);
		  // 	},
		  // 	(err) => {
		  // 		this.messaging.displayMessage(err.error.display_message, 'error');
		  // 	});
	  	// },
	  	// (err) => {
		  // 	this.messaging.displayMessage(err.error.display_message, 'error');
	  	// });
  	}
  	else {
  		this.messaging.displayMessage('Your passwords did not match', 'error');
  		this.password = '';
  		this.passwordConfirm = '';
  	}
  }

  routeToForgotPassword() {
    //this.router.navigate(['/forgotpassword']);
    this.redirectTo('/forgotpassword');
  }

  routeToSignin() {
    //this.router.navigate(['/signin']);
    this.redirectTo('/signin');
  }

  keydownEvent(event){
    if(event.keyCode == 13) {
      this.signinClick();
    }
  }
}
