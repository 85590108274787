// ==================================================================
// PrintItemComponent
//
// This represents a single form element in the DFormComponent.
// 
// It takes an "options" object as input and uses that to format
// itself properly.  Depending on the arguments in the options obj,
// it will render a label as well as an input element (text, number,
// date, textarea, dropdown, or button-group).  Values and labels
// for the options in dropdown or button-group must be included.
//
// The options object also must include a 'value' which binds to
// the input elements.  The value property is an array, and so for
// fields which only appear once in the form, they will bind to
// options.value[0].
//
// Sometimes the DFormComponent will include a repeating set
// of FormItemComponents.  In that case, the iteration of the repeating
// set is indicated with the repeatIndex input.
//
// The options object includes a fieldname which is used to identify a
// database field on the backend.  And via integration with the
// BackstrapService, on edit-end (+1.5 seconds), the form sends its value to the
// API which is responsible for saving that value to the correct field
// in the correct table of the db.  The green sync icon turns red, and
// then back to green once the api call is complete and successful.
//
//
// options for button-group form item 
// eg. from OSS
// {
//  "fieldName":"LIGHTING_CD",
//  "max_length":2,
//  "value":[],
//  "type":"button-group",
//  "key":"CRASH-0033",
//  "label":"Lighting Code",
//  "placeholder":"",
//  "items":[
//    {"label":"Daylight","value":"A"},
//    {"label":"Dark-No Street Lights","value":"B"},
//    {"label":"Dark-Continuous Street Light","value":"C"},
//    {"label":"Dark-Street Light At Intersection Only","value":"D"},
//    {"label":"Dusk","value":"E"},
//    {"label":"Dawn","value":"F"},
//    {"label":"Unknown","value":"Y"},
//    {"label":"Other","value":"Z"}
//    ]
// }
// ==================================================================


import { Component, Inject, OnInit, Input, ViewChild } from '@angular/core';

import { BackstrapService } from '../backstrap.service';
import { EventDispatchService } from '../event-dispatch.service';

import { SignaturePad } from 'angular2-signaturepad';
import {MatDialog} from '@angular/material';
import { ImageDialog } from '../form-item/form-item.component';
declare let L;

export interface DialogData {
  formElement: any;
}

@Component({
  selector: 'app-print-item',
  templateUrl: './print-item.component.html',
  styleUrls: ['./print-item.component.css']
})
export class PrintItemComponent implements OnInit {
	@Input() options: any;
  @Input() crashNum: string;
  @Input() groupRepeatIndex: number;
  @Input() repeatIndex: number;
  @Input() itemIndex: number;
  @Input() vehicle: any;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

	inputTimer: any;
  isSynced: boolean;
  sigPadOptions: any;
  eventSubscription: any;


  constructor(private api: BackstrapService, private eventDispatch: EventDispatchService, public dialog: MatDialog) { 
  	this.inputTimer = null;
    this.isSynced = true;
    this.sigPadOptions = {
      canvasWidth: 600,
      canvasHeight: 100,
      backgroundColor: '#EEEEEE'
    };
  }


  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  ngOnInit() {
    //console.log(`this.options.value: ${this.options.value}`)
    if(this.options.value == null) { 
      this.options.value = [];
    }
    else if (this.options.value == '') {
      this.options.value = [''];
    }
    else if(this.options.value[this.groupRepeatIndex] == null) {
      this.options.value[this.groupRepeatIndex] = [];
    }

    if(this.groupRepeatIndex == null) {
      this.groupRepeatIndex = 0;
    }
    else {
      this.groupRepeatIndex--;
    }

    if(this.repeatIndex == null) {
      this.repeatIndex = 0;
    }
    else {
      this.repeatIndex--;
    }

    if(this.options.type === 'map' && this.options.value[this.groupRepeatIndex] && this.options.value[this.groupRepeatIndex][this.repeatIndex] != undefined){
      this.options.lat = this.options.value[this.groupRepeatIndex][this.repeatIndex].split(',')[0];
      this.options.lng = this.options.value[this.groupRepeatIndex][this.repeatIndex].split(',')[1];
      this.options.value[this.groupRepeatIndex][this.repeatIndex] = 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/pin-s(' + this.options.lng + ',' + this.options.lat + ')/' + this.options.lng + ',' + this.options.lat + ',16,0,0/600x300?access_token=pk.eyJ1IjoiZ2dhdWNoZXQiLCJhIjoiY2pucWY5OGF3MDFtZTN0bGpvZDhwMzExbSJ9.m1MFQimz7bcxNru8iyDGYQ';
    }

    if(this.options.type === 'date'){
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      if(this.options.value[this.groupRepeatIndex][0] != null && this.options.value[this.groupRepeatIndex][0] !== '') {
        var formattedVal = null;
        var d = null;

        if(this.options.value[this.groupRepeatIndex][0].indexOf('-') !== -1) {
          var formattedVal = this.options.value[this.groupRepeatIndex][0];
          d = new Date(formattedVal);
          this.options.value[this.groupRepeatIndex][0] = days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
        }
        else if(this.options.value[this.groupRepeatIndex][0].indexOf(',') === -1) {
          formattedVal = this.options.value[this.groupRepeatIndex][0].substring(4)+'-'+this.options.value[this.groupRepeatIndex][0].substring(0, 2)+'-'+this.options.value[this.groupRepeatIndex][0].substring(2, 4);
          let tzOffset = new Date().getTimezoneOffset();
          let tzHours = Math.floor(tzOffset/60);
          let tzMins = tzOffset - (tzHours*60);
          let hrsString = tzHours < 10 ? '0'+tzHours.toString() : tzHours.toString();
          let minsString = tzMins < 10 ? '0'+tzMins.toString() : tzMins.toString(); 
          
          formattedVal += 'T'+hrsString+':'+minsString;
          
          d = new Date(formattedVal);
          this.options.value[this.groupRepeatIndex][0] = days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
        }  
      }
      else {
        this.options.value[this.groupRepeatIndex][0] = '';
      }
    }

    this.eventSubscription = this.eventDispatch.emitter.subscribe((data) => {
      switch(data.type) {
        case 'report_load': 
          console.log('caught report load event');
          if(this.options.type === 'signature') {
          }
          break;
        default:
      }
    },
    (err) => {
      console.log(err);
    });

    // if(this.options.type === 'map'){
    //   if(this.options.value && this.options.value[this.repeatIndex] != undefined && this.options.value[this.repeatIndex] != ''){
    //     setTimeout(() => {this.setupMap(this.options.value[this.repeatIndex])}, 50);
    //   }else{
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition((position) => {
    //         this.options.value[this.repeatIndex] = position.coords.latitude + ',' + position.coords.longitude;
    //         this.inputChange(this.options);
    //         this.setupMap(this.options.value[this.repeatIndex]);
    //       })
    //     }
    //   }
    // }

    // if(this.options.fieldName === 'AGENT_COORDINATES'){
    //   if(this.options.value && this.options.value[this.repeatIndex] != undefined && this.options.value[this.repeatIndex] != ''){
    //     //just here in case we need it in the future. right now, we're just
    //     //capturing on initial arrival, i.e., report creation
    //   }else{
    //     if(navigator.geolocation){
    //       navigator.geolocation.getCurrentPosition((position) => {
    //         this.options.value[this.repeatIndex] = position.coords.latitude + ',' + position.coords.longitude;
    //         this.inputChange(this.options);
    //       })
    //     }
    //   }
    // }

    if(this.options.type === 'image'){
      // this.blobToDataURL(this.options.value[this.repeatIndex], (dataurl) => {
      setTimeout(() => {
        document.getElementById('image_thumb_' + this.options.fieldName).setAttribute('src', this.options.value[this.repeatIndex]);
      }, 50);
      // })
    }
  }

  ngAfterViewInit() {
    if(this.options.type === 'signature') {
      // this.loadSignature(this.options.value[this.repeatIndex])
    }
  }

  ngOnDestroy() {
    if(this.eventSubscription != null) {
      this.eventSubscription.unsubscribe();
    }
  }

  // setupMap(position){
  //   var lat = position.split(',')[0];
  //   var lng = position.split(',')[1];
  //   const map = L.map('map').setView([lat, lng], 18);
  //   const that = this;
  //   L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //               attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  //           }).addTo(map);
  //     var marker = L.marker([lat, lng], {draggable: true})
  //       .on('dragend', function(event){
  //         var marker = event.target;
  //         var position = marker.getLatLng();
  //         that.options.value[that.repeatIndex] = position.lat + ',' + position.lng;
  //         that.inputChange(that.options);
  //         marker.setLatLng(new L.LatLng(position.lat, position.lng),{draggable:'true'});
  //         map.panTo(new L.LatLng(position.lat, position.lng))
  //       }).addTo(map)

  //   setTimeout(() => {window.print();}, 500);
  // }


  buttonStyle(b) {
  	if(b.value === this.options.value) {
  		return "{'background-color': 'red'}";
  	}
  	else {
  		return "'background-color': 'black'";
  	}
  }

  embiggenImage(field){
    const dialogRef = this.dialog.open(ImageDialog, {
      width: '600px',
      data: {formElement: field}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

}
