// ==================================================================
// ReportListComponent
//
// This is just an hmtl table hooked up to ngFor repeater and
// some basic styling.
//
// For OSS this is used in the tabbed-view of reports on the dashboard
// ==================================================================

import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { BackstrapService } from '../backstrap.service';
import { EventDispatchService } from '../event-dispatch.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {
  displayedColumns: string[] = ['nopd_id', 'status', 'date', 'last_update', 'agent'];
  statusVals: any[] = [
    {value: 'open', label: 'Open'},
    {value: 'in_progress', label: 'In Progress'},
    {value: 'send_to_carrier', label: 'Send To Carrier'},
    {value: 'agent_qc_check', label: 'Agent QC Check'},
    {value: 'secondary_qc_check', label: 'Secondary QC Check'},
    {value: 'ready', label: 'Ready for Submission'},
    {value: 'submitted', label: 'Submitted'},
    {value: 'incomplete', label: 'Incomplete'}
  ];

  dataSet: any[] = [];

  @Input() statuses: any;
  @Input() serviceLocation: any;
  @Input() pageSize: number;
  @Input() currentPage: number;
  @Input() reloadTable: number;
  @Input() id:string;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router,
              private _snackBar: MatSnackBar,
              private api: BackstrapService,
              private eventDispatch: EventDispatchService) { }

  ngOnInit() {
    this.reload();
  }

  ngAfterContentInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !!changes.serviceLocation && 
      changes.serviceLocation.previousValue !== changes.serviceLocation.currentValue
    ) {
      this.reload();
    }
    else if(!!changes.currentPage && changes.currentPage.previousValue !== changes.currentPage.currentValue) {
      this.reload();
    }
    else if(!!changes.pageSize && changes.pageSize.previousValue !== changes.pageSize.currentValue) {
      // CHANGING THE currentPage SHOULD TRIGGER A RELOAD
      this.currentPage = 0;
    }
    else if (
      !!changes.reloadTable && 
      changes.reloadTable.previousValue !== changes.reloadTable.currentValue
    ) {
      this.reload();
    }
  }

  reportSelected(repDesc) {
  	var url = '/crashReport/'+repDesc.oss_id;
  	this.router.navigate([url]);
  }

  statusChange(row) {
    this.api.setReportStatus(row.oss_id, row.status)
    .subscribe((data) => {
      if (row.status === 'submitted') {

        this._snackBar.open('Report Submitted.', '', {
          duration: 7000,
          panelClass: ['default-snackbar']
        });
      }
      if(!this.statuses.includes(row.status)) {
        this.dataSet = this.dataSet.filter((r) => {
          return (r.oss_id !== row.oss_id)
        });
      }
    },
    (err) => {
      console.log(err);
    });
  }

  reload() {
    let that = this;
    if (!!this.serviceLocation) {
      this.api.reportsByStatus({
        statuses: this.statuses,
        serviceLocationId: this.serviceLocation.id,
        limit: this.pageSize,
        offset: this.currentPage * this.pageSize
      })
      // this.api.reportsByStatus(this.statuses)
      .subscribe((reps:any) => {
        that.dataSet = reps;
        for(var rIdx = 0; rIdx < that.dataSet.length; rIdx++) {
          if(that.dataSet[rIdx].date != null) {
            var createDate = new Date(that.dataSet[rIdx].date);
            var m = (createDate.getMonth() + 1) + '';
            m = parseInt(m) < 10? '0' + m: m;
            var day = createDate.getDate() + '';
            day = parseInt(day) < 10? '0' + day: day;
            that.dataSet[rIdx].date = m + '/' + day + '/' + createDate.getFullYear();
          }
          else {
            that.dataSet[rIdx].date = '';
          }

          if(that.dataSet[rIdx].last_update != null) {
            var lastUpdate = new Date(that.dataSet[rIdx].last_update);
            that.dataSet[rIdx].last_update = lastUpdate.toLocaleString();
          }
          else {
            that.dataSet[rIdx].last_update = '';
          }
        }
      },
      (err) => {
        console.log(err);
      })
    }
  }
}
