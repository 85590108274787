import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../shared/content.service';
import { BackstrapService } from '../../backstrap.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUrlInfo } from '../../contracts/content/iurl-info';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<any> = new Subject<any>();
  currentUrl: string = '';
  report: object = {};
  images: object = {};
  showModal: string = null;
  emptyGalleryMessage: string = '';


  constructor(private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private api: BackstrapService) { }

  ngOnInit() {
    let that = this;
    // Scroll to the top of the window when the page loads.
    const top: Element = document.getElementById('topOfInitialReportContent');
    if (top) {
      top.scrollIntoView({
        behavior: 'smooth'
      });
    }

    // Subscribe to navigation events.
    this.contentService.urlInfo$.pipe(takeUntil(this.unsubscribe))
    .subscribe((urlInfo: IUrlInfo) => {
      this.currentUrl = urlInfo.currentUrl;
    });

    const crashId = this.route.snapshot.parent.paramMap.get('crashId');
    const reportId = this.route.snapshot.paramMap.get('reportId');

    if (crashId && reportId) {
      this.api.getReport(crashId).pipe(takeUntil(this.unsubscribe))
        .subscribe((response) => {
          that.report = response;
          if(that.report['invest_agency_name'] !== 'NOPD/OSS') {
            this.emptyGalleryMessage = 'This report was written by the New Orleans Police Department. NOPD does not take photos or written statements as part of their investigation. Please email <a href="mailto:info@oss.city">info@oss.city</a> if you have any questions.'
          }
          else {
            this.emptyGalleryMessage = 'OSS Agents are actively working this CrashFolio. Please refresh this page at regular intervals to see the most up-to-date information. If data is still not present after 1-2 hours, please contact <a href="mailto:support@onsceneservices.com">support@onsceneservices.com</a>  for assistance. Thank you.';
          }
          let itemNum = this.report['report_num_2'];
          that.api.getImagesForReport(itemNum).pipe(takeUntil(this.unsubscribe))
          .subscribe(imgs => {
            that.images = {};
            let re = /^d(\d*)/;
            Object.keys(imgs).forEach(key => {
              let matchRes = key.match(re);
              if(matchRes != null) {
                that.images[`Driver ${matchRes[1]}`] = imgs[key];
              }
              else {
                that.images[`${key.substring(0, 1).toUpperCase()}${key.substring(1).toLowerCase()}`] = imgs[key];
              }
            });
          });
      });
    } else {
      this.router.navigate(['/carrierDashboard']);
    };

  }

  getKeys(obj) {
    return Object.keys(obj).sort();
  }

  openFullImage(url) {
    this.showModal = url;
  }

  closeFullImage() {
    this.showModal = null;
  }

  ngOnDestroy()  {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
