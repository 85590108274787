import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { BackstrapService } from '../backstrap.service';

@Injectable({
  providedIn: 'root'
})
export class BoxWindowResolverService {

  constructor(private router: Router,
              private api: BackstrapService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<boolean> {
    const crashId: string = route.parent.paramMap.get('crashId');
    const reportId: string = route.paramMap.get('reportId');
    // As long as the report id exists on the route, get the docs, photos, and report. Otherwise navigate to the carrier dashboard.
    return (crashId && reportId) ? forkJoin(this.api.boxDocsForReport(reportId), this.api.getReport(crashId)) : this.router.navigate(['/carrierDashboard']);
  }
}
