// ==================================================================
// TabbedViewComponent
//
// This takes an arbitrary number of tab names (string array) and
// data sources (array of arrays) and uses that to make a tabbed
// view of ReportTableComponents.
// ==================================================================

import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { ReportListComponent } from '../report-list/report-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-tabbed-view',
  templateUrl: './tabbed-view.component.html',
  styleUrls: ['./tabbed-view.component.css']
})
export class TabbedViewComponent implements OnInit {
	@Input() tabs: string[];
  @Input() serviceLocation: any;
  @Input() pageSize: number;
  @Input() reloadTable: number;
  @Input() statuses: any[];

  @ViewChildren('tabComponent') tabComponents:QueryList<ReportListComponent>
  tab = new FormControl(0);
  currentPage = 0;
  
  constructor(private router: Router, private route: ActivatedRoute) { 
    if(this.pageSize < 10) this.pageSize = 10;
    if(this.pageSize > 100) this.pageSize = 100;
  }

  ngOnInit() {
    const _tab = this.route.snapshot.queryParamMap.get('tab');
    if(!!_tab) {
      this.tab.setValue(_tab);
    }
  }

  tabChange(tab) {
    console.log(tab)
    this.tab.setValue(tab);
    this.router.navigate(['.'], { 
      relativeTo: this.route, 
      queryParams: { 
        tab: tab
      },
      queryParamsHandling: 'merge'
    });
  }

  pageChange(newPage) {
    let np = parseInt(newPage);
    if(isNaN(np) || np < 0) {
      np = 0;
    }

    this.currentPage = np;
  }
}
