// ======================================================================
// DformComponent
//
// This is a form generating component.  It uses a json "schema" of the
// form to generate the correct FormItem components for display.
// 
// The json schema is an array of Groups with Subgroups therein.  Each
// group contains at least one subgroup and no form items are found outside
// of a subgroup.  Both groups and subgroups have a "title" property which
// defines the text for headers of those sections.  Subgroups also have
// the ability to repeat based off the data it will display.  The 'key'
// property matches an array property of the report data and is used
// to determine how many times the subgroup should repeat.  There is also
// a 'repeats' boolean which determines whether this subgroup repeats.
// If repeats = true, +/- buttons will appear at the bottom of the subgroup.
//
// Finally, each subgroup has an array called 'formItems' which holds the
// options objects needed to input to each FormItem component.
//
// For OSS, the schema is pulled down from the API/db as it could be
// different for each crash report.
//
// To handle multiple users editing a report at once, there is a 5s
// refresh timer which polls the API to see if any report data has
// been changed by another user since the last time you updated.  If so,
// it sends the data with the response and DFormComponent reloads it.
// ======================================================================


import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BackstrapService } from '../backstrap.service';
import { EventDispatchService } from '../event-dispatch.service';
import { HttpClient } from '@angular/common/http';

declare var require:any;

@Component({
  selector: 'app-dform',
  templateUrl: './dform.component.html',
  styleUrls: ['./dform.component.css']
})
export class DformComponent implements OnInit {
  crashId: string;
  reportStatus: string = '';
  statusVals: any[] = [
    {value: 'open', label: 'Open'},
    {value: 'in_progress', label: 'In Progress'},
    {value: 'send_to_carrier', label: 'Send To Carrier'},
    {value: 'agent_qc_check', label: 'Agent QC Check'},
    {value: 'secondary_qc_check', label: 'Secondary QC Check'},
    {value: 'ready', label: 'Ready for Submission'},
    {value: 'submitted', label: 'Submitted'},
    {value: 'incomplete', label: 'Incomplete'}
  ];
  formGroups: any[];
  reportData: any;
  refreshTimer: any;
  lastUpdate: Date;
  isLoading: boolean;
  showAllFields: boolean;
  buttonsValue: string;
  eventSubscription: any;
  showMenu: boolean;
  navItems: any[];
  navLinks: any[];
  lastActive: any;
  activeInput: any;
  ignoreInputs: any[];
  rightSidenavOpened: boolean = true;

  constructor(private route: ActivatedRoute,
              private api: BackstrapService, 
              @Inject(DOCUMENT) document,
              private eventDispatch: EventDispatchService,
              private http: HttpClient) { 
    this.formGroups = [];
    this.crashId = null;
    this.refreshTimer = null;
    this.isLoading = true;
    this.showAllFields = false;
    this.buttonsValue = '';
    this.showMenu = false;
    this.navItems = [];
    this.navLinks = [];
    this.lastActive = '';
    this.activeInput = null;
    this.ignoreInputs = [];
  }

  // KEEP THE NAVIGATION OPTIONS ON THE SCREEN
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.getElementById('dform-nav');
    var fromTop = 200;
    var cur;

    // see what section is closest to the top of the page
    for(var i = 0; i < this.navItems.length; i++){
      if (this.navItems[i] && this.navItems[i].getBoundingClientRect().top < fromTop && this.navItems[i].getBoundingClientRect().top > 0){
        cur = this.navItems[i];
      }      
    }

    //find the nav link for the top-most section
    if(cur != null && cur.getAttribute('id') != this.lastActive){
      this.lastActive = cur.getAttribute('id');
      var lastActive = document.querySelector('#dform-nav a.active');
      if(lastActive) lastActive.classList.remove('active');
      document.querySelector('#dform-nav a[href*="' + cur.getAttribute('id') + '"]').classList.add('active');
    }
  }


  ngOnInit() {
    // DISPATCH EVENT THAT THE REPORT COMPONENT IS OPENING SO THAT THE
    // TITLE BAR KNOWS TO DISPLAY THE "back to dashboard" BUTTON.
    this.eventDispatch.dispatch({type: 'reportComponent', payload: {isOpen: true}}, null);

    // THE URL CONTAINS THE CRASH REPORT IDENTIFIER, LOAD UP THAT REPORT
    this.crashId = this.route.snapshot.paramMap.get('crashId');
    if(this.crashId !== null && typeof(this.crashId) !== 'undefined') {

      // GET THE REPORT FROM THE DB
      this.api.getReport(this.crashId)
      .subscribe((data) => {
        if(data !== undefined && data !== null) {
          this.reportData = data;
          this.reportStatus = data['report_status'];
          // LET app.component KNOW THE NOPD ITEM NUM & OSS ID TO DISPLAY IN HEADER
          this.eventDispatch.dispatch({type: 'reportIds', payload: {report_num_2: this.reportData.report_num_2, report_num: this.reportData.report_num}}, null)

          // IF NO CRASH DATE WAS PRESENT IN THE DB, ASSUME THIS IS THE FIRST
          // TIME THE REPORT WAS OPENED.  STAMP IT WITH TODAY'S DATE
          if(this.reportData.crash_date == null || this.reportData.crash_date === '') {
            this.reportData.crash_date = new Date().toISOString();
            var fieldDescriptor = {
              fieldName: 'CRASH_DATE',
              tableName: 'crash',
              key: 'CRASH-0007',
              value: [[this.reportData.crash_date]]
            };
            this.api.updateFormField(this.crashId, fieldDescriptor, 0, 0).subscribe();
          }

          // SET THE LAST UPDATE TO NOW -- USED TO CHECK FOR DIRTY DATA LATER
          this.lastUpdate = new Date();

          var sv = null;
          if(this.reportData.rev_num !== undefined && this.reportData.rev_num !== null) {
            sv = this.reportData.rev_num;
          }

          // GET THE SCHEMA VERSION FROM THE DB THAT MATCHES THIS REPORT
          this.api.getSchema(sv, null)
          .subscribe((data:any) => {
            this.formGroups = data.schema;

            // START THE TIMER TO CHECK FOR NEW DATA INPUT BY ANOTHER USER.
            // this.refreshTimer = setInterval(() => {
            //   this.api.checkForData(this.crashId, this.lastUpdate.toISOString())
            //   .subscribe((newDataRes) => {
            //     if(newDataRes['is_dirty'] === true) {
            //       this.reportData = newDataRes['data'];
            //       this.lastUpdate = new Date();
            //       this.populateForm();
            //     }
            //   })
            // }, 5000);

            // RUN THROUGH THE REPORT DATA AND FILL IN THE APPROPRIATE
            // FORM FIELDS
            this.populateForm();
          },
          (err) => {
            console.log(err);
          });
        }
        else {
          console.log('no report found with that id');
        }
      },
      (err) => {
        console.log(err);
      });
    }
    // IF NO REPORT IS SPECIFIED, JUST SHOW THE LATEST
    // SCHEMA WITH NO REPORT DATA
    else {
      this.api.getSchema(null, null)
      .subscribe((data:any) => {
        this.formGroups = data.schema;
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
      });
    }

    this.eventSubscription = this.eventDispatch.emitter.subscribe((data) => {
      switch(data.type){
        case 'set_status_from_child':
          this.buttonsValue = data.payload;
          break;
        case 'add_section':
          var num_visible = document.querySelectorAll('.' + data.payload.section).length;
          if(num_visible < data.payload.total) {
            this.addSectionToReport(data.payload.section, null);
          }
          break;
        case 'remove_section':
          var num_visible = document.querySelectorAll('.' + data.payload.section).length;
          if(num_visible > data.payload.total) {
            this.removeSectionFromReport(data.payload.section, null);
          }
          break;
        case 'start_update':
          this.lastUpdate = new Date();
          break;
        // case 'toggle-hamburger':
        //   this.showMenu = !this.showMenu;
        //   break;
        case 'set_dob':
          var age = moment().diff(data.payload.date, 'years');
          var fgs = this.formGroups.filter((f) => {
            return (f.key === 'vehic');
          });

          var sgs = fgs[0].subGroups.filter((sg) => {
            return (sg.title.toLowerCase() === 'driver');
          });

          var fis = sgs[0].formItems.filter((fi) => {
            return (fi.key === 'VEHIC-0010');
          });

          fis[0].value[data.payload.groupIndex][data.payload.subGroupIndex] = age;
          console.log('fis', fis)
          this.api.updateFormField(this.crashId, fis[0], data.payload.groupIndex, data.payload.subGroupIndex).subscribe();
          break;
        case 'clear_active_field':
          this.activeInput = null; 
          break;
        case 'set_active_field':
          this.activeInput = data.payload;
          break;
        case 'clear_sync_ignore':
          for(var iIdx = 0; iIdx < this.ignoreInputs.length; iIdx++) {
            var ignoreObj = this.ignoreInputs[iIdx];
            if(ignoreObj.field === data.payload.field && ignoreObj.groupRepeatIndex === data.payload.groupRepeatIndex && ignoreObj.repeatIndex === data.payload.repeatIndex) {
              this.ignoreInputs.splice(iIdx, 1);
              break;
            }
          }
          break;
        case 'set_sync_ignore':
          var foundIgnoreObj = false;
          for(var iIdx = 0; iIdx < this.ignoreInputs.length; iIdx++) {
            var ignoreObj = this.ignoreInputs[iIdx];
            if(ignoreObj.field === data.payload.field && ignoreObj.groupRepeatIndex === data.payload.groupRepeatIndex && ignoreObj.repeatIndex === data.payload.repeatIndex) {
              foundIgnoreObj = true;
              break;
            }
          }
          if(!foundIgnoreObj) {
            this.ignoreInputs.push({field:data.payload.field, groupRepeatIndex:data.payload.groupRepeatIndex, repeatIndex:data.payload.repeatIndex});
          }
          break;
      }
    });
  }

  ngOnDestroy() {
    // STOP THE REFRESH TIMER
    //clearInterval(this.refreshTimer);

    // ALERT THE OTHER COMPONENTS THAT THE REPORT VIEW IS CLOSING
    this.eventDispatch.dispatch({type: 'reportComponent', payload: {isOpen: false}}, null);
  }
  
  trackByHandler(index: any, item: any) {
    return item;
  }

  slideChange(){
    // for(var fgIdx = 0; fgIdx < this.formGroups.length; fgIdx++) {
    //   this.formGroups[fgIdx];
    // }
    // this.parentSubject.next({type: 'show_fields', payload: !this.showAllFields});
    this.eventDispatch.dispatch({type: 'show_fields', payload: !this.showAllFields}, null);
  }

  setStatus(status){
    this.buttonsValue = status;
    this.eventDispatch.dispatch({type: 'set_status', payload: status}, null);
  }

  showKeys(obj) {
    //return Object.keys(obj).join(',');
    return (obj['veh_num']);
  }


  // THIS FUNCTION RUNS THROUGH THE REPORT DATA AND FILLS IN THE APPROPRIATE
  // FORM FIELDS.  IT ALSO CAUSES THE REPEATING SUBSECTIONS TO REPEAT BASED
  // ON THE NUMBER OF ITEMS IN THE ARRAY MATCHING THE 'key' PROPERTY IN THE
  // FormItemComponent OPTIONS OBJECT.
  populateForm() {
    try {
      var reportKeys = Object.keys(this.reportData);
      // FOR EACH GROUP IN THE FORM SCHEMA
      for(var gIdx = 0; gIdx < this.formGroups.length; gIdx++) {
        var formGroup = this.formGroups[gIdx];
        // GET ALL THE SUB GROUPS OF THAT GROUP
        for(var sgIdx = 0; sgIdx < formGroup.subGroups.length; sgIdx++) {
          var subGroup = formGroup.subGroups[sgIdx];
          // GET ALL FORM ITEMS FOR EACH SUBGROUP
          for(var fIdx = 0; fIdx < subGroup.formItems.length; fIdx++) {
            var formItem = subGroup.formItems[fIdx];

            // GET THE FIELD NAME FOR THIS FORM ITEM
            // IT WILL BE THE KEY IN THE REPORT DATA
            var thisKey = formItem.fieldName.toLowerCase();

            // IF WE DON'T HAVE THE TABLE NAME SPECIFIED,
            // DERIVE IT FROM THE FORM ITEM KEY
            formItem.tableName = formItem.table;
            if(formItem.table == null || formItem.table === ''){
              formItem.tableName = formItem.key.split('-')[0];
            }
            var thisTable = formItem.tableName.toLowerCase();


            // FOR EACH KEY IN THE REPORT DATA
            for(var rIdx = 0; rIdx < reportKeys.length; rIdx++) {
              var reportKey = reportKeys[rIdx];

              // IF THIS REPORT DATA IS AN OBJECT, THERE ARE MULTIPLE
              // FORM ITEMS FOR THIS TABLE.  RUN THROUGH EACH REPORT KEY
              // TO FIND THE CORRECT TABLE, THEN RUN THROUGH THE INNER KEYS
              // TO FIND THE KEY IN QUESTION
              if(this.reportData[reportKey] != null && 
                  typeof(this.reportData[reportKey]) === 'object') {
                // FOUND THE TABLE
                if(reportKey.toLowerCase() === thisTable) {
                  for(var irIdx = 0; irIdx < this.reportData[reportKey].length; irIdx++) {
                    var tableKeys = Object.keys(this.reportData[reportKey][irIdx]);

                    for(var ikIdx = 0; ikIdx < tableKeys.length; ikIdx++) {
                      var tableKey = tableKeys[ikIdx];
                      // FOUND THE SPECIFIC KEY FOR THIS FORM ITEM
                      if(tableKey.toLowerCase() === thisKey) {
                        
                        // FIGURE OUT WHICH GROUP ITERATION AND SUBGROUP ITERATION THIS APPLIES TO
                        var groupIndex = 0;
                        var subGroupIndex = 0;
                        if(formGroup.repeats === true && thisTable === formGroup.key) {
                          groupIndex = this.reportData[reportKey][irIdx][subGroup.parentGroupKey] - 1;
                        }
                        else if(formGroup.repeats === true) {
                          groupIndex = this.reportData[reportKey][irIdx][subGroup.parentGroupKey] - 1;
                          subGroupIndex = this.reportData[reportKey][irIdx][subGroup.groupKey] - 1;
                        }
                        else if(subGroup.repeats === true) {
                          subGroupIndex = this.reportData[reportKey][irIdx][subGroup.groupKey] - 1;
                        }

                        if(formItem.value == null) formItem.value = [];
                        for(var gi = 0; gi <= groupIndex; gi++) {
                          if(formItem.value[gi] === undefined || formItem.value[gi] === null) {
                            formItem.value[gi] = [['']];
                          }
                          for(var ri = 0; ri <= subGroupIndex; ri++) {
                            if(formItem.value[gi][ri] === undefined || formItem.value[gi][ri] === null) {
                              formItem.value[gi][ri] = '';
                            }
                          }
                        }
                        
                        // IF THIS IS THE ACTIVE INPUT OR IT HAS EXPERIENCED AN API FAILURE, DON'T SYNC
                        var hasError = false;
                        for(var eiIdx = 0; eiIdx < this.ignoreInputs.length; eiIdx++) {
                          var errorObj = this.ignoreInputs[eiIdx];
                          if(errorObj.field === formItem.key && errorObj.groupRepeatIndex === groupIndex && errorObj.repeatIndex === subGroupIndex) {
                            hasError = true;
                            break;
                          }
                        }
                        if(hasError === false && (this.activeInput == null || formItem.key !== this.activeInput.field || groupIndex !== this.activeInput.groupRepeatIndex || subGroupIndex !== this.activeInput.repeatIndex)) {
                          // THE BACK END FOR YES/NO FORM ITEMS STORES t/f IN THE DB.
                          // CHECK FOR YES/NO FORM ITEMS AND CONVERT true/false -> Y/N
                          if(formItem.items !== undefined && formItem.items !== null && formItem.items.length === 2 && 
                            (formItem.items[0].value.toLowerCase() === 'y' || formItem.items[0].value.toLowerCase() === 'n') &&
                            (formItem.items[1].value.toLowerCase() === 'y' || formItem.items[1].value.toLowerCase() === 'n')) {
                            if(this.reportData[reportKey][irIdx][tableKey] === true) {
                              formItem.value[groupIndex][subGroupIndex] = 'Y';
                            }
                            else if(this.reportData[reportKey][irIdx][tableKey] === false) {
                              formItem.value[groupIndex][subGroupIndex] = 'N';
                            }
                          }
                          else if(formItem.type === 'date') {
                            if(this.reportData[reportKey][irIdx][tableKey] != null && this.reportData[reportKey][irIdx][tableKey] !== '') {
                              var d = new Date(this.reportData[reportKey][irIdx][tableKey]);
                              var monthNum = d.getMonth()+1;
                              var monthString = monthNum < 10 ? '0'+monthNum.toString() : monthNum.toString();
                              var dayString = d.getDate() < 10 ? '0'+d.getDate().toString() : d.getDate().toString();
                              var yearString = d.getFullYear().toString();
                              var dateVal = monthString+dayString+yearString;

                              formItem.value[groupIndex][subGroupIndex] = dateVal;
                            }
                            else {
                              formItem.value[groupIndex][subGroupIndex] = '';
                            }
                          }
                          else if(formItem.type === 'time') {
                            if(this.reportData[reportKey][irIdx][tableKey] != null && this.reportData[reportKey][irIdx][tableKey] !== '') {
                              formItem.value[groupIndex][subGroupIndex] = this.reportData[reportKey][irIdx][tableKey].replace(new RegExp('\\:', 'g'), '');
                            }
                            else {
                              formItem.value[groupIndex][subGroupIndex] = '';
                            }
                          }
                          else if(formItem.type === 'phone') {
                            if(this.reportData[reportKey][irIdx][tableKey] != null && this.reportData[reportKey][irIdx][tableKey] !== '') {
                              formItem.value[groupIndex][subGroupIndex] = this.reportData[reportKey][irIdx][tableKey].replace(new RegExp('\\-', 'g'), '');
                            }
                            else {
                              formItem.value[groupIndex][subGroupIndex] = '';
                            }
                          }
                          // OTHERWISE THIS IS A NORMAL FIELD WHOSE VALUE IS STORED
                          // ON THE BACK END THE SAME AS ON THE FRONT
                          else {
                            formItem.value[groupIndex][subGroupIndex] = this.reportData[reportKey][irIdx][tableKey];
                          }
                        }
                        else if(hasError === false && (this.activeInput == null || formItem.key !== this.activeInput.field || groupIndex !== this.activeInput.groupRepeatIndex || subGroupIndex !== this.activeInput.repeatIndex) && this.reportData[reportKey][irIdx][tableKey] == null) {
                          formItem.value[groupIndex][subGroupIndex] = '';
                        }

                        break;
                      }
                    }
                  }
                }
              }
              else {
                if(reportKeys[rIdx].toLowerCase() === thisKey) {
                  if(formItem.value == null) formItem.value = [];
                  if(formItem.value[0] == null || formItem.value[0].length === 0) {
                    formItem.value[0] = [''];
                  }

                  // IF THIS IS THE ACTIVE INPUT OR IT HAS EXPERIENCED AN API FAILURE, DON'T SYNC
                  var hasError = false;
                  for(var eiIdx = 0; eiIdx < this.ignoreInputs.length; eiIdx++) {
                    var errorObj = this.ignoreInputs[eiIdx];
                    if(errorObj.field === formItem.key && errorObj.groupRepeatIndex === 0 && errorObj.repeatIndex === 0) {
                      hasError = true;
                      break;
                    }
                  }
                  if(hasError === false && (this.activeInput == null || formItem.key !== this.activeInput.field || 0 !== this.activeInput.groupRepeatIndex || 0 !== this.activeInput.repeatIndex)) {
                    // THE BACK END FOR YES/NO FORM ITEMS STORES t/f IN THE DB.
                    // CHECK FOR YES/NO FORM ITEMS AND CONVERT true/false -> Y/N
                    if(formItem.items !== undefined && formItem.items !== null && formItem.items.length === 2 && 
                      (formItem.items[0].value.toLowerCase() === 'y' || formItem.items[0].value.toLowerCase() === 'n') &&
                      (formItem.items[1].value.toLowerCase() === 'y' || formItem.items[1].value.toLowerCase() === 'n')) {
                      if(this.reportData[reportKey] === true) {
                        formItem.value[0] = ['Y'];
                      }
                      else {
                        formItem.value[0] = ['N'];
                      }
                    }
                    else if(formItem.type === 'date') {
                      if(this.reportData[reportKey] != null && this.reportData[reportKey] !== '') {
                        var d = new Date(this.reportData[reportKey]);
                        var monthNum = d.getMonth()+1;
                        var monthString = monthNum < 10 ? '0'+monthNum.toString() : monthNum.toString();
                        var dayString = d.getDate() < 10 ? '0'+d.getDate().toString() : d.getDate().toString();
                        var yearString = d.getFullYear().toString();
                        var dateVal = monthString+dayString+yearString;

                        formItem.value[0] = [dateVal];
                      }
                      else {
                        formItem.value[0] = [''];
                      }
                    }
                    else if(formItem.type === 'time') {
                      if(this.reportData[reportKey] != null && this.reportData[reportKey] !== '') {
                        formItem.value[0][0] = this.reportData[reportKey].replace(new RegExp('\\:', 'g'), '');
                      }
                      else {
                        formItem.value[0] = [''];
                      }
                    }
                    else if(formItem.type === 'phone') {
                      if(this.reportData[reportKey] != null && this.reportData[reportKey] !== '') {
                        formItem.value[0][0] = this.reportData[reportKey].replace(new RegExp('\\-', 'g'), '');
                      }
                      else {
                        formItem.value[0] = [''];
                      }
                    }
                    // OTHERWISE THIS IS A NORMAL FIELD WHOSE VALUE IS STORED
                    // ON THE BACK END THE SAME AS ON THE FRONT
                    else {
                      formItem.value[0] = [this.reportData[reportKey]];
                    }
                  }
                  else if(hasError === false && (this.activeInput == null || formItem.key !== this.activeInput.field || groupIndex !== this.activeInput.groupRepeatIndex || subGroupIndex !== this.activeInput.repeatIndex) && this.reportData[reportKey] == null) {
                    formItem.value[0][0] = '';
                  }

                  break;
                }
              }
            }
          }
        }
      }
      
      this.isLoading = false;
      this.eventDispatch.dispatch({type: 'report_load'}, null);

      setTimeout(() => {
        //find the navbar stuff
        var navbar = document.getElementById('dform-nav');
        var items = navbar.querySelectorAll('a');
        
        for(var i = 0; i < items.length; i++){
          var href = items[i].getAttribute('href');
          if(href && href !== null){
            var anchor = href.split('#')[1];
            this.navItems.push(document.getElementById(anchor));
            this.navLinks.push(items[i]);
          }
        }
      }, 2000);
    }
    catch(e) {
      console.error('ERROR POPULATING FORM');
      console.error(e);
    }
  }

  // GO TO SECTION BY CLICKING NAV BUTTONS
  navButtonClick(fg, gIdx, grgIdx) {
    window.location.hash = '';
    window.location.hash = fg.domID? fg.domID+'-'+gIdx+'-'+grgIdx: 'fg-' + gIdx + '-' + grgIdx;
    var elId = fg.domID? fg.domID+'-'+gIdx+'-'+grgIdx: 'fg-' + gIdx + '-' + grgIdx;
    var el = document.getElementById(elId.toString());
    el.scrollIntoView();
  }


  subnavButtonClick(sg, grgIdx, repIdx) {
    var el = document.getElementById('sg' + sg.domID + grgIdx+(repIdx + 1).toString());
    el.scrollIntoView();
  }

  // ADD AN ITERATION OF A REPEATING SUBGROUP
  addSectionToReport(tbKey, groupIndex) {
    var listType = null;
    switch(tbKey) {
      case 'altgrid':
        listType = 'grid';
        break;
      case 'narrsup':
        listType = 'narrsup';
        break;
      case 'vehic':
        listType = 'vehicle';
        break;
      case 'trailer':
        listType = 'trailer';
        break;
      case 'occup':
        listType = 'occupant';
        break;
      case 'pedes':
        listType = 'pedestrian';
        break;
      case 'train':
        listType = 'train';
        break;
      case 'trocc':
        listType = 'train_occupant';
        break;
      case 'drwitness':
        listType = 'driver_statement';
        break;
      case 'witness':
        listType = 'witness_statement';
        break;
      default:
    }
    this.api.addToList(this.crashId, listType, groupIndex)
    .subscribe((data) => {
      this.reportData = data;
      this.populateForm();
    },
    (err) => {
      console.log(err);
    });
  }


  // REMOVE AN ITERATION OF A REPEATING SUBGROUP
  removeSectionFromReport(tbKey, groupIndex) {
    var listType = null;
    switch(tbKey) {
      case 'altgrid':
        listType = 'grid';
        break;
      case 'narrsup':
        listType = 'narrsup';
        break;
      case 'vehic':
        listType = 'vehicle';
        break;
      case 'trailer':
        listType = 'trailer';
        break;
      case 'occup':
        listType = 'occupant';
        break;
      case 'pedes':
        listType = 'pedestrian';
        break;
      case 'train':
        listType = 'train';
        break;
      case 'trocc':
        listType = 'train_occupant';
        break;
      case 'drwitness':
        listType = 'driver_statement';
        break;
      case 'witness':
        listType = 'witness_statement';
        break;
      default:
    }
    this.api.removeFromList(this.crashId, listType, groupIndex)
    .subscribe((data) => {
      this.reportData = data;
      this.populateForm();
    },
    (err) => {
      console.log(err);
    });
  }

  isHiddenSection(fg) {
    var show = true;
    outerLoop:
    for(var sgIdx = 0; sgIdx < fg.subGroups.length; sgIdx++) {
      var sg = fg.subGroups[sgIdx];
      for(var fiIdx = 0; fiIdx < sg.formItems.length; fiIdx++) {
        var fi = sg.formItems[fiIdx];
        if(fi.hide === true && fi.systemField === false) {
          show = false;
          break outerLoop;
        }
      }
    }
    return show;
  }

  // TAKE AN INFORMAL TIME STRING AND PRODUCE A FORMATTED ISO STYLE TIME STRING
  formatTime(timeString) {
    if(timeString == null) {
      return null;
    }
    let lastTwoChars = timeString.trim().substr(-2).toLowerCase();
    var hrsNum = Number(timeString.substring(0, timeString.indexOf(':')));
    var remainingTimeString = timeString.substring(timeString.indexOf(':')+1).trim();

    // IF AM IS SPECIFIED
    if(lastTwoChars === 'am') {
      if(hrsNum >= 12) {
        hrsNum -= 12;
      }
      if(hrsNum > 11) return null; // PARSE ERROR

      // STRIP THE AM LABEL
      remainingTimeString = remainingTimeString.substring(0, remainingTimeString.length-2).trim();
    }
    // IF PM IS SPECIFIED
    else if(lastTwoChars === 'pm') {
      // IF THE USER SPECIFIED 0 FOR HOURS, JUST STRIP THE PM LABEL
      if(hrsNum !== 0) {
        if(hrsNum < 12) {
          hrsNum += 12;
        }
        if(hrsNum > 23) return null; // PARSE ERROR
      }
      remainingTimeString = remainingTimeString.substring(0, remainingTimeString.length-2).trim();
    }
    let hrs = hrsNum < 10 ? '0'+hrsNum.toString() : hrsNum.toString();

    var minutes = '00';
    if(remainingTimeString.indexOf(':') !== -1) {
      minutes = remainingTimeString.substring(0, remainingTimeString.indexOf(':'));
      if(minutes.length !== 2) return null; // PARSE ERROR
      
      remainingTimeString = remainingTimeString.substring(remainingTimeString.indexOf(':')+1).trim();
    }
    else {
      minutes = remainingTimeString.trim();
      if(minutes.length !== 2) return null; // PARSE ERROR
      
      remainingTimeString = '';
    }

    var seconds = '00';
    if(remainingTimeString.length > 0) {
      if(remainingTimeString.indexOf(':') !== -1) {
        seconds = remainingTimeString.substring(0, remainingTimeString.indexOf(':'));
        if(seconds.length !== 2) return null; // PARSE ERROR
        
        remainingTimeString = remainingTimeString.substring(remainingTimeString.indexOf(':')+1).trim();
      }
      else if(remainingTimeString.indexOf('.') !== -1) {
        seconds = remainingTimeString.substring(0, remainingTimeString.indexOf('.'));
        if(seconds.length !== 2) return null; // PARSE ERROR
        
        remainingTimeString = remainingTimeString.substring(remainingTimeString.indexOf('.')+1).trim();
      }
      else {
        seconds = remainingTimeString.trim();
        if(seconds.length !== 2) return null; // PARSE ERROR

        remainingTimeString = '';
      }
    } 

    var millis = '000';
    if(remainingTimeString.length > 0) {
      millis = remainingTimeString;

      if(millis.length === 1) {
        millis += '00';
      }
      else if(millis.length === 2) {
        millis += '0';
      }
      else if(millis.length > 3) {
        return null; // PARSE ERROR
      }
    }

    var formattedTime = hrs+':'+minutes+':'+seconds+this.getTimezoneString();

    return formattedTime;
  }

  getTimezoneString() {
    // var tzString = '';
    // var tzOffset = new Date().getTimezoneOffset();
    // if(tzOffset >= 0) {
    //   tzString += '-';
    // }
    // else {
    //   tzString += '+';
    // }
    // var tzOffsetHrs = Math.floor(tzOffset/60);
    // tzString += (tzOffsetHrs < 10 ? '0'+tzOffsetHrs.toString()+':' : tzOffsetHrs.toString()+':');
    // var tzOffsetMins = Math.round(((tzOffset/60) - tzOffsetHrs) * 60);
    // tzString += (tzOffsetMins < 10 ? '0'+tzOffsetMins.toString() : tzOffsetMins.toString());
    var tzString = '-06:00';
    return tzString;
  }

  formatDateForReport(inputDate) {
    try {
      var d = new Date(inputDate);
      var monthNum = d.getMonth()+1;
      let month = monthNum < 10 ? '0'+monthNum.toString() : monthNum.toString();
      let dayNum = d.getDate();
      let day = dayNum < 10 ? '0'+dayNum.toString() : dayNum.toString();
      return d.getFullYear()+'-'+month+'-'+day;
    }
    catch(e) {
      return null;
    }
  }

  fieldTypeFromSchema(tableName, fieldName) {
    var type = null;
    rootLoop:
    for(var fgIdx = 0; fgIdx < this.formGroups.length; fgIdx++) {
      var fg = this.formGroups[fgIdx];
      for(var sgIdx = 0; sgIdx < fg.subGroups.length; sgIdx++) {
        var sg = fg.subGroups[sgIdx];
        for(var fiIdx = 0; fiIdx < sg.formItems.length; fiIdx++) {
          var formItem = sg.formItems[fiIdx];
          if(formItem.key.substring(0, formItem.key.indexOf('-')).toLowerCase() === tableName.toLowerCase() &&
            formItem.fieldName.toLowerCase() === fieldName.toLowerCase()) {
              type = formItem.type;
              if(formItem.type === 'button-group' && formItem.items.length === 2 &&
              (formItem.items[0].label.toLowerCase() === 'yes' || formItem.items[0].label.toLowerCase() === 'no') &&
              (formItem.items[1].label.toLowerCase() === 'yes' || formItem.items[1].label.toLowerCase() === 'no')) {
                type = 'button-group-boolean';
              }
              break rootLoop;
          }
        }
      }
    }
    return type;
  }

  formatValForReport(tableName, propName, input) {
    var val = {};
    var tzString = this.getTimezoneString();
    
    // CHECK THE SCHEMA TO SEE IF THIS IS A TIME FIELD. IF SO, WE'LL
    // NEED TO FORMAT IT
    switch(this.fieldTypeFromSchema(tableName, propName)) {
      case 'time':
        val = this.formatTime(input);
        if(val === null) {
          val = "1901-01-01T00:00:00"+tzString;
        }
        else {
          val = "1901-01-01T" + val;
        }
        break;
      case 'date':
        if(input == null) {
          val = "1901-01-01T00:00:00"+tzString;
        }
        else {
          try {
            var d = new Date(input);
            var monthNum = d.getMonth()+1;
            var month = monthNum < 10 ? '0'+monthNum.toString() : monthNum.toString();
            var dayNum = d.getDate();
            var day = dayNum < 10 ? '0'+dayNum.toString() : dayNum.toString();
            val = d.getFullYear()+'-'+month+'-'+day+'T00:00:00'+tzString;
          }
          catch(e) {
            val = "1901-01-01T00:00:00"+tzString;
          }
        }
        break;
      case 'button-group-boolean':
        if(input != null && input == true) {
          val = true;
        }
        else {
          val = false;
        }
        break;
      case 'number':
        if(input == null || input === '') {
          val = null;
        }
        else {
          val = input;
        }
        break;
      case 'text':
        if(tableName === 'crash' && (propName === 'lat' || propName === 'long')) {
          if(input == null || input === '') {
            val = null;
          }
          else {
            val = input;
          }
        }
        else {
          if(input != null && input !== '') {
            val = input;
          }
        }
        break
      case 'button-group':
        if(tableName === 'crash' && propName === 'est_alcohol') {
          if(input == null || input === '') {
            val = null;
          }
          else {
            val = input;
          }
        }
        else {
          if(input != null && input !== '') {
            val = input;
          }
        }
        break;
      case 'image': {
        if(input != null && input !== '') {
          val = input.substring(input.indexOf(',')+1);
        }
        break;
      }
      default:
        if(input != null)
          val = input;
    }

    return val;
  }


  exportReport() {
    // MAKE SURE this.reportData IS UP TO DATE
    this.api.getReport(this.crashId)
    .subscribe((data) => {
      if(data !== undefined && data !== null) {
        this.reportData = data;

        var rightNow = new Date();
        var dateNow = this.formatDateForReport(rightNow);
        var timeNow = this.formatTime(rightNow.toLocaleTimeString());
        var now = dateNow+'T'+timeNow;

        // FLAGS TO KNOW WHICH PARTS OF THE XSD NEED TO BE INSERTED
        var hasVehic = false; 
        var hasSignInit = false;
        var hasNarr = false;
        var hasAltGrid = false;
        var hasOccup = false;
        var hasDrWitness = false;
        var hasPedes = false;
        var hasNarrSup = false;
        var hasTrain = false;
        var hasTrocc = false;
        
        
        // IGNORE THESE FIELDS
        var ignoreFields = [
          // TABLES
          'altgrid', 'narr', 'narrsup', 'vehic', 'pedes', 'occup', 'train', 'trocc', 'drwitness', 'witness',
          // OSS FIELDS
          'crash_num', 'lacrash_id', 'rev_num', 'report_status', 'report_resolution', 'agent_coordinates', 'response_status', 
          'date_entered', 'crash_coordinates', 'cr_month', 'cr_hour', 'crash_year', 'invest_officer_signature',
          'invest_officer_sign', 'invest_officer_init', 'rpt_origin', 'alcohol', 'day_of_wk', 'drugs', 'num_dri_ik',
          'num_dri_inj', 'num_dri_kil', 'num_occ_ik', 'num_occ_inj', 'num_occ_kil', 'num_ped_ik', 'num_ped_inj',
          'num_ped_kil', 'num_tot_ik', 'num_tot_inj', 'num_tot_kil', 'severity_cd', 'trk_bus_inv', 'code', 'district',
          'state_rpt_num', 'hwy_bypass', 'who_entered'
        ];

        let laCrashNum = this.reportData['lacrash_id'];
        let reportNum2 = this.reportData['report_num_2'];

        // BUILD THE JSON TO BE FED INTO THE JSON -> XML TOOL
        // ===========================================================================
        // INSERT THE CRASH_TB DATA
        // ===========================================================================
        var xmlObj = {
          "@": {
            "xmlns:msdata": "urn:schemas-microsoft-com:xml-msdata",
            "xmlns:diffgr": "urn:schemas-microsoft-com:xml-diffgram-v1"
          },
          "NewDataSet": {
            "CRASH_TB": {
              "@": {
                "diffgr:id": "CRASH_TB1",
                "msdata:rowOrder": "0",
                "diffgr:hasChanges": "inserted"
              },
              "RPT_ORIGIN": "NEWORLEANSPD",
              "VERSION_ORIGINAL": "2.0.12.2112",
              "VERSION_CURRENT": "2.0.12.2112",
              "CRASH_NUM": laCrashNum,
              "REV_DATE": "1901-01-01T00:00:00-06:00",
              "PAGE_OWNER_APPROVER": {},
              "PAGE_OWNER": {},
              "REV_STAT": "0",
              "CURR_STAT": false,
              "GLOB_STAT": false,
              "OUT": "OF",
              "DATE_ENTERED": now,
              "MAN_COLL_CD": {},
              "WHO_ENTERED": "DODOM"
            }
          }
        };

        if(this.reportData['rpt_origin'] != null && this.reportData['rpt_origin'] !== '') {
          xmlObj.NewDataSet.CRASH_TB.RPT_ORIGIN = this.reportData['rpt_origin'];
        }

        if(this.reportData['who_entered'] != null && this.reportData['who_entered'] !== '') {
          xmlObj.NewDataSet.CRASH_TB.WHO_ENTERED = this.reportData['who_entered'];
        }

        let reportDataKeys = Object.getOwnPropertyNames(this.reportData);
        for(var kIdx = 0; kIdx < reportDataKeys.length; kIdx++) {
          var propName = reportDataKeys[kIdx];
          if(!ignoreFields.includes(propName)) {
            var val = this.formatValForReport('crash', propName, this.reportData[propName]);
            if(val !== null) {
              xmlObj.NewDataSet.CRASH_TB[propName.toUpperCase()] = val;
            }
          }
        }
        // ===========================================================================

        // ===========================================================================
        // HANDLE SIGNINIT_TB (IN LA CRASH BUT NOT OUR SYSTEM.  WE STORE IT IN CRASH_TB)
        // ===========================================================================
        var signXMLObj = {
          "@": {
            "diffgr:id": "SIGNINIT_TB1",
            "msdata:rowOrder": "0",
            "diffgr:hasChanges": "inserted"
          },
          "CRASH_NUM": laCrashNum
        }
        
        // IF THERE IS DATA FOR THE INVEST_OFFICER_SIGN, THEN INCLUDE THIS SECTION
        if(this.reportData['invest_officer_signature'] != null) {
          signXMLObj['INVEST_OFFICER_SIGN'] = this.reportData['invest_officer_signature'];
          hasSignInit = true;
          signXMLObj['INVEST_OFFICER_INIT'] = {};
          xmlObj.NewDataSet['SIGNINIT_TB'] = signXMLObj;
        }
        // ===========================================================================

        // ===========================================================================
        // HANDLE THE NARR_TB DATA
        // ===========================================================================
        var narr = this.reportData['narr'][0];
        var narrXMLObj = {
          "@": {
            "diffgr:id": "NARR_TB1",
                "msdata:rowOrder": "0",
                "diffgr:hasChanges": "inserted"
          },
          "CRASH_NUM": laCrashNum,
          "REPORT_NUM_2": reportNum2,
          "REV_DATE": "1901-01-01T00:00:00-06:00",
          "PAGE_OWNER_APPROVER": {},
          "PAGE_OWNER": {},
          "REV_STAT": "0",
          "CURR_STAT": false,
          "MAN_COLL_CD": {},
        }
        var propNames = Object.getOwnPropertyNames(narr);
        for(var nIdx = 0; nIdx < propNames.length; nIdx++) {
          var propName = propNames[nIdx];
          var val = this.formatValForReport('narr', propName, narr[propName]);
          if(val !== null) {
            narrXMLObj[propName.toUpperCase()] = val;
            if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
              hasNarr = true;
            }
          }
        }
        if(hasNarr === true) {
          xmlObj.NewDataSet['NARR_TB'] = narrXMLObj;
        }
        // ===========================================================================

        // ===========================================================================
        // HANDLE THE VEHIC_TB DATA
        // ===========================================================================
        var vehics = this.reportData['vehic'];
        var vehicXMLArray = [];
        let vIgnoreFields = ['vin8', 'vin10', 'date_entered', 'crash_num', 'report_num_2', 'rev_date',
                              'page_owner_approver', 'page_owner', 'rev_stat', 'curr_stat'];
        for(var vIdx = 0; vIdx < vehics.length; vIdx++) {
          var v = vehics[vIdx];
          var vTableId = 'VEHIC_TB'+v.veh_num;
          var rowNum =  v.veh_num - 1;
          var vehicXMLObj = {
            "@": {
              "diffgr:id": vTableId,
              "msdata:rowOrder": rowNum.toString(),
              "diffgr:hasChanges": "inserted"
            },
            "CRASH_NUM": laCrashNum,
            "REPORT_NUM_2": reportNum2,
            "REV_DATE": "1901-01-01T00:00:00-06:00",
            "PAGE_OWNER_APPROVER": {},
            "PAGE_OWNER": {},
            "REV_STAT": "0",
            "CURR_STAT": false,
          }

          var propNames = Object.getOwnPropertyNames(v);
          for(var vkIdx = 0; vkIdx < propNames.length; vkIdx++) {
            var propName = propNames[vkIdx];
            if(!vIgnoreFields.includes(propName)) {
              var val = this.formatValForReport('vehic', propName, v[propName]);
              if(val !== null) {
                vehicXMLObj[propName.toUpperCase()] = val;
                if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
                  hasVehic = true;
                }
              }
            }
          }
          vehicXMLArray.push(vehicXMLObj);
        }
        if(hasVehic === true) {
          xmlObj.NewDataSet['VEHIC_TB'] = vehicXMLArray;
        }
        // ===========================================================================

        // ===========================================================================
        // HANDLE DRWITNESS_TB
        // ===========================================================================
        // var drws = this.reportData['drwitness'];
        // // OSS STORES DRIVER STATMENTS AND WITNESS STATEMENTS IN
        // // TWO TABLES,  WE NEED TO MERGE THEM FOR LACRASH
        // this.reportData['witness'].map((w) => {
        //   drws.push(w);
        // });
        // var drwXMLArray = [];
        // let drwIgnoreFields = [];
        // for(var dIdx = 0; dIdx < drws.length; dIdx++) {
        //   var drw = drws[dIdx];
        //   var wNum;

        //   // ASSUMES 1 DRIVER PER VEHICLE
        //   if(drw.hasOwnProperty('veh_num')) {
        //     var wNum = drw.veh_num;
        //   }
        //   else {
        //     var wNum = this.reportData['drwitness'].length + drw.statement_num;
        //   }
        //   var drwTableId = 'DRWITNESS_TB'+wNum.toString();
        //   var rowNum = wNum-1;
          
        //   var drwXMLObj = {
        //     "@": {
        //       "diffgr:id": drwTableId,
        //       "msdata:rowOrder": rowNum.toString(),
        //       "diffgr:hasChanges": "inserted"
        //     },
        //     "CRASH_NUM": laCrashNum,
        //     "REPORT_NUM_2": reportNum2,
        //     "REV_DATE": "1901-01-01T00:00:00-06:00",
        //     "PAGE_OWNER_APPROVER": {},
        //     "PAGE_OWNER": {},
        //     "REV_STAT": "0",
        //     "CURR_STAT": false
        //   }

        //   var propNames = Object.getOwnPropertyNames(drw);
        //   for(var dkIdx = 0; dkIdx < propNames.length; dkIdx++) {
        //     var propName = propNames[dkIdx];
        //     if(!drwIgnoreFields.includes(propName)) {
        //       var val = this.formatValForReport('drwitness', propName, drw[propName]);
        //       if(val !== null) {
        //         drwXMLObj[propName.toUpperCase()] = val;
        //         if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
        //           hasDrWitness = true;
        //         }
        //       }
        //     }
        //   }
        //   drwXMLArray.push(drwXMLObj);
        // }
        
        // if(hasDrWitness === true) {
        //   xmlObj.NewDataSet['DRWITNESS_TB'] = drwXMLArray;
        // }
        // ===========================================================================

        // ===========================================================================
        // HANDLE OCCUP_TB
        // ===========================================================================
        var occups = this.reportData['occup'];
        var occXMLArray = [];
        let occIgnoreFields = [];

        // OSS KEEPS COUNT OF OCCUP PER VEHICLE.  WE NEED TO CONVERT THIS
        // A GLOBAL OCCUP NUM.
        var numOccups = [];
        for(var vIdx = 0; vIdx < this.reportData.vehic.length; vIdx++) {
          numOccups[vIdx] = 0;
        }
        occups.map((o) => {
          numOccups[o.veh_num-1]++;
        });


        for(var oIdx = 0; oIdx < occups.length; oIdx++) {
          var occ = occups[oIdx];
          
          // ADD UP ALL OCCUPANTS IN VEHICLES WITH VEH_NUM LESS THAN 
          // THIS VEH_NUM
          var occNum = 0;
          var vehIdx = 1
          while(occ.veh_num > vehIdx) {
            occNum += numOccups[vehIdx-1];
            vehIdx++
          }
          occNum += occ.occ_num;


          var occTableId = 'OCCUP_TB'+occNum;
          var rowNum =  occNum - 1;
          var occXMLObj = {
            "@": {
              "diffgr:id": occTableId,
              "msdata:rowOrder": rowNum,
              "diffgr:hasChanges": "inserted"
            },
            "CRASH_NUM": laCrashNum,
            "REPORT_NUM_2": reportNum2,
            "REV_DATE": "1901-01-01T00:00:00-06:00",
            "PAGE_OWNER_APPROVER": {},
            "PAGE_OWNER": {},
            "REV_STAT": "0",
            "CURR_STAT": false
          }

          var propNames = Object.getOwnPropertyNames(occ);
          for(var okIdx = 0; okIdx < propNames.length; okIdx++) {
            var propName = propNames[okIdx];
            if(!occIgnoreFields.includes(propName)) {
              var val = this.formatValForReport('occup', propName, occ[propName]);
              if(val !== null) {
                occXMLObj[propName.toUpperCase()] = val;
                if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
                  hasOccup = true;
                }
              }
            }
          }
          occXMLArray.push(occXMLObj);
        }
        if(hasOccup === true) {
          xmlObj.NewDataSet['OCCUP_TB'] = occXMLArray;
        }
        // ===========================================================================

        // ===========================================================================
        // HANDLE ALTGRID_TB
        // ===========================================================================
        var altgrids = this.reportData['altgrid'];
        var agXMLArray = [];
        let agIgnoreFields = [];

        for(var agIdx = 0; agIdx < altgrids.length; agIdx++) {
          var ag = altgrids[agIdx];
          
          var agTableId = 'ALTGRID_TB'+ag['grid_num'];
          var rowNum =  ag['grid_num']-1;
          var agXMLObj = {
            "@": {
              "diffgr:id": agTableId,
              "msdata:rowOrder": rowNum,
              "diffgr:hasChanges": "inserted"
            },
            "CRASH_NUM": laCrashNum,
            "REPORT_NUM_2": reportNum2,
            "REV_DATE": "1901-01-01T00:00:00-06:00",
            "PAGE_OWNER_APPROVER": {},
            "PAGE_OWNER": {},
            "REV_STAT": "0",
            "CURR_STAT": false
          }

          var propNames = Object.getOwnPropertyNames(ag);
          for(var akIdx = 0; akIdx < propNames.length; akIdx++) {
            var propName = propNames[akIdx];
            if(!agIgnoreFields.includes(propName)) {
              var val = this.formatValForReport('altgrid', propName, ag[propName]);
              if(val !== null) {
                agXMLObj[propName.toUpperCase()] = val;
                if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
                  hasAltGrid = true;
                }
              }
            }
          }
          agXMLArray.push(agXMLObj);
        }
        
        if(hasAltGrid === true) {
          xmlObj.NewDataSet['ALTGRID_TB'] = agXMLArray;
        }
        // ===========================================================================

        // ===========================================================================
        // HANDLE NARRSUP_TB
        // ===========================================================================
        var narrsups = this.reportData['narrsup'];
        var nsXMLArray = [];
        let nsIgnoreFields = [];

        for(var nsIdx = 0; nsIdx < narrsups.length; nsIdx++) {
          var ns = narrsups[nsIdx];
          
          var nsTableId = 'NARRSUP_TB'+ns['narr_num'];
          var rowNum =  ns['narr_num']-1;
          var nsXMLObj = {
            "@": {
              "diffgr:id": nsTableId,
              "msdata:rowOrder": rowNum,
              "diffgr:hasChanges": "inserted"
            },
            "CRASH_NUM": laCrashNum,
            "REPORT_NUM_2": reportNum2,
            "REV_DATE": "1901-01-01T00:00:00-06:00",
            "PAGE_OWNER_APPROVER": {},
            "PAGE_OWNER": {},
            "REV_STAT": "0",
            "CURR_STAT": false
          }

          var propNames = Object.getOwnPropertyNames(ns);
          for(var nkIdx = 0; nkIdx < propNames.length; nkIdx++) {
            var propName = propNames[nkIdx];
            if(!nsIgnoreFields.includes(propName)) {
              var val = this.formatValForReport('narrsup', propName, ns[propName]);
              if(val !== null) {
                nsXMLObj[propName.toUpperCase()] = val;
                if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
                  hasNarrSup = true;
                }
              }
            }
          }
          nsXMLArray.push(nsXMLObj);
        }
        if(hasNarrSup === true) {
          xmlObj.NewDataSet['NARRSUP_TB'] = nsXMLArray;
        }
        // ===========================================================================


        // ===========================================================================
        // HANDLE PEDES_TB
        // ===========================================================================
        var pedes = this.reportData['pedes'];
        var pdXMLArray = [];
        let pdIgnoreFields = [];

        for(var pdIdx = 0; pdIdx < pedes.length; pdIdx++) {
          var pd = pedes[pdIdx];
          
          var pdTableId = 'PEDES_TB'+pd['ped_num'];
          var rowNum =  pd['ped_num']-1;
          var pdXMLObj = {
            "@": {
              "diffgr:id": pdTableId,
              "msdata:rowOrder": rowNum,
              "diffgr:hasChanges": "inserted"
            },
            "CRASH_NUM": laCrashNum,
            "REPORT_NUM_2": reportNum2,
            "REV_DATE": "1901-01-01T00:00:00-06:00",
            "PAGE_OWNER_APPROVER": {},
            "PAGE_OWNER": {},
            "REV_STAT": "0",
            "CURR_STAT": false
          }

          var propNames = Object.getOwnPropertyNames(pd);
          for(var pkIdx = 0; pkIdx < propNames.length; pkIdx++) {
            var propName = propNames[pkIdx];
            if(!pdIgnoreFields.includes(propName)) {
              var val = this.formatValForReport('pedes', propName, pd[propName]);
              if(val !== null) {
                pdXMLObj[propName.toUpperCase()] = val;
                if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
                  hasPedes = true;
                }
              }
            }
          }
          pdXMLArray.push(pdXMLObj);
        }
        if(hasPedes === true) {
          xmlObj.NewDataSet['PEDES_TB'] = pdXMLArray;
        }
        // ===========================================================================

        // ===========================================================================
        // HANDLE TRAIN_TB
        // ===========================================================================
        var trains = this.reportData['train'];
        var trainXMLArray = [];
        let trainIgnoreFields = [];

        for(var tIdx = 0; tIdx < trains.length; tIdx++) {
          var train = trains[tIdx];
          
          var trainTableId = 'TRAIN_TB'+train['tra_num'];
          var rowNum =  train['tra_num']-1;
          var trainXMLObj = {
            "@": {
              "diffgr:id": trainTableId,
              "msdata:rowOrder": rowNum,
              "diffgr:hasChanges": "inserted"
            },
            "CRASH_NUM": laCrashNum,
            "REPORT_NUM_2": reportNum2,
            "REV_DATE": "1901-01-01T00:00:00-06:00",
            "PAGE_OWNER_APPROVER": {},
            "PAGE_OWNER": {},
            "REV_STAT": "0",
            "CURR_STAT": false
          }

          var propNames = Object.getOwnPropertyNames(train);
          for(var tkIdx = 0; tkIdx < propNames.length; tkIdx++) {
            var propName = propNames[tkIdx];
            if(!trainIgnoreFields.includes(propName)) {
              var val = this.formatValForReport('train', propName, train[propName]);
              if(val !== null) {
                trainXMLObj[propName.toUpperCase()] = val;
                if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
                  hasTrain = true;
                }
              }
            }
          }
          trainXMLArray.push(trainXMLObj);
        }
        if(hasTrain === true) {
          xmlObj.NewDataSet['TRAIN_TB'] = trainXMLArray;
        }
        // ===========================================================================

        // ===========================================================================
        // HANDLE TROCC_TB
        // ===========================================================================
        var troccs = this.reportData['trocc'];
        var troccXMLArray = [];
        let troccIgnoreFields = [];

        // OSS KEEPS COUNT OF TROCC PER TRAIN.  WE NEED TO CONVERT THIS
        // A GLOBAL TROCC NUM.
        var numTroccs = [];
        for(var tIdx = 0; tIdx < this.reportData.train.length; tIdx++) {
          numTroccs[tIdx] = 0;
        }
        troccs.map((t) => {
          numTroccs[t.tra_num-1]++;
        });


        for(var tIdx = 0; tIdx < troccs.length; tIdx++) {
          var trocc = troccs[tIdx];
          
          // ADD UP ALL OCCUPANTS IN VEHICLES WITH VEH_NUM LESS THAN 
          // THIS VEH_NUM
          var troccNum = 0;
          var traIdx = 1
          while(trocc.tra_num > traIdx) {
            troccNum += numTroccs[traIdx-1];
            traIdx++
          }
          troccNum += trocc.occ_num;


          var troccTableId = 'TROCC_TB'+troccNum;
          var rowNum =  troccNum - 1;
          var troccXMLObj = {
            "@": {
              "diffgr:id": troccTableId,
              "msdata:rowOrder": rowNum,
              "diffgr:hasChanges": "inserted"
            },
            "CRASH_NUM": laCrashNum,
            "REPORT_NUM_2": reportNum2,
            "REV_DATE": "1901-01-01T00:00:00-06:00",
            "PAGE_OWNER_APPROVER": {},
            "PAGE_OWNER": {},
            "REV_STAT": "0",
            "CURR_STAT": false
          }

          var propNames = Object.getOwnPropertyNames(trocc);
          for(var tkIdx = 0; tkIdx < propNames.length; tkIdx++) {
            var propName = propNames[tkIdx];
            if(!troccIgnoreFields.includes(propName)) {
              var val = this.formatValForReport('trocc', propName, trocc[propName]);
              if(val !== null) {
                troccXMLObj[propName.toUpperCase()] = val;
                if(typeof(val) !== 'object' || (typeof(val) === 'object' && Object.getOwnPropertyNames(val).length > 0)) {
                  hasTrocc = true;
                }
              }
            }
          }
          troccXMLArray.push(troccXMLObj);
        }
        if(hasTrocc === true) {
          xmlObj.NewDataSet['TROCC_TB'] = troccXMLArray;
        }
        // ===========================================================================


        // CREATE THE XML
        var js2xmlparser = require("js2xmlparser");
        var xmlBody = js2xmlparser.parse("diffgr:diffgram", xmlObj);

        // REMOVE THE XML SPEC TAG
        xmlBody = xmlBody.substring(xmlBody.indexOf('\n')+1);

        let b = new Blob([xmlBody], {type: 'text/plain'});
        var xmlFileName = reportNum2 + '.xml';
        saveAs(b, xmlFileName);


        // CREATE THE XSD
        var xsdBody = this.buildXsd(hasVehic, hasSignInit, hasNarr, hasAltGrid, hasOccup, hasDrWitness, hasPedes, hasNarrSup, hasTrain, hasTrocc);
        let b2 = new Blob([xsdBody], {type: 'text/plain'});
        var xsdFileName = reportNum2 + '.xsd';
        saveAs(b2, xsdFileName);
      }
      // GET REPORT RETURNED NO DATA
      else {
        alert('There was a problem getting the report for export');
      }
    },
    // ERROR GETTING REPORT
    (err) => {
      alert('There was a problem getting the report for export');
    });
  }

  buildXsd(hasVehic, hasSignInit, hasNarr, hasAltGrid, hasOccup, hasDrWitness, hasPedes, hasNarrSup, hasTrain, hasTrocc) {
    var xsdString = '<?xml version="1.0" encoding="utf-8"?> \
                    <xs:schema id="NewDataSet" xmlns="" xmlns:xs="http://www.w3.org/2001/XMLSchema" \
                    xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"> \
                    <xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:UseCurrentLocale="true"> \
                    <xs:complexType> \
                    <xs:choice minOccurs="0" maxOccurs="unbounded">';

    xsdString += '<xs:element name="CRASH_TB"> \
                  <xs:complexType> \
                    <xs:sequence> \
                      <xs:element name="RPT_ORIGIN" type="xs:string" minOccurs="0" /> \
                      <xs:element name="VERSION_ORIGINAL" type="xs:string" minOccurs="0" /> \
                      <xs:element name="VERSION_CURRENT" type="xs:string" minOccurs="0" /> \
                      <xs:element name="DATE_CHANGED" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="EST_ALCOHOL" type="xs:int" minOccurs="0" /> \
                      <xs:element name="DATE_ENTERED" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                      <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                      <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                      <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="GLOB_STAT" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="OUT" type="xs:string" minOccurs="0" /> \
                      <xs:element name="ISDS_CODE" type="xs:string" minOccurs="0" /> \
                      <xs:element name="MAN_COLL_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="SEC_INTER_ROAD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="INTER_ROAD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="PRI_ROAD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                      <xs:element name="WEATHER_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="LOC_TYPE_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="ROAD_REL_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="ACCESS_CNTL_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="LIGHTING_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="SEC_CONTRIB_FAC_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="PRI_CONTRIB_FAC_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="SURF_TYPE_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="SURF_COND_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="ROAD_COND_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="ROAD_TYPE_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="ALIGNMENT_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="SEC_INTER_ROAD_TYPE" type="xs:string" minOccurs="0" /> \
                      <xs:element name="SEC_INTER_ROAD_NUM" type="xs:double" minOccurs="0" /> \
                      <xs:element name="SEC_INTER_ROAD_DIR" type="xs:string" minOccurs="0" /> \
                      <xs:element name="SEC_INTER_ROAD_NAME" type="xs:string" minOccurs="0" /> \
                      <xs:element name="INTER_ROAD_TYPE" type="xs:string" minOccurs="0" /> \
                      <xs:element name="INTER_ROAD_NUM" type="xs:double" minOccurs="0" /> \
                      <xs:element name="INTER_ROAD_DIR" type="xs:string" minOccurs="0" /> \
                      <xs:element name="INTER_ROAD_NAME" type="xs:string" minOccurs="0" /> \
                      <xs:element name="PRI_ROAD_TYPE" type="xs:string" minOccurs="0" /> \
                      <xs:element name="PRI_ROAD_NUM" type="xs:double" minOccurs="0" /> \
                      <xs:element name="PRI_ROAD_DIR" type="xs:string" minOccurs="0" /> \
                      <xs:element name="INVEST_OFFICER_BADGENUM" type="xs:string" minOccurs="0" /> \
                      <xs:element name="INVEST_OFFICER" type="xs:string" minOccurs="0" /> \
                      <xs:element name="PROCESS_DATE" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="FIRE_DEPT" type="xs:string" minOccurs="0" /> \
                      <xs:element name="AMB_SERVICE" type="xs:string" minOccurs="0" /> \
                      <xs:element name="INVEST_AGENCY_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="TIME_LANES_OPEN" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="TIME_POLICE_ARR" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="TIME_POLICE_NOTE" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="INVEST_AGENCY_NAME" type="xs:string" minOccurs="0" /> \
                      <xs:element name="TIME_RESCUE_ARR" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="TIME_RESCUE_CALLED" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="TIME_AMB_ARR_HOSP" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="TIME_AMB_DEPART" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="TIME_AMB_ARR" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="TIME_AMB_CALLED" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="SEC_DIST" type="xs:double" minOccurs="0" /> \
                      <xs:element name="SEC_INTERSECTION_NOT" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="SEC_INTERSECTION" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="SEC_DIR" type="xs:string" minOccurs="0" /> \
                      <xs:element name="SEC_MEASUREMENT_FEET" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="SEC_MEASUREMENT_MILES" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="PRI_DIR" type="xs:string" minOccurs="0" /> \
                      <xs:element name="PRI_DIST" type="xs:double" minOccurs="0" /> \
                      <xs:element name="PRI_ROAD_NAME" type="xs:string" minOccurs="0" /> \
                      <xs:element name="BYPASS_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="MILEPOST" type="xs:double" minOccurs="0" /> \
                      <xs:element name="PRI_HWY_NUM" type="xs:int" minOccurs="0" /> \
                      <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                      <xs:element name="REPORT_NUM" type="xs:string" minOccurs="0" /> \
                      <xs:element name="CITY_CD" type="xs:int" minOccurs="0" /> \
                      <xs:element name="CITY" type="xs:string" minOccurs="0" /> \
                      <xs:element name="PARISH_CD" type="xs:int" minOccurs="0" /> \
                      <xs:element name="PARISH" type="xs:string" minOccurs="0" /> \
                      <xs:element name="LONG" type="xs:decimal" minOccurs="0" /> \
                      <xs:element name="LAT" type="xs:decimal" minOccurs="0" /> \
                      <xs:element name="TROOP" type="xs:string" minOccurs="0" /> \
                      <xs:element name="DIST_ZONE" type="xs:string" minOccurs="0" /> \
                      <xs:element name="CRASH_TIME" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="CRASH_DATE" type="xs:dateTime" minOccurs="0" /> \
                      <xs:element name="NUM_VEH" type="xs:int" minOccurs="0" /> \
                      <xs:element name="REV_NUM" type="xs:int" minOccurs="0" /> \
                      <xs:element name="INVEST_COMP" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="RESCUE_UNIT" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="AMBULANCE" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="INJURY" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="PEDESTRIAN" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="FATALITY" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="RR_TRAIN_INV" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="PHOTOS_MADE" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="PUB_PROP_DAM" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="HIT_AND_RUN" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="CONST_MAINT_ZN" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="INTERSECTION_NOT" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="INTERSECTION" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="PRI_MEASUREMENT_FEET" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="PRI_MEASUREMENT_MILES" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="SERVICE_ROAD_W" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="SERVICE_ROAD_E" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="SERVICE_ROAD_S" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="SERVICE_ROAD_N" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="QUADRANT_SE" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="QUADRANT_SW" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="QUADRANT_NE" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="QUADRANT_NW" type="xs:boolean" minOccurs="0" /> \
                      <xs:element name="HWY_TYPE_CD" type="xs:string" minOccurs="0" /> \
                      <xs:element name="WHO_ENTERED" type="xs:string" minOccurs="0" /> \
                    </xs:sequence> \
                  </xs:complexType> \
                </xs:element>';

    if(hasVehic) {
      xsdString += '<xs:element name="VEHIC_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="DAMAGE_EXT3_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DAMAGE_EXT2_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DAMAGE_EXT1_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="FI_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="FO_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="T_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="S_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="F_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="NOTICE_INS_VIOL" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="FI_VEH_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="FO_VEH_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="T_VEH_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="S_VEH_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="F_VEH_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="FI_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="FO_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="T_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="F_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="S_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="SKID_RL" type="xs:double" minOccurs="0" /> \
                        <xs:element name="SKID_RR" type="xs:double" minOccurs="0" /> \
                        <xs:element name="SKID_FL" type="xs:double" minOccurs="0" /> \
                        <xs:element name="SKID_FR" type="xs:double" minOccurs="0" /> \
                        <xs:element name="DAMAGE_AREA3_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DAMAGE_AREA2_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DAMAGE_AREA1_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_DIST_TRAVELED" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_FINAL_LOC" type="xs:string" minOccurs="0" /> \
                        <xs:element name="POSTED_SPEED" type="xs:int" minOccurs="0" /> \
                        <xs:element name="EST_SPEED" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRAVEL_DIR_ROAD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRAVEL_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_BLOOD_ALC_KIT_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_DRUGS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_ALCOHOL_BAC" type="xs:double" minOccurs="0" /> \
                        <xs:element name="DR_ALCOHOL_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_A_D_PRES_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRAFF_CNTL_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="FO_HARM_EV_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="M_HARM_EV_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="T_HARM_EV_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="S_HARM_EV_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="F_HARM_EV_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PRIOR_MOVEMENT_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_COND_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRAFF_CNTL_COND_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="MOVEMENT_REASON_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_COND_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VIOLATIONS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VISION_OBSCURE_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_LIGHTING_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_DISTRACT_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OWNER_ST" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_ST" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_ADDR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="EX_INFO" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="UTB_HAZ_CAR_REL" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="UTB_HAZ_MAT_PLAC" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="UTB_TRANS_HAZ_MAT" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="UTB_INTERSTATE_CAR" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="OWNER_ST_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OWNER_ST_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OWNER_ST_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="OWNER_ST_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_ST_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_ST_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_ST_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="DR_ST_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_ADDR_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_ADDR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_ADDR_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="INS_PHONE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="INS_AGENT" type="xs:string" minOccurs="0" /> \
                        <xs:element name="INS_EXP" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="INS_POL_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="INSCO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OWNER_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OWNER_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OWNER_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OWNER_HOME_PHONE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OWNER_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_MED_FAC_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_TRAN_MED_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_LIC_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENDORSE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DL_CLASS" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_LIC_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_INJ_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_AGE" type="xs:int" minOccurs="0" /> \
                        <xs:element name="DR_RACE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_SEX" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_PROTSYS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_AIRBAG_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_TRAP_EXTR_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_POS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_EJEC_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_HOME_PHONE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DR_DOB" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="DR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_THM_HAZ_ID" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_THM_HAZ_CLS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_ADDR_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_CAR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_ICC_IDNUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_DOT_IDNUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_TRAILER_PLATE_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_TRAILER_PLATE_ST" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_TRAILER_PLATE_YR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_TRAILER_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_TRAILER_MAKE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_TRAILER_YEAR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REAS_TOW_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_GVWR_TTB" type="xs:int" minOccurs="0" /> \
                        <xs:element name="PLATE_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PLATE_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REG_YEAR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REG_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_REMOVED_BY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_TOWED_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VIN" type="xs:string" minOccurs="0" /> \
                        <xs:element name="NUM_TIRES" type="xs:int" minOccurs="0" /> \
                        <xs:element name="NUM_AXLES" type="xs:int" minOccurs="0" /> \
                        <xs:element name="NUM_DOORS" type="xs:int" minOccurs="0" /> \
                        <xs:element name="VEH_MODEL" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_MAKE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_YEAR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="UTB_BOD_TYP_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_TYPE_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_NUM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="PERSONAL_VEH" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="GOV_VEH" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="COMM_BUS_VEH" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="OWNER_INFO_DR_SAME" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }

    if(hasOccup) {
      xsdString += '<xs:element name="OCCUP_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="OCC_ADDR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_NUM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_MED_FAC_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_TRAN_MED_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_INJ_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_AGE" type="xs:int" minOccurs="0" /> \
                        <xs:element name="OCC_RACE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_SEX" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_PROTSYS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_AIRBAG_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_TRAP_EXTR_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_EJEC_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_POS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="OCC_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="VEH_NUM" type="xs:int" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }
    
    if(hasPedes) {
      xsdString += '<xs:element name="PEDES_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="PED_BLOOD_ALC_KIT_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_DRUGS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_ALCOHOL_BAC" type="xs:double" minOccurs="0" /> \
                        <xs:element name="PED_ALCOHOL_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_A_D_PRES_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_ACTIONS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_COND_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="FI_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="FO_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="T_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="S_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="F_RS_ORD_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="FI_PED_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="FO_PED_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="T_PED_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="S_PED_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="F_PED_CIT_RS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="FI_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="FO_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="T_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="F_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="S_CITATION_NO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_ST" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_INJ_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_AGE" type="xs:int" minOccurs="0" /> \
                        <xs:element name="PED_RACE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_SEX" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_CLOTH_LO_D" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="PED_CLOTH_LO_L" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="PED_CLOTH_UP_D" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="PED_CLOTH_UP_L" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="PED_ST_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_ST_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_ST_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="PED_ST_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_MED_FAC_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_TRAN_MED_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_HOME_PHONE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PED_NUM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }
    
    if(hasNarr) {
      xsdString += '<xs:element name="NARR_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="NARRATIVE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DIAGRAM" type="xs:base64Binary" minOccurs="0" /> \
                        <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="MAN_COLL_CD" type="xs:string" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }

    if(hasNarrSup) {
      xsdString += '<xs:element name="NARRSUP_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="NARR_NUM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="NARRATIVE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }

    if(hasAltGrid) {
      xsdString += '<xs:element name="ALTGRID_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="GRID_NUM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="DIAGRAM" type="xs:base64Binary" minOccurs="0" /> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }

    // if(hasDrWitness) {
    //   xsdString += '<xs:element name="DRWITNESS_TB"> \
    //                 <xs:complexType> \
    //                 </xs:complexType> \
    //               </xs:element>';
    // }

    if(hasSignInit) {
      xsdString += '<xs:element name="SIGNINIT_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="INVEST_OFFICER_INIT" type="xs:base64Binary" minOccurs="0" /> \
                        <xs:element name="INVEST_OFFICER_SIGN" type="xs:base64Binary" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }

    if(hasTrain) {
      xsdString += '<xs:element name="TRAIN_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="COND_ADDR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_ADDR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER_ADDR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OPER_CO_ADDR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRA_NUM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="DOT_PLACARD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="LEAD_ENG_NUM_CAR" type="xs:int" minOccurs="0" /> \
                        <xs:element name="RAILCAR_STRUCK_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="RAILCAR_NUM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="TRAIN_TRAVEL_DIST" type="xs:double" minOccurs="0" /> \
                        <xs:element name="TRAIN_NUM_CAR" type="xs:int" minOccurs="0" /> \
                        <xs:element name="TRAIN_NUM_ENG" type="xs:int" minOccurs="0" /> \
                        <xs:element name="TRAIN_SERIAL_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRAIN_LEAD_ENG_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRAIN_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRAIN_MAKE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="HWY_USER_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="AWDF_OTHER_DESC" type="xs:string" minOccurs="0" /> \
                        <xs:element name="AWD_OTHER_DESC" type="xs:string" minOccurs="0" /> \
                        <xs:element name="WD_OTHER_DESC" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_AGE" type="xs:int" minOccurs="0" /> \
                        <xs:element name="COND_INJ_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_RACE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_SEX" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_TRAP_EXTR_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_EJEC_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_POS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_MED_FAC_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_TRAN_MED_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_AGE" type="xs:int" minOccurs="0" /> \
                        <xs:element name="ENGR_INJ_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_RACE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_SEX" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_TRAP_EXTR_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_EJEC_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_POS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_MED_FAC_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_CERTIF" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_TRAN_MED_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_DOB" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="COND_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_DOB" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="ENGR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_ADDR_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_ADDR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="COND_ADDR_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="COND_ADDR_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_ADDR_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_ADDR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="ENGR_ADDR_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="ENGR_ADDR_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER_ADDR_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER_ADDR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER_ADDR_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER_ADDR_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OPER_CO_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OPER_CO_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OPER_CO_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OPER_CO_ADDR_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OPER_CO_ADDR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OPER_CO_ADDR_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="OPER_CO_ADDR_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OPER_CO" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRAIN_EST_SPEED" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CROSSING_SURF_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRACK_SPEED_LIM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="NUM_TRACKS" type="xs:int" minOccurs="0" /> \
                        <xs:element name="TRAIN_ID_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="DOT_CROSS_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="STREET_CAR" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="RAILROAD_TRAIN" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="TRAIN_MOTION" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="CROSSING_TYPE_PUBLIC" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="CROSSING_TYPE_PRIVATE" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="TRAIN_TRAVEL_DIST_MEASUREMENT_FEET" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="TRAIN_TRAVEL_DIST_MEASUREMENT_MILES" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="CAR_LEAKING" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="CAR_LOADED" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="HAZ_MAT" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="SIDE_IMPACT" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="SPEED_RES_PEND" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="DATA_REC_SPEED" type="xs:int" minOccurs="0" /> \
                        <xs:element name="EV_DATA_REC_EQPD" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="BELL_FUNC" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="HORN" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="DITCH_LIGHTS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="HEADLIGHTS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="AWDF_OTHER" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="AWDF_GATE_DOWN" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="AWDF_RING_BELL" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="AWDF_LTS_FL" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="AWD_OTHER" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="AWD_ACT_ADV_WARN" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="AWD_PAV_MARKS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="AWD_SIGN" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="WD_OTHER" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="WD_BELL_LTS_GATE" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="WD_BELL_LTS" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="WD_CROSSBUCK" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }

    if(hasTrocc) {
      xsdString += '<xs:element name="TROCC_TB"> \
                    <xs:complexType> \
                      <xs:sequence> \
                        <xs:element name="REV_DATE" type="xs:dateTime" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER_APPROVER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="PAGE_OWNER" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REV_STAT" type="xs:int" minOccurs="0" /> \
                        <xs:element name="CURR_STAT" type="xs:boolean" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="REPORT_NUM_2" type="xs:string" minOccurs="0" /> \
                        <xs:element name="CRASH_NUM" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_NUM" type="xs:int" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR_TYPE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR_NUM" type="xs:double" minOccurs="0" /> \
                        <xs:element name="OCC_ADDR_DIR" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_MED_FAC_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_TRAN_MED_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_ZIP" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_STATE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_CITY" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_INJ_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_AGE" type="xs:int" minOccurs="0" /> \
                        <xs:element name="OCC_RACE" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_SEX" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_PROTSYS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_AIRBAG_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_TRAP_EXTR_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_EJEC_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_POS_CD" type="xs:string" minOccurs="0" /> \
                        <xs:element name="OCC_NAME" type="xs:string" minOccurs="0" /> \
                        <xs:element name="TRA_NUM" type="xs:int" minOccurs="0" /> \
                      </xs:sequence> \
                    </xs:complexType> \
                  </xs:element>';
    }

    xsdString += '</xs:choice></xs:complexType></xs:element></xs:schema>';

    return xsdString;
  }

  /**
   * Changes the status of the incident to Send To Carrier
   */
  sendToCarrier() {
    this.api.setReportStatus(this.crashId, 'send_to_carrier')
    .subscribe(() => this.reportStatus = 'send_to_carrier', (error) => console.log(error));
  }

  /**
   * Gets the status label based on the status value.
   */
  getStatusLabel() {
    const status = this.statusVals.find((status: {value: string, label: string}) => {
      return status.value === this.reportStatus;
    });
    return status ? status.label : ''
  }

}
